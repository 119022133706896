import React, { useState } from "react";
import { MdOutlineGroupAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import { LiaCommentsSolid } from "react-icons/lia";

const GroupCard = () => {
  const groupdata = [
    {
      title: "Classy Marketplace",
      slug: "classy-marketplace",
      community: "ens",
      date: "24 Jan 2024",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there.",
      imageUrl: "/assets/group-img.jpg",
      imageDescription: "post image",
      members: 60,
      comments: 300,
    },
    {
      title: "Better Store",
      slug: "better-store",
      community: "ens",
      date: "23 Jan 2024",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there.",
      imageUrl: "/assets/group-img.jpg",
      imageDescription: "post image",
      members: 50,
      comments: 100,
    },
    {
      title: "Social Platform",
      slug: "social-platform",
      community: "ens",
      date: "23 Jan 2024",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there.",
      imageUrl: "/assets/group-img.jpg",
      imageDescription: "post image",
      members: 11,
      comments: 10,
    },
  ];
  const [group, setGroup] = useState(groupdata);
  return (
    <div>
      <div className="relative w-[100%] h-[200px] lg:h-[200px] full  m-auto bg-cover bg-no-repeat bg-center  bg-[#302b27] bg-[url('/public/assets/discussion.jpg')]">
        <h1 className="flex  items-center text-[40px] justify-center h-[200px] lg:h-[200px] font-bold text-[#fff]">
          Groups
        </h1>
      </div>
      <div className="grid grid-cols-3 gap-4 w-[70%] m-auto my-5">
        {group.map((item, index) => {
          return (
            <Link
              to={`/community/${item.community}/${item.slug}/group-overview`}
              key={index}
            >
              <div className="flex flex-col border border-[#9e9e9e] hover:shadow-inner hover:border hover:border-[#f0f0f0]">
                <img
                  src={item.imageUrl}
                  alt={item.imageDescription}
                  className="cursor-pointer"
                />
                <div className="lg:w-full relative p-4 text-center">
                  <h2 className="text-[16px] lg:text-[20px] font-bold  cursor-pointer color-blue">
                    {item.title}
                  </h2>
                  <p className="font-[400] text-[16px] text-[#9e9e9e] line-clamp-3 ">
                    {item.content}
                  </p>
                </div>
                <div className="lg:w-full relative p-4 mt-5 justify-between items-center flex bg-[#f0f0f0]">
                  <p className="flex items-center  text-[#9e9e9e] font-bold">
                    <MdOutlineGroupAdd className="text-[30px] text-[#9e9e9e] pr-1" />{" "}
                    {item.members}
                  </p>
                  <p className="flex items-center  text-[#9e9e9e] font-bold">
                    <LiaCommentsSolid className="text-[30px] text-[#9e9e9e] pr-1" />{" "}
                    {item.comments}
                  </p>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default GroupCard;

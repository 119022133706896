import React from "react";
import Header from "../components/header";
import DiscussionCard from "../components/discussionCard";

const Discussion = () => {
  return (
    <div>
      <Header />
      <DiscussionCard />
    </div>
  );
};

export default Discussion;

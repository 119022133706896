import React, { useState } from "react";
import axios from "axios";
import { apiurl } from "../config/config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import LoaderSpiner from "../components/loader";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const Register = () => {
  const [handle, setHandle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    phone: "+1",
    dob: "",
    userHandle: "",
    bio:"",
  });

  const initialerror = {
    email: false,
    password: false,
    firstname: false,
    lastname: false,
    phone: false,
    dob: false,
    userHandle: false,
    bio:false,
  };

  const [error, setError] = useState(initialerror);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "userHandle") {
      // const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, '');
      const isValid = /^[a-zA-Z0-9]*$/.test(value);
      
      setFormData({ ...formData, userHandle: value });
      if (isValid) {
        //setHandleerror(true);
        setHandle(false);
        userhandleChange(value);
        setError({ ...error, [name]: "valid" });
      }
      else{
        setHandle(true)
        setError({ ...error, [name]: true });
      }

    } else if (name === "phone") {
      if (value === "+1" || value === "") {
        setFormData({ ...formData, [name]: "+1" });
      } else if (value.length === 1 && !isNaN(value)) {
        setFormData({ ...formData, [name]: "+1" + value });
      } else if (value.length > 1 && !isNaN(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const errorHandler = (e) => {
    if (e.target.name === "userHandle") {
      const isValid = /^[a-zA-Z0-9]+$/.test(e.target.value);
      if (!isValid) {
          setError({ ...error, [e.target.name]: true });
      } else {
          setError({ ...error, [e.target.name]: "valid" });
      }
    } else if (e.target.name === "email") {
      const emailPattern = /\S+@\S+\.\S+/;
      if (!emailPattern.test(e.target.value)) {
        setError({ ...error, [e.target.name]: true });
      } else {
        setError({ ...error, [e.target.name]: "valid" });
      }
    } else if (e.target.name === "phone") {
      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(e.target.value)) {
        setError({ ...error, [e.target.name]: true });
      } else {
        setError({ ...error, [e.target.name]: "valid" });
      }
    } else {
      if (e.target.value) {
        setError({ ...error, [e.target.name]: "valid" });
      } else {
        setError({ ...error, [e.target.name]: true });
      }
    }
  };

  const handleDateChange = (newDate) => {
    const formattedDate = newDate ? newDate.format("MM-DD-YYYY") : "";
    setFormData((prevData) => ({ ...prevData, dob: formattedDate }));
    setError((prevError) => ({ ...prevError, dob: false }));
    // console.log("newDate", newDate);
  };
  const userhandleChange = async (value) => {
    // Handle registration API call

    try {
      const response = await axios({
        url: `${apiurl}/user/checkhandle/${value}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log("user handle response >>>>>>", response);
      if (response.data.userfound === true) {
        setHandle(true);
      } else {
        setHandle(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { firstname, lastname, email, phone, dob, password, userHandle, bio } =
        formData;
      // console.log("formData ----------------->", formData);
      if (!/^[a-zA-Z0-9]+$/.test(userHandle)) {
        toast.error("User handle must be unique and contain only letters and numbers.");
        setHandle(true);
        setError({ ...error, userHandle: true });
        setLoading(false);
        return;
      }
      if (
        firstname !== "" &&
        lastname !== "" &&
        email !== "" &&
        email.includes("@") &&
        phone !== "" &&
        dob !== "" &&
        password !== "" &&
        userHandle !== "" &&
        bio !== ""
      ) {
        const response = await axios({
          url: `${apiurl}/user/register`,
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "application/json",
          },
        });
        // console.log(response);
        if (response.data.success === true) {
          setLoading(false);
          toast.success(
            `${response.data.message} Please check your Mail for the Email Verification`
          );
          // navigate('/login', { state: { email: formData.email } })
          navigate("/login", { state: { email: formData.email } });
          setFormData({
            email: "",
            password: "",
            firstname: "",
            lastname: "",
            phone: "+1",
            dob: "",
            userHandle: "",
          });
        } else if (response.data.success === false) {
          setLoading(false);
          toast.error(response.data.message);
        }
      } else {
        setLoading(false);
        Object.keys(formData).forEach((fieldname) => {
          if (formData[fieldname].trim() === "") {
            setError((error) => ({
              ...error,
              [fieldname]: true,
            }));
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <Header />
      <div className="flex flex-col items-center justify-center login-screen min-h-screen">
        <div className="relative rounded-md w-[100%] lg:w-[85%] sm:min-h-screen h-full max-w-[100%] lg:shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] lg:flex block lg:bg-[#005AE6] my-3">
          <div className="lg:w-[55%] w-full  lg:block hidden bg-[#005AE6] rounded-[40px]">
            <div className="h-full w-[65%] m-auto flex items-center justify-center flex-col">
              <h1 className="flex items-center lg:text-[50px] text-[24px] justify-center h-[200px] lg:h-[200px] font-bold text-[#fff]">
                Start Your Savage Journey Today
              </h1>
              <img
                src="/assets/sign-bg.png"
                className="max-w-[85%] flex items-cent m-auto my-0"
                alt="signUp_banner"
              />
            </div>
          </div>
          <div className="lg:w-[60%] bg-[#fff] w-full sm:min-h-screen overflow-auto sm:rounded-md sm:rounded-tl-[30px] sm:rounded-bl-[30px] bottomright_bg flex items-center justify-center">
            <div className="w-full px-4 lg:px-16 pt-5 topright_bg">
              <p className="text-center text-[20px]  font-[500] mt-3 mb-3">
                <img
                  src="/assets/savageseller-logo.jpg"
                  alt="logo"
                  className="w-[50%] h-auto mx-auto"
                />
              </p>
              <div className="h2 text-center font-sans text-2xl font-bold leading-46 tracking-normal pb-4">
                Sign up into your account
              </div>
              <h3 className="text-start text-sm font-semibold m-0 mx-2 text-red-500">
                All fields are mandatory (*)
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2">
                <div className="col-span-1 py-4 relative">
                  {error.firstname !== "valid" && error.firstname === true ? (
                    <p className="text-[#FF0000] text-xs absolute -top-2 left-2"></p>
                  ) : null}
                  <input
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                    className="w-[95%] bg-[#EFF5FF] placeholder-slate-700 placeholder:bg-[#EFF5FF] focus:bg-[#EFF5FF] outline-none m-auto block p-2 border border-[#f0f0f0]"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorHandler(e)}
                  />
                </div>
                <div className="col-span-1 py-4 relative">
                  {error.lastname !== "valid" && error.lastname === true ? (
                    <p className="text-[#FF0000] text-xs absolute -top-2 left-2">
                      {" "}
                    </p>
                  ) : null}
                  <input
                    type="text"
                    name="lastname"
                    placeholder="Last Name"
                    className="w-[95%] bg-[#EFF5FF] placeholder-slate-700 placeholder:bg-[#EFF5FF] focus:bg-[#EFF5FF] outline-none m-auto block p-2 border border-[#f0f0f0]"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorHandler(e)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1">
                <div className="col-span-1 py-4 relative">
                  {error.email !== "valid" && error.email === true ? (
                    <p className="text-[#FF0000] text-xs absolute -top-2 left-2 w-[80%]"></p>
                  ) : null}
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="sm:w-[98%] w-[95%] bg-[#EFF5FF] placeholder-slate-700 placeholder:bg-[#EFF5FF] focus:bg-[#EFF5FF] outline-none m-auto block p-2 border border-[#f0f0f0]"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorHandler(e)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2">
                <div className="col-span-1 py-4 relative">
                  {error.phone !== "valid" && error.phone === true ? (
                    <p className="text-[#FF0000] text-sm absolute -top-2 left-2 w-[80%]"></p>
                  ) : null}
                  <p className="text-blue-500 text-xs absolute -top-1 left-2">
                    Only USA phone numbers are allowed
                  </p>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    placeholder="Mobile Number"
                    maxLength={12}
                    className="w-[95%] bg-[#EFF5FF] placeholder-slate-700 placeholder:bg-[#EFF5FF] focus:bg-[#EFF5FF] outline-none m-auto block p-2 border border-[#f0f0f0]"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorHandler(e)}
                  />
                </div>

                <div className="col-span-1 my-4 mx-auto relative   flex items-center justify-between bg-[#EFF5FF] w-[95%] calenderContainer">
                  {error.dob !== "valid" && error.dob == true ? (
                    <p className="text-[#FF0000] text-xs absolute -top-2 left-10"></p>
                  ) : null}
                  <div className="relative w-full">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        value={
                          formData.dob
                            ? dayjs(formData.dob, "MM-DD-YYYY")
                            : null
                        }
                        onChange={handleDateChange}
                        format="MM-DD-YYYY"
                        views={["year", "month", "day"]}
                        renderInput={(params) => (
                          <input
                            {...params.inputProps}
                            placeholder="Date of Birth"
                            className="w-[95%] bg-[#EFF5FF] placeholder-slate-700 focus:bg-[#EFF5FF] outline-none m-auto block p-2 border border-[#f0f0f0]"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1">
                <div className="col-span-1 py-4 relative">
                  {error.userBio !== "valid" && error.userBio === true ? (
                    <p className="text-[#FF0000] text-sm absolute -top-2 left-2 w-[80%]"></p>
                  ) : null}
                  <input
                    type="text"
                    name="bio"
                    placeholder="Bio"
                    className="sm:w-[98%] w-[95%] bg-[#EFF5FF] placeholder-slate-700 placeholder:bg-[#EFF5FF] focus:bg-[#EFF5FF] outline-none m-auto block p-2 border border-[#f0f0f0]"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorHandler(e)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2">
              <div className="col-span-1 py-4 relative">
              {error.userHandle !== "valid" && error.userHandle === true ? (
                <p className="text-[#FF0000] text-xs absolute -top-2 left-2 w-[80%]">Please add letter and number only</p>
              ) : null}
              <input
                type="text"
                name="userHandle"
                placeholder="Create User Name"
                className={`w-[95%] bg-[#EFF5FF] placeholder-slate-700 placeholder:bg-[#EFF5FF] focus:bg-[#EFF5FF] outline-none m-auto block p-2 border ${
                  !handle ? "border-[#f0f0f0]" : "border-[#ff0000]"
                } `}
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={(e) => errorHandler(e)}
              />
              <p className={`text-[#3cd70b] text-xs text-center`}>{handle || !formData.userHandle?"Not Available":"Available"}</p>
            </div>
                <div className="col-span-1 py-4 relative">
                  {error.password !== "valid" && error.password === true ? (
                    <p className="text-[#FF0000] text-xs absolute -top-2 left-2 w-[80%]"></p>
                  ) : null}
                  <input
                  type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    className="w-[95%] bg-[#EFF5FF] placeholder-slate-700 placeholder:bg-[#EFF5FF] focus:bg-[#EFF5FF] outline-none m-auto block p-2 border border-[#f0f0f0]"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorHandler(e)}
                  />
                  <div
                  className="absolute right-5 top-9 transform -translate-y-1/2 cursor-pointer text-gray-600"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
                </div>

                
              </div>
              <p className="m-auto w-[95%] text-center">First name, last name, unique username, and bio will be publicly visible.</p>
              <div className="grid grid-cols-1">
                <div className="col-span-1">
                  <button
                    className="rounded-md bg-[#005AE6] text-[#fff] text-[20px] font-[500] p-2 m-auto block mt-6 w-[95%]"
                    onClick={handleSubmit}
                    disabled={handle?true:false}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
              <p className="text-center  font-[500] mt-3 p-3 block ">
                Already have account?{" "}
                <Link to="/login">
                  <span className="underline text-[#005AE6] cursor-pointer">
                    Login
                  </span>
                </Link>
              </p>
              <p className="text-center  font-[500] mt-3 p-3 block ">
                Become individual seller ?{" "}
                <Link to="/individualseller">
                  <span className="underline text-[#005AE6] cursor-pointer">
                    Register
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;

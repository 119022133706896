import React from "react";

const Skeleton = () => {
  return (
    <>
      {/* <div className="max-w-2xl mx-auto p-4">
        <div className="mb-4">
          <div className="bg-gray-300 h-6 w-2/3 mb-2 animate-pulse"></div>

          <div className="bg-gray-200 h-16 mb-4 animate-pulse"></div>

          <div className="flex items-center mb-4">
            <div className="bg-gray-300 h-4 w-12 mr-2 animate-pulse"></div>
            <div className="bg-gray-300 h-4 w-16 animate-pulse"></div>
          </div>

          <div className="flex items-center mb-4">
            <div className="bg-gray-300 h-4 w-12 mr-2 animate-pulse"></div>
            <div className="bg-gray-300 h-4 w-16 animate-pulse"></div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="bg-gray-200 h-24 animate-pulse"></div>
            <div className="bg-gray-200 h-24 animate-pulse"></div>
          </div>
        </div>
      </div> */}

      <div className="max-w-2xl mx-auto p-4">
        <div className="mb-4">
          <div className="flex items-center mb-2">
            <div className="bg-gray-300 h-6 w-2/3 mr-2 animate-pulse"></div>
            <div className="bg-gray-300 h-4 w-1/3 animate-pulse"></div>
          </div>

          <div className="bg-gray-200 h-16 mb-4 relative animate-pulse">
            <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center">
              <div className="bg-gray-300 h-4 w-1/2 animate-pulse"></div>
            </div>
          </div>

          <div className="flex items-center mb-4">
            <div className="bg-gray-300 h-4 w-12 mr-2 animate-pulse"></div>
            <div className="bg-gray-300 h-4 w-16 animate-pulse"></div>
            <div className="ml-auto">
              <div className="bg-gray-300 h-3 w-20 animate-pulse"></div>
              <div className="bg-gray-300 h-3 w-16 animate-pulse mt-1"></div>
            </div>
          </div>

          <div className="flex items-center mb-4">
            <div className="bg-gray-300 h-4 w-12 mr-2 animate-pulse"></div>
            <div className="bg-gray-300 h-4 w-16 animate-pulse"></div>
            <div className="ml-auto">
              <div className="bg-gray-300 h-3 w-20 animate-pulse"></div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="bg-gray-200 h-24 relative animate-pulse">
              <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center">
                <div className="bg-gray-300 h-4 w-1/2 animate-pulse"></div>
              </div>
            </div>
            <div className="bg-gray-200 h-24 relative animate-pulse">
              <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center">
                <div className="bg-gray-300 h-4 w-1/2 animate-pulse"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Skeleton;

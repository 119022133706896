import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../components/imageCrop/cropImageHelper";
import { v4 as uuidv4 } from "uuid";
import { awsKeyId, awsSecretKey } from "../config/config";
import { useUser } from "../contexts/UserProvider";
import { getCookie } from "../config/coockies";
import { toast } from "react-toastify";
import axios from "axios";
import { apiurl } from "../config/config";

const ImageCropper = ({ closeCrop, setIsImageCrop, imageUrl, handleGetProfileData, croppedImgType }) => {
  const { userData } = useUser();
  const token = getCookie("savage_token");
  const [imgloading, setImgLoading] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedProfileImg, setCroppedProfileImg] = useState(imageUrl);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const aws = require("aws-sdk");

  const ImageHandler = async (file) => {
    setImgLoading(true);
    try {
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: awsKeyId,
        secretAccessKey: awsSecretKey,
      });
      const params = {
        Bucket: "savageseller",
        Key: `${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file.type,
      };
      let { Location } = await s3.upload(params).promise();
      return Location;
    } catch (exception) {
      console.error(exception);
      setImgLoading(false);
      setIsImageCrop(false);
      return false;
    }
  };

  const handleUpload = async (imageType, imageUrl) => {
    setImgLoading(true);
    try {
      const updatedData = {
        [imageType]: imageUrl,
      };
      const UserId = { id: userData._id };
      const response = await axios.put( `${apiurl}/user/update/${UserId.id}`, updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      await handleGetProfileData();
      if (response.status === 200) {
        await handleGetProfileData();
        setIsImageCrop(false);
        toast.success('Profile Updated Successfully');
        setZoom(1);
      } else {
        toast.error('Failed to Update profile');
      }
    } catch (error) {
      console.error("Profile update error", error);
    }
    setImgLoading(false);
    setIsImageCrop(false);
  };
  
  const showCroppedCoverImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        croppedProfileImg,
        croppedAreaPixels,
        rotation
      );
      const file = new File([croppedImage], "cropped_image.jpeg", {
        type: "image/jpeg",
      });
      const imageUrl = await ImageHandler(file);
      handleUpload(croppedImgType, imageUrl);
      setRotation(0);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="fixed inset-0 overflow-y-auto shadow-2xl">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div className="inline-flex align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:w-[50%] sm:p-6 h-[600px]">
          <Cropper
            image={imageUrl}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={croppedImgType === 'hero_img' ? 1 : 287 / 80}
            onCropChange={(crop) => setCrop(crop)}
            onCropComplete={(croppedArea, croppedAreaPixels) => {
              setCroppedAreaPixels(croppedAreaPixels);
            }}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
          />
          <div className="absolute bottom-0 left-0 px-4 flex-col sm:flex-row sm:flex w-full items-center justify-between py-5">
            <div className="sm:flex flex-col w-full sm:w-3/4 px-4 sm:px-1">
              <div className="flex w-full items-center justify-between my-4">
                <span className="mr-2 w-[60px]">Zoom</span>
                <div className="flex w-full justify-between">
                  <input
                    type="range"
                    min={1}
                    max={3}
                    step={0.1}
                    value={zoom}
                    onChange={(e) => setZoom(Number(e.target.value))}
                    className="w-[80%] cursor-pointer"
                    disabled={imgloading}
                  />
                  <span className=" w-12">{zoom.toFixed(1)}</span>
                </div>
              </div>
              <div className="flex w-full items-center justify-between my-4">
                <span className="mr-2 w-[60px]">Rotation</span>
                <div className="flex w-full justify-between">
                  <input
                    type="range"
                    min={0}
                    max={360}
                    value={rotation}
                    onChange={(e) => setRotation(Number(e.target.value))}
                    className="w-[80%] cursor-pointer"
                    disabled={imgloading}
                  />
                  <span className="w-12">{rotation.toFixed(2)}°</span>
                </div>
              </div>
            </div>
            <div className="flex sm:flex-col flex-row-reverse items-center justify-between w-full px-4 sm:px-1 sm:w-1/4">
              
              <button 
                className="bg-blue-500 text-white px-2 py-[10px] rounded-md hover:bg-blue-600 mb-0 sm:mb-2 w-28 h-9 flex items-center justify-center"
                onClick={showCroppedCoverImage}
                disabled={imgloading} 
                >
                {imgloading ? <>
                    <svg
                      width="24px"
                      height="24px"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      stroke="#ffffff"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <circle cx="75" cy="50" fill="#ffffff" r="6.39718"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.875s" ></animate> </circle> <circle cx="67.678" cy="67.678" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s" ></animate> </circle> <circle cx="50" cy="75" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.625s" ></animate> </circle> <circle cx="32.322" cy="67.678" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s" ></animate> </circle> <circle cx="25" cy="50" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.375s" ></animate> </circle> <circle cx="32.322" cy="32.322" fill="#ffffff" r="4.80282" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s" ></animate> </circle> <circle cx="50" cy="25" fill="#ffffff" r="6.40282"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.125s" ></animate> </circle> <circle cx="67.678" cy="32.322" fill="#ffffff" r="7.99718" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s" ></animate> </circle>
                    </svg>{" "}
                    <span className="mt-[2px]">Loading</span></> : `Upload` }
                </button>
              <button
                onClick={closeCrop}
                className="flex items-center justify-center bg-gray-300 text-gray-700 px-2 py-[10px] w-28 h-9 rounded-md hover:bg-gray-400"
                disabled={imgloading}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;

import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { useUser } from "../contexts/UserProvider.js";
import { socket } from "../config/socket.js";
import { getCookie } from "../config/coockies.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CallContext } from "../contexts/CallContext.js";
import { AudioCallContext } from "../contexts/audioCallContext.js";
import { GroupCallContext } from "../contexts/groupCallContext.js";
import { GetTimeAgo } from "../components/getTime.js";
import { GrGroup } from "react-icons/gr";
import { RiUser3Line } from "react-icons/ri";
import { FaUserLock } from "react-icons/fa";
import { toast } from "react-toastify";
import { IoArrowBackSharp } from "react-icons/io5";
import { MdAddPhotoAlternate } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { awsKeyId, awsSecretKey } from "../config/config";
import DOMPurify from "dompurify";
import { BsEmojiSmileFill } from "react-icons/bs";
import Picker from "emoji-picker-react";
import { apiurl } from "../config/config";
import { NewGroupCallContext } from "../contexts/NewGroupCall.js";
import { GetDate } from "../components/GetDate.js";
import { GetTimeforchat } from "../components/GetTimeforchat.js";
const aws = require("aws-sdk");

export default function ChatBox() {
  const { Calluser } = useContext(CallContext);
  const { AudioCalluser } = useContext(AudioCallContext);
  const { joinGroupCall } = useContext(GroupCallContext);

  let User = useUser();
  const { userData } = useUser();
  const token = getCookie("savage_token");
  const [selectedChat, setSelectedChat] = useState(null);
  const [newChatPopup, setNewChatPopup] = useState(false);
  const [createGroupPopup, setCreateGroupPopup] = useState(false);
  const [createGroupresult, setCreateGroupresult] = useState(false);
  const [selectedChatName, setSelectedChatName] = useState(null);
  const [selectedChatImage, setSelectedChatImage] = useState(null);
  const [groupMemberCount, setGroupMemberCount] = useState(null);
  const [creator, setCreator] = useState(null);
  const [userList, setuserList] = useState([]);
  const [chatList, setchatList] = useState([]);
  const [isGroup, setIsGroup] = useState(false);
  const [groupType, setGroupType] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [newGroupName, setNewGroupName] = useState("");
  const [groupMembers, setGroupMembers] = useState([]);
  const [chatType, setChatType] = useState("chat");
  const [getAllPublicGroup, setGetAllPublicGroup] = useState([]);
  const [getAllPrivateGroup, setGetAllPrivateGroup] = useState([]);
  const [privateGroupPop, setPrivateGroupPop] = useState(false);
  const [groupPassword, setGroupPassword] = useState("");
  const [groupId, setGroupId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserGroup, setSelectedUserGroup] = useState([]);
  const [message, setMessage] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [groupname, setGroupname] = useState("");
  const [loading, setloading] = useState(false);
  const [isCheckPrivateGroupMember, setIsCheckPrivateGroupMember] = useState();
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  const [privatepwd, setPrivatepwd] = useState("");
  const handleResize = () => {
    setIsDesktopView(window.innerWidth > 768);
  };
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    fetchUserList();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const fetchChatsList = async () => {
    let senderId = User?.userData?._id;
    const config = {
      url: `${apiurl}/message/chats/${senderId}`,
      method: "GET",
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    try {
      const response = await axios.request(config);
      const data = await response.data;
      setchatList(data.users);
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };
  const fetchUserList = () => {
    axios
      .get(`${apiurl}/user/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log("ss", res.data);
        setuserList(res.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchUserList();
    fetchChatsList();
    fetchGroupList();
  }, []);
  const fetchGroupList = () => {
    axios
      .get(
        `${apiurl}/message/getgroupsbyuserid?userId=${User?.userData?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setGroupList(res.data.groups);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleGetAllPublicGroup = async () => {
    try {
      const response = await axios.get(`${apiurl}/message/get-public-groups`);
      if (response.data.success === true) {
        setGetAllPublicGroup(response.data?.groups);
      } else {
        console.error("get all public group error");
      }
    } catch (error) {
      console.error("get all public group", error);
    }
  };
  const handleGetAllPrivateGroup = async () => {
    try {
      const response = await axios.get(`${apiurl}/message/get-private-groups`);
      if (response.data.success === true) {
        setGetAllPrivateGroup(response.data?.groups);
      } else {
        console.error("get all private group error");
      }
    } catch (error) {
      console.error("get all private group", error);
    }
  };
  useEffect(() => {
    handleGetAllPublicGroup();
    handleGetAllPrivateGroup();
  }, []);
  const handleJoinGroup = async (groupId) => {
    setloading(true);
    setGroupId(groupId);
    try {
      const data = {
        groupId: groupId,
        userId: userData._id,
        password: groupPassword || "",
      };
      const response = await axios.post(`${apiurl}/message/joingroup`, data);
      if (response.data.success === true) {
        setGroupPassword("");
        setloading(false);
        toast.success(response.data.message);
        fetchChatsList();
        setPrivateGroupPop(false);
      } else {
        setloading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setloading(false);
      console.error("An error occurred while joining the group:", error);
    }
  };
  const handleSubmitJoinGroup = async () => {
    setloading(true);
    try {
      const data = {
        groupId: groupId,
        userId: userData._id,
        password: groupPassword || "",
      };
      const response = await axios.post(`${apiurl}/message/joingroup`, data);
      if (response.data.success === true) {
        setGroupPassword("");
        setloading(false);
        toast.success(response.data.message);
        fetchChatsList();
        setPrivateGroupPop(false);
      } else {
        setloading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setloading(false);
      console.error("An error occurred while joining the group:", error);
    }
  };
  const senderId = User?.userData?._id;
  const receiverId = selectedChat;
  const senderName = User?.userData?.firstname;
  const receiverName = selectedChatName;
  const openChat = (
    receiverId,
    firstname,
    hero_img,
    groupMemberCount,
    groupMembers,
    creator
  ) => {
    setGroupMembers(groupMembers);
    setSelectedChat(receiverId);
    setSelectedChatName(firstname);
    setSelectedChatImage(hero_img);
    setGroupMemberCount(groupMemberCount);
    setCreator(creator);
  };
  const handleBackToChatList = () => {
    setSelectedChat(null);
    setSelectedChatName(null);
  };
  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setSearchTerm("");
    setFilteredUsers([]);
  };
  const newChatHandle = async (receiver) => {
    try {
      const response = await axios.post(`${apiurl}/message/send`, {
        senderId: User?.userData?._id,
        receiverId: receiver,
        content: newMessage,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      window.location.reload();
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };
  const handleCheckboxChange = (userId) => {
    const isChecked = selectedUserGroup.includes(userId);
    if (isChecked) {
      setSelectedUserGroup(selectedUserGroup.filter((id) => id !== userId));
    } else {
      setSelectedUserGroup([...selectedUserGroup, userId]);
    }
  };
  const createGroupe = () => {
    // console.log(selectedUserGroup);
    setloading(true);
    if (selectedUserGroup.length > 0 && groupname && groupType) {
      let data = JSON.stringify({
        groupName: groupname,
        groupType: groupType,
        members: [...selectedUserGroup, User?.userData?._id],
        createdBy: User?.userData?._id,
        password: privatepwd,
      });
      let config = {
        method: "post",
        url: `${apiurl}/message/creategroup`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          setloading(false);
          setCreateGroupresult(true);
          setCreateGroupPopup(false);
        })
        .catch((error) => {
          console.log(error);
          setloading(false);
        });
    } else {
      if(!groupname)
        {
          toast.warn("Please add group name");
          setloading(false);
        }
     
      else  if(selectedUserGroup.length < 1){
        toast.warn("Please add some members ");
        setloading(false);
      }
      else if(!groupType)
        {
          toast.warn("Please select group type");
          setloading(false);
        }
      
    }
  };
  const filteredChatList = chatList
    .filter((user) => {
      return user.name.toLowerCase().includes(searchTerm.toLowerCase());
    })

    .sort((a, b) => {
      const dateA = new Date(a.lastMessage?.createdAt);
      const dateB = new Date(b.lastMessage?.createdAt);

      if (!a.lastMessage || !a.lastMessage.createdAt) return 1;
      if (!b.lastMessage || !b.lastMessage.createdAt) return -1;

      return dateB - dateA;
    });

  const filteredGroupList = groupList.filter((user) => {
    return user.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const combinedChatList = [...filteredChatList, ...filteredGroupList];

// Sort the combined array by the latest message or creation date
const sortedCombinedChatList = combinedChatList.sort((a, b) => {
  const dateA = new Date(a.lastMessage?.createdAt || a.createdAt);
  const dateB = new Date(b.lastMessage?.createdAt || b.createdAt);

  return dateB - dateA; // Sort in descending order of time
});

  const isPrivateGroupMember = getAllPrivateGroup.map((item) =>
    item.members.some((member) => member._id === userData._id)
  );
  const isPublicGroupMember = getAllPublicGroup.map((item) =>
    item.members.some((member) => member._id === userData._id)
  );

  return (
    <div className="flex justify-between h-screen flex-col md:flex-row">
      {newChatPopup === true ? (
        <>
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-30">
            <div className="bg-white sm:p-6 p-2 rounded-xl shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] text-center flex justify-start items-center flex-col sm:w-2/5 w-11/12">
              <div className="flex justify-between items-center w-full">
                <p className="text-lg">New Chat</p>
                <button className="" onClick={() => setNewChatPopup(false)}>
                  X
                </button>
              </div>
              <div className="w-full p-2">
                <div className="my-1 p-1 flex bg-[#fff] border border-gray-400 w-full rounded-lg">
                  <input
                    type="text"
                    className="px-2 py-2 w-full focus:outline-none"
                    placeholder="Search Users . . ."
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setFilteredUsers(
                        userList.filter((user) =>
                          user?.name
                            ?.toLowerCase()
                            .includes(e.target?.value?.toLowerCase())
                        )
                      );
                    }}
                  />
                  <button className="px-4 focus:outline-none">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      className="text-[#000]"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                    </svg>
                  </button>
                </div>

                {(filteredUsers.length > 0 || userList !== "") && (
                  <ul className="sm:mt-4 mt-2 w-full bg-gray-100 border border-gray-300 rounded-lg shadow-md overflow-y-scroll h-40">
                    {(filteredUsers.length > 0 ? filteredUsers : userList).map(
                      (user) => (
                        <li
                          key={user?._id}
                          onClick={() => handleUserSelect(user)}
                          className={`flex justify-start items-center px-3 py-0 bg-gray-100 hover:bg-gray-200 transition-all duration-300 cursor-pointer my-2  ${
                            selectedUser === user
                              ? "bg-blue-500 text-blue-600"
                              : ""
                          }`}
                        >
                          <div className="w-1/7 border-[1px] border-gray-500 rounded-full p-1">
                            <img
                              src={user.hero_img}
                              className="object-cover h-12 w-14 rounded-full border-[1px] border-gray-500"
                              alt=""
                            />
                          </div>
                          <div className="w-full text-start ml-3">
                            <div className="sm:text-lg text-sm">
                              {user?.name}
                            </div>
                            <p className="sm:text-sm text-xs">{user.email}</p>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                )}
                {selectedUser && (
                  <div className="mt-2">
                    <div className="flex mt-5 items-center">
                      <div className="sm:w-1/6 w-2/5 border border-gray-400 rounded-lg">
                        <img
                          src={selectedUser?.hero_img}
                          className="object-cover h-24 w-24 rounded-lg"
                          alt=""
                        />
                      </div>
                      <div className="w-full text-start ml-3">
                        <div className="sm:text-2xl text-lg font-semibold">
                          {selectedUser?.name}
                        </div>
                        <div className="sm:text-lg text-sx">
                          {selectedUser?.email}
                        </div>
                        <span>{selectedUser?.location}</span>
                      </div>
                    </div>
                    <div className="flex justify-center items-center mt-3">
                      <input
                        type="text"
                        placeholder="Type your message"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        className="border border-gray-300 p-2 rounded-md mt-2 w-full"
                      />
                      <button
                        onClick={() => newChatHandle(selectedUser?._id)}
                        className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md mt-2"
                      >
                        Send
                      </button>
                    </div>
                    <div className="mt-5">
                      <p className="sm:text-lg text-sm">
                        "Hey there! 🌟 Before we dive in, let's keep it friendly
                        and respectful. Enjoy the chat! 👋"
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}

      {createGroupPopup === true ? (
        <>
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-30">
            <div className="bg-white sm:p-6 p-3 rounded-xl shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] text-center flex justify-start items-center flex-col sm:w-1/3 w-11/12">
              <div className="flex justify-between items-center w-full">
                <p className="text-xl font-semibold">Create New Group</p>
                <button className="" onClick={() => setCreateGroupPopup(false)}>
                  X
                </button>
              </div>
              <div className="w-full mt-5">
                <div className="text-start">
                  <label
                    htmlFor="groupName"
                    className="text-start m-auto mb-1 text-lg"
                  >
                    Group Name :
                  </label>
                  <input
                    id="groupName"
                    type="text"
                    className="p-2 w-full focus:outline-none border border-gray-400 rounded-lg mb-2"
                    placeholder="Group Name"
                    onChange={(e) => setGroupname(e.target.value)}
                  />
                </div>

                <div className="p-1 flex bg-[#fff] border border-gray-400 w-full rounded-lg">
                  <input
                    type="text"
                    className="p-1 w-full focus:outline-none"
                    placeholder="Search Users . . ."
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setFilteredUsers(
                        userList.filter((user) =>
                          user?.name
                            ?.toLowerCase()
                            .includes(e.target?.value?.toLowerCase())
                        )
                      );
                    }}
                  />
                </div>

                <div className="flex justify-between sm:w-3/5 w-11/12">
                  <div className="flex justify-between mt-2">
                    <label
                      htmlFor="groupName"
                      className="text-start m-auto mb-1 text-gray-700"
                    >
                      Public Group
                    </label>
                    <input
                      type="radio"
                      className="mx-2"
                      name="groupType"
                      value="public"
                      onClick={() => setGroupType("public")}
                      defaultChecked={groupType === "public"}
                    />
                  </div>
                  <div className="flex justify-between mt-2">
                    <label
                      htmlFor="groupName"
                      className="text-start m-auto mb-1 text-gray-700"
                    >
                      Private Group
                    </label>
                    <input
                      type="radio"
                      className="mx-2"
                      name="groupType"
                      value="private"
                      onClick={() => setGroupType("private")}
                      defaultChecked={groupType === "private"}
                    />
                  </div>
                </div>

                {groupType === "private" && (
                  <div className="text-start mt-2">
                    <label
                      htmlFor="groupName"
                      className="text-start m-auto mb-1 text-lg"
                    >
                      Private Group Password :
                    </label>
                    <input
                      type="password"
                      className="p-2 w-full focus:outline-none border border-gray-400 rounded-lg mb-2"
                      placeholder="Group Password"
                      value={privatepwd}
                      onChange={(e) => setPrivatepwd(e.target.value)}
                    />
                  </div>
                )}

                {(filteredUsers.length > 0 || userList !== "") && (
                  <ul className="w-full bg-white rounded-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] overflow-y-scroll h-40 mt-4">
                    {(filteredUsers.length > 0 ? filteredUsers : userList).map(
                      (user) => (
                        <li
                          className="flex justify-start sm:px-3 px-1 py-0 bg-gray-100 hover:bg-gray-200 transition-all duration-300 cursor-pointer"
                          key={user?._id}
                        >
                          <input
                            type="checkbox"
                            id={`user${user?._id}`}
                            className="sm:mx-3 mx-1 p-1"
                            checked={selectedUserGroup.includes(user?._id)}
                            onChange={() => handleCheckboxChange(user?._id)}
                          />
                          <label
                            className="flex justify-start w-full"
                            htmlFor={`user${user?._id}`}
                          >
                            <div className="w-1/7 border-[1px] border-gray-500 rounded-full p-1 bg-white">
                              <img
                                src={user?.hero_img}
                                className="object-cover h-12 w-14    border-gray-500 rounded-full"
                                alt=""
                              />
                            </div>

                            <div className="w-full text-start ml-3 mb-1">
                              <div className="sm:text-lg text-base">
                                {user?.name}
                              </div>
                              <p className="sm:text-base text-sm">
                                {user.email}
                              </p>
                            </div>
                          </label>
                        </li>
                      )
                    )}
                  </ul>
                )}

                {/* 
                {selectedUserGroup &&
                  selectedUserGroup.map((user, index) => {
                    return (
                      <div className="mt-2 hh" key={index + 1}>
                        <div className="w-full text-start ml-3">
                          <div className="text-lg ">{user?.name}</div>
                          <p>{user?._id}</p>
                        </div>
                      </div>
                    );
                  })} */}
              </div>
              {loading === true ? (
                <button className="flex items-center space-x-2 bg-blue-500 text-white px-4 py-2 rounded-md w-full text-center justify-center">
                  Loading...
                </button>
              ) : (
                <button
                  className="flex items-center space-x-2 bg-blue-500 text-white px-4 py-2 rounded-md w-full text-center justify-center mt-4"
                  onClick={createGroupe}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </>
      ) : null}

      {createGroupresult === true ? (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          {/* <div className="bg-white p-6 rounded shadow-lg text-center flex justify-start items-center flex-col w-2/4 h-96"> */}
          <div className="bg-green-100 text-green-900 p-8 rounded-lg shadow-lg">
            <div className="flex justify-end ">
              <button onClick={(e) => setCreateGroupresult(false)}>X</button>
            </div>
            <h2 className="text-2xl font-bold mb-4">
              Group Created Successfully
            </h2>
            <div className="mt-4">
              <a
                href="/chats"
                className="bg-green-500 text-white px-4 py-2 rounded-md mr-4"
              >
                Start Chat
              </a>
              {/* </div> */}
            </div>
          </div>
        </div>
      ) : null}

      {(isDesktopView || selectedChat) && (
        <>
          {isDesktopView === true ? (
            <div className="flex flex-col w-full sm:w-[502px] border-r-1 overflow-y-auto bg-[#F5F7FB] chatsList h-full">
              <div className="border border-1px-gray-500 bg-slate-200 rounded-lg">
                <div className="flex justify-between items-center border-b pb-3 bg-slate-200 p-3">
                  <div className="flex justify-start items-center">
                    <div className="">
                      <img
                        src={userData?.hero_img}
                        className="object-cover h-12 w-12 border-[1px] border-black rounded-full"
                        alt="chat profile image"
                      />
                    </div>
                    <div className="mx-2">
                      <div className="text-lg font-semibold capitalize">
                        {User?.userData?.name}
                      </div>
                      <span className="text-gray-500 text-sm">Welcome</span>
                    </div>
                  </div>
                  <button
                    className="flex items-center ml-2 text-green-600 border border-green-600 px-2 py-1 rounded-lg sm:px-5 sm:py-2 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform mt-2"
                    onClick={handleGoBack}
                  >
                    <IoArrowBackSharp className="mr-2" /> Back
                  </button>
                </div>
                <div className="p-3">
                  <input
                    type="text"
                    placeholder="Search Chats"
                    value={searchTerm}
                    className="py-2 px-2 rounded-lg w-full border border-gray-400"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>

                <div className="flex justify-between items-center px-3 pb-3 relative">
                  <button
                    className="flex items-center space-x-2 bg-blue-800 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-all duration-300"
                    onClick={() => setCreateGroupPopup(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    Create Group
                  </button>

                  <button
                    className="flex items-center space-x-2 bg-green-700 text-white px-4 py-2 rounded-md font-semibold hover:bg-green-600 transition-all duration-300"
                    onClick={() => setNewChatPopup(true)}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25px"
                      stroke="#fff"
                      strokeWidth="0.336"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22 12C22 17.5228 17.5228 22 12 22C10.4003 22 8.88837 21.6244 7.54753 20.9565C7.19121 20.7791 6.78393 20.72 6.39939 20.8229L4.17335 21.4185C3.20701 21.677 2.32295 20.793 2.58151 19.8267L3.17712 17.6006C3.28001 17.2161 3.22094 16.8088 3.04346 16.4525C2.37562 15.1116 2 13.5997 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.5303 9.46967C15.8232 9.76256 15.8232 10.2374 15.5303 10.5303L11.5303 14.5303C11.2417 14.819 10.7751 14.8238 10.4806 14.541L8.4806 12.621C8.18179 12.3342 8.1721 11.8594 8.45896 11.5606C8.74582 11.2618 9.22059 11.2521 9.5194 11.539L10.9893 12.9501L14.4697 9.46967C14.7626 9.17678 15.2374 9.17678 15.5303 9.46967Z"
                          fill="#fff"
                        ></path>
                      </g>
                    </svg>
                    New Chat
                  </button>
                </div>
              </div>
              <div className="max-h-[90vh] overflow-y-auto">
                {/* {chatType === "chat" &&
                  filteredChatList.map((user) => (
                    <div
                      className="flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer capitalize"
                      onClick={() => {
                        openChat(user?._id, user?.firstname, user?.hero_img);
                        setIsGroup(false);
                        setIsCheckPrivateGroupMember(true);
                      }}
                      key={user?._id}
                    >
                      <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1 bg-white">
                        <img
                          src={user.hero_img}
                          className="rounded-full w-12 h-12 border-[1px] border-gray-500 bg-gray-300"
                          alt=""
                        />
                        <span className="absolute w-2.5 h-2.5 bg-green-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                      </div>

                      <div className="flex-grow overflow-hidden">
                        <h5 className="mb-1 font-semibold text-black ">
                          {user?.name}
                        </h5>
                        <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px] block w-40 overflow-hidden whitespace-nowrap overflow-ellipsis">
                          {user?.lastMessage?.content}
                        </p>
                      </div>
                      <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                        {GetDate(user?.lastMessage?.createdAt)}
                      </div>
                    </div>
                  ))} */}

                {/*{chatType === "chat" &&
                  filteredGroupList.map((user) => {
                    return (
                      <div
                        className="flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer"
                        onClick={() => {
                          openChat(
                            user?._id,
                            user?.name,
                            true,
                            user?.members?.length,
                            user?.members,
                            user?.creator
                          );
                          setIsGroup(true);
                          setIsCheckPrivateGroupMember(true);
                        }}
                        key={user?._id}
                      >
                        <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                          <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                            {user?.name?.charAt(0).toUpperCase()}
                          </p>
                          <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                        </div>

                        <div className="flex-grow overflow-hidden">
                          <h5 className="mb-1 font-semibold text-black ">
                            {user?.name}
                          </h5>
                          <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                            {user?.members && Array.isArray(user.members)
                              ? `${user.members.length} members`
                              : ""}
                          </p>
                        </div>
                        <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                          {GetDate(user?.createdAt)}
                        </div>
                      </div>
                    );
                  })}
*/}
              {chatType === "chat" &&
              sortedCombinedChatList.map((user) => {
              const isGroup = !!user.members; // Check if it's a group based on presence of members array

              return (
                <div
                  className="flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer"
                  onClick={() => {
                    if (isGroup) {
                      openChat(
                        user?._id,
                        user?.name,
                        true,
                        user?.members?.length,
                        user?.members,
                        user?.creator
                      );
                      setIsGroup(true);
                    } else {
                      openChat(user?._id, user?.name, user?.hero_img);
                      setIsGroup(false);
                    }
                    setIsCheckPrivateGroupMember(true);
                  }}
                  key={user?._id}
                >
                  {/* Render different UI for group vs individual chat */}
                  {isGroup ? (
                    <>
                      <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                        <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                          {user?.name?.charAt(0).toUpperCase()}
                        </p>
                        <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                      </div>
                      <div className="flex-grow overflow-hidden">
                        <h5 className="mb-1 font-semibold text-black capitalize">{user?.name}</h5>
                        <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                        {user?.lastMessage?.content}
                        </p>
                      </div>
                      <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                      {user?.lastMessage!=null?GetDate(user?.lastMessage?.createdAt):GetDate(user?.updatedAt)}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1 bg-white">
                        <img
                          src={user.hero_img}
                          className="rounded-full w-12 h-12 border-[1px] border-gray-500 bg-gray-300"
                          alt=""
                        />
                        <span className="absolute w-2.5 h-2.5 bg-green-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                      </div>

                      <div className="flex-grow overflow-hidden">
                        <h5 className="mb-1 font-semibold text-black capitalize ">{user?.name}</h5>
                        <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px] block w-40 overflow-hidden whitespace-nowrap overflow-ellipsis">
                          {user?.lastMessage?.content}
                        </p>
                      </div>
                      <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                        {GetDate(user?.lastMessage?.createdAt)}
                      </div>
                    </>
                  )}
                </div>
              );
              })}
                {chatType === "private-group" &&
                getAllPrivateGroup &&
                Array.isArray(getAllPrivateGroup) &&
                getAllPrivateGroup.length > 0
                  ? getAllPrivateGroup.map((user, index) => {
                      const checkPrivateGroupMember = user.members.some(
                        (member) => member._id === userData._id
                      );
                      return (
                        <div
                          className="flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer"
                          onClick={() =>{
                            openChat(
                              user?._id,
                              user?.name,
                              "",
                              user?.members.length,
                              setIsCheckPrivateGroupMember(
                                checkPrivateGroupMember
                              ),
                              user?.members,
                              user?.creator
                            )
                            
                          setIsGroup(true);
                            }
                          }
                          key={user?._id}
                        >
                          <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                            <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300 capitalize">
                              {user?.name?.charAt(0).toUpperCase()}
                            </p>
                            <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                          </div>

                          <div className="flex-grow overflow-hidden">
                            <h5 className="mb-1 font-semibold text-black capitalize">
                              {user?.name}
                            </h5>
                             <p className="mb-0 text-gray-500 truncate dark:text-gray-300 text-14">{user?.lastMessage?.content}</p> 
                            
                          </div>
                          <div className="text-gray-500 text-xs dark:text-gray-300 pt-1 relative">
                          <div className="mb-0 text-gray-500 dark:text-gray-300 text-[14px] ">
                            {user?.lastMessage!=null?GetDate(user?.lastMessage?.createdAt):GetDate(user?.updatedAt)}
                            </div>
                            {!isPrivateGroupMember[index] && (
                              <div className="border border-blue-600 rounded-lg absolute top-[28px] right-0 w-28 text-center p-[2px] hover:border-green-600">
                                <button
                                  className="text-xs px-1 hover:text-green-700 text-blue-600"
                                  onClick={() => {
                                    setPrivateGroupPop(true);
                                    handleJoinGroup(user?._id);
                                  }}
                                >
                                  Join Private Group
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  : null}

                {chatType === "public-group"
                  ? getAllPublicGroup &&
                    getAllPublicGroup.map((user, index) => (
                      <div
                        className="flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer"
                        onClick={() => {
                          openChat(
                            user?._id,
                            user?.name,
                            "",
                            user?.members.length
                          );
                          setIsGroup(true);
                          setIsCheckPrivateGroupMember(true);
                        }}
                        key={user?._id}
                      >
                        <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                          <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300 capitalize">
                            {user?.name?.charAt(0).toUpperCase()}
                          </p>
                          <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                        </div>

                        <div className="flex-grow overflow-hidden">
                          <h5 className="mb-1 font-semibold text-black capitalize">
                            {user?.name}
                          </h5>
                          {/* <p className="mb-0 text-gray-500 truncate dark:text-gray-300 text-14">{user?.userHandle}</p> */}
                          <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                            {user?.lastMessage?.content}
                          </p>
                        </div>
                        <div className="text-gray-500 text-xs dark:text-gray-300 pt-1 relative">
                        {user?.lastMessage!=null?GetDate(user?.lastMessage?.createdAt):GetDate(user?.updatedAt)}
                          {!isPublicGroupMember[index] && (
                            <div className="border border-blue-600 rounded-lg absolute top-[28px] right-0 w-28 text-center p-[2px] hover:border-green-600">
                              <button
                                className="text-xs px-1 hover:text-green-700 text-blue-600 "
                                onClick={() => handleJoinGroup(user._id)}
                              >
                                Join Public Group
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  : null}
              </div>

              <div className="px-3 py-2 bg-gray-300 w-full">
                <div className="w-3/4 flex justify-between items-center m-auto ">
                  <div
                    className={`bg px-2 py-1 rounded-lg transition-all duration-300 
                   ${chatType === "chat" ? "bg-green-600" : "bg-white"}`}
                    onClick={() => setChatType("chat")}
                  >
                    <button>
                      <RiUser3Line className="text-3xl" />
                    </button>
                  </div>
                  <div
                    className={`px-2 py-1 rounded-lg transition-all duration-300 
                  ${chatType === "public-group" ? "bg-green-600" : "bg-white"}`}
                    onClick={() => setChatType("public-group")}
                  >
                    <button>
                      <GrGroup className="text-3xl" />
                    </button>
                  </div>
                  <div
                    className={` px-2 py-1 rounded-lg transition-all duration-300 
                  ${
                    chatType === "private-group" ? "bg-green-600" : "bg-white"
                  }`}
                    onClick={() => setChatType("private-group")}
                  >
                    <button>
                      <FaUserLock className="text-3xl" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}

      {selectedChat ? (
        <>
          {/* <button onClick={handleBackToChatList}>handleBackToChatList</button> */}
          <Chats
            data={{
              receiverId: selectedChat,
              CallContext: CallContext,
              AudioCallContext: AudioCallContext,
              GroupCallContext: GroupCallContext,
              NewGroupCallContext: NewGroupCallContext,
              senderId,
              senderName,
              receiverName,
              handleBackToChatList: handleBackToChatList,
              selectedChatImage: selectedChatImage,
              isGroup: isGroup,
              groupMemberCount: groupMemberCount,
              groupMembers: groupMembers,
              isCheckPrivateGroupMember: isCheckPrivateGroupMember,
              creator: creator,
            }}
          />
        </>
      ) : (
        <>
          {isDesktopView === false ? (
            <div className="flex flex-col w-full md:w-2/5 border-r-2 overflow-y-auto transition-all duration-300 cursor-pointer h-full">
              <div className="border border-[1px]-gray-600 rounded-b-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] hover:bg-gray-300 transition-all duration-300 cursor-pointer">
                <div className="flex justify-between items-center border-b pb-3 p-3">
                  <div className="flex justify-start items-center">
                    <div className="">
                      <img
                        src={userData?.hero_img}
                        className="object-cover h-12 w-12 border-[1px] border-black rounded-full"
                        alt="chat profile image"
                      />
                    </div>
                    <div className="mx-2">
                      <div className="text-lg font-semibold capitalize">
                        {User?.userData?.name}
                      </div>
                      <span className="text-gray-500 text-sm">Welcome </span>
                    </div>
                  </div>
                  <button
                    className="flex items-center ml-2 text-green-600 border border-green-600 px-2 py-1 rounded-lg sm:px-5 sm:py-2 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform mt-2"
                    onClick={handleGoBack}
                  >
                    <IoArrowBackSharp className="mr-2" /> Back
                  </button>
                </div>
                <div className="p-3">
                  <input
                    type="text"
                    placeholder="Search Chats"
                    className="py-2 px-2 rounded-lg w-full border border-gray-400"
                  />
                </div>

                <div className="flex justify-between items-center px-3 pb-3">
                  <button
                    className="flex items-center space-x-2 bg-blue-500 text-white px-4 py-2 rounded-md"
                    onClick={() => setCreateGroupPopup(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    Create Group
                  </button>

                  <button
                    className="flex items-center space-x-2 bg-green-500 text-white px-4 py-2 rounded-md"
                    onClick={() => setNewChatPopup(true)}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25px"
                      stroke="#fff"
                      strokeWidth="0.336"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22 12C22 17.5228 17.5228 22 12 22C10.4003 22 8.88837 21.6244 7.54753 20.9565C7.19121 20.7791 6.78393 20.72 6.39939 20.8229L4.17335 21.4185C3.20701 21.677 2.32295 20.793 2.58151 19.8267L3.17712 17.6006C3.28001 17.2161 3.22094 16.8088 3.04346 16.4525C2.37562 15.1116 2 13.5997 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.5303 9.46967C15.8232 9.76256 15.8232 10.2374 15.5303 10.5303L11.5303 14.5303C11.2417 14.819 10.7751 14.8238 10.4806 14.541L8.4806 12.621C8.18179 12.3342 8.1721 11.8594 8.45896 11.5606C8.74582 11.2618 9.22059 11.2521 9.5194 11.539L10.9893 12.9501L14.4697 9.46967C14.7626 9.17678 15.2374 9.17678 15.5303 9.46967Z"
                          fill="#fff"
                        ></path>
                      </g>
                    </svg>
                    New Chat
                  </button>
                </div>
              </div>
              <div className="h-full max-h-[90vh] overflow-y-auto">
                {/* {chatType === "chat"
                  ? chatList &&
                    chatList.map((user) => (
                      <div
                        className="flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer"
                        onClick={() => {
                          openChat(user?._id, user?.firstname, user?.hero_img);
                          setIsGroup(false);
                        }}
                        key={user?._id}
                      >
                        <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1 bg-white">
                          <img
                            src={user.hero_img}
                            className="rounded-full w-12 h-12 border-[1px] border-gray-500 bg-gray-300"
                            alt=""
                          />
                          <span className="absolute w-2.5 h-2.5 bg-green-500 border-2 border-white rounded-full top-7 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                        </div>

                        <div className="flex-grow overflow-hidden">
                          <h5 className="mb-1 font-semibold text-black capitalize">
                            {user?.name}
                          </h5>
                          <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px] block w-40 overflow-hidden whitespace-nowrap overflow-ellipsis">
                            {user?.lastMessage?.content}
                          </p>
                        </div>
                        <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                          {GetTimeforchat(user?.lastMessage?.createdAt)}
                        </div>
                      </div>
                    ))
                  : null} */}
                  {chatType === "chat" &&
                    sortedCombinedChatList.map((user) => {
              const isGroup = !!user.members; // Check if it's a group based on presence of members array

              return (
                <div
                  className="flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer"
                  onClick={() => {
                    if (isGroup) {
                      openChat(
                        user?._id,
                        user?.name,
                        true,
                        user?.members?.length,
                        user?.members,
                        user?.creator
                      );
                      setIsGroup(true);
                    } else {
                      openChat(user?._id, user?.name, user?.hero_img);
                      setIsGroup(false);
                    }
                    setIsCheckPrivateGroupMember(true);
                  }}
                  key={user?._id}
                >
                  {/* Render different UI for group vs individual chat */}
                  {isGroup ? (
                    <>
                      <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                        <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                          {user?.name?.charAt(0).toUpperCase()}
                        </p>
                        <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                      </div>
                      <div className="flex-grow overflow-hidden">
                        <h5 className="mb-1 font-semibold text-black capitalize">{user?.name}</h5>
                        <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                        {user?.lastMessage?.content}
                        </p>
                      </div>
                      <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                      {user?.lastMessage!=null?GetDate(user?.lastMessage?.createdAt):GetDate(user?.updatedAt)}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1 bg-white">
                        <img
                          src={user.hero_img}
                          className="rounded-full w-12 h-12 border-[1px] border-gray-500 bg-gray-300"
                          alt=""
                        />
                        <span className="absolute w-2.5 h-2.5 bg-green-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                      </div>

                      <div className="flex-grow overflow-hidden">
                        <h5 className="mb-1 font-semibold text-black capitalize ">{user?.name}</h5>
                        <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px] block w-40 overflow-hidden whitespace-nowrap overflow-ellipsis">
                          {user?.lastMessage?.content}
                        </p>
                      </div>
                      <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                        {GetDate(user?.lastMessage?.createdAt)}
                      </div>
                    </>
                  )}
                </div>
              );
              })}
                

                {chatType === "private-group"
                  ? getAllPrivateGroup &&
                    getAllPrivateGroup.map((user, index) => (
                      <div
                        className="flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer "
                        onClick={() => {
                          openChat(user?._id, user?.name, user?.members.length);
                          setIsGroup(true);
                        }}
                        key={user?._id}
                      >
                        <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                          <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300 capitalize">
                            {user?.name?.charAt(0).toUpperCase()}
                          </p>
                          <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                        </div>

                        <div className="flex-grow overflow-hidden">
                          <h5 className="mb-1 font-semibold text-black capitalize">
                            {user?.name}
                          </h5>
                          {/* <p className="mb-0 text-gray-500 truncate dark:text-gray-300 text-14">{user?.userHandle}</p> */}
                          <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                            {user?.lastMessage?.content}
                          </p>
                        </div>
                        <div className="text-gray-500 text-xs dark:text-gray-300 pt-1 relative">
                        {user?.lastMessage!=null?GetDate(user?.lastMessage?.createdAt):GetDate(user?.updatedAt)}

                          {!isPrivateGroupMember[index] && (
                            <div className="border border-blue-600 rounded-lg absolute top-[28px] right-0 w-28 text-center p-[2px] hover:border-green-600">
                              <button
                                className="text-xs px-1 hover:text-green-700 text-blue-600 "
                                onClick={() => {
                                  setPrivateGroupPop(true);
                                  handleJoinGroup(user?._id);
                                }}
                              >
                                Join Private Group
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  : null}

                {chatType === "public-group"
                  ? getAllPublicGroup &&
                    getAllPublicGroup.map((user, index) => (
                      <div
                        className="flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer"
                        onClick={() => {
                          openChat(user?._id, user?.name, user?.members.length);
                          setIsGroup(true);
                        }}
                        key={user?._id}
                      >
                        <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                          <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300 capitalize">
                            {user?.name?.charAt(0).toUpperCase()}
                          </p>
                          <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                        </div>

                        <div className="flex-grow overflow-hidden">
                          <h5 className="mb-1 font-semibold text-black capitalize">
                            {user?.name}
                          </h5>
                          {/* <p className="mb-0 text-gray-500 truncate dark:text-gray-300 text-14">{user?.userHandle}</p> */}
                          <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                            {user?.lastMessage?.content}
                          </p>
                        </div>
                        <div className="text-gray-500 text-xs dark:text-gray-300 pt-1 relative">
                        {user?.lastMessage!=null?GetDate(user?.lastMessage?.createdAt):GetDate(user?.updatedAt)}
                          {!isPublicGroupMember[index] && (
                            <div className="border border-blue-600 rounded-lg absolute top-[28px] right-0 w-28 text-center p-[2px] hover:border-green-600">
                              <button
                                className="text-xs px-1 hover:text-green-700 text-blue-600 "
                                onClick={() => {
                                  handleJoinGroup(user?._id);
                                }}
                              >
                                Join Public Group
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  : null}
              </div>

              <div className="px-3 py-2 bg-gray-300 w-full fixed bottom-0">
                <div className="w-3/4 flex justify-between items-center m-auto ">
                  <div
                    className={`px-2 py-1 rounded-lg  transition-all duration-300 ${
                      chatType === "chat" ? "bg-green-600" : "bg-white"
                    }`}
                    onClick={() => setChatType("chat")}
                  >
                    <button>
                      <RiUser3Line className="text-3xl" />
                    </button>
                  </div>
                  <div
                    className={`px-2 py-1 rounded-lg  transition-all duration-300 ${
                      chatType === "public-group" ? "bg-green-600" : "bg-white"
                    }`}
                    onClick={() => setChatType("public-group")}
                  >
                    <button>
                      <GrGroup className="text-3xl" />
                    </button>
                  </div>
                  <div
                    className={`px-2 py-1 rounded-lg  transition-all duration-300 ${
                      chatType === "private-group" ? "bg-green-600" : "bg-white"
                    } `}
                    onClick={() => setChatType("private-group")}
                  >
                    <button>
                      <FaUserLock className="text-3xl" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex w-full justify-center items-center">
              <div className="bg-slate-200 p-6">
                <h1 className="text-4xl font-bold text-indigo-600 capitalize">
                  Hi {User?.userData?.firstname},
                </h1>
                <p className="text-2xl text-gray-700">Welcome back!</p>
                <p>Get ready to start chatting with your friends.</p>
              </div>
            </div>
          )}
        </>
      )}
      {privateGroupPop && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-40">
          <div className="bg-white sm:px-8 px-4 py-12 rounded-lg shadow-lg sm:w-1/2 w-11/12 relative ">
            <button
              onClick={() => setPrivateGroupPop(false)}
              className="absolute top-3 right-6 text-xl"
            >
              X
            </button>
            <h2 className="text-4xl font-semibold my-4 text-center text-[#005AE6]">
              Private Group
            </h2>
            <p className="text-gray-700 mb-6 text-center text-lg">
              This is a private group. Please join first and then enter the
              password to access it.
            </p>
            <div className="sm:w-1/2 m-auto pb-5 w-11/12">
              <h2 className="mb-2 text-xl font-bold leading-tight text-[#005AE6] text-center">
                Private Group
              </h2>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Enter Password
                </label>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={groupPassword}
                  onChange={(e) => setGroupPassword(e.target.value)}
                  className="bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-4"
                />
              </div>
              <button
                className="w-full text-white hover:text-[#005AE6] border border-[#005AE6] hover:bg-white bg-[#005AE6] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={handleSubmitJoinGroup}
              >
                Verify Password
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const Chats = (data) => {
  const { Calluser } = useContext(data?.data?.CallContext);
  const { AudioCalluser } = useContext(data?.data?.AudioCallContext);
  const { joinGroupCall } = useContext(data?.data?.GroupCallContext);
  const { groupCallInit } = useContext(data?.data?.NewGroupCallContext);
  const [groupmembershow, setGroupmembershow] = useState(false);

  const { id } = useParams();
  const token = getCookie("savage_token");
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [groupMemberPopup, setGroupMemberPopup] = useState(false);
  const [groupMemberData, setGroupMemberData] = useState([]);
  const [filteredGroupMembers, setFilteredGroupMembers] = useState([]);
  const [searchGroupMember, setSearchGroupMember] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [imagePop, setImagePop] = useState(false);
  const audioRef = useRef(null);
  const chatContainerRef = useRef(null);
  const { userData } = useUser();
  const handleEmojiPickerhideShow = (e) => {
    const emojiButton = document.querySelector(".emoji");
    if (emojiButton.contains(e.target)) {
      setShowEmojiPicker(!showEmojiPicker);
    } else {
      setShowEmojiPicker(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleEmojiPickerhideShow);
    return () => {
      document.removeEventListener("click", handleEmojiPickerhideShow);
    };
  }, []);
  const handleEmojiClick = (e, emojiObject) => {
    setInputValue((prevInputValue) => prevInputValue + e.emoji);
  };
  const senderName = data?.data?.senderName;
  const receiverName = data?.data?.receiverName;
  const senderId = data?.data?.senderId;
  const receiverId = data?.data?.receiverId;
  const selectedChatImage = data?.data?.selectedChatImage;
  const isGroup = data?.data?.isGroup;
  const groupMemberCount = data?.data?.groupMemberCount;
  const groupMembers = data?.data?.groupMembers || [];
  const getIds = groupMembers.map((item) => item._id);
  getIds.push(data?.data?.creator);
  // console.log("groupMember", getIds);
  const isCheckPrivateGroupMember = data?.data?.isCheckPrivateGroupMember;

  useEffect(() => {
    const fetchMessages = async () => {
      const payload = isGroup ? { isGroup: true } : {};
      const config = {
        url: `${apiurl}/message/${senderId}/${receiverId}`,
        method: "POST",
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: payload,
      };
      try {
        const response = await axios.request(config);
        const data = await response.data;
        setMessages(data.messages);
        handlegetGroupMember();
        setGroupmembershow(true);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
    fetchMessages();
  }, [receiverId]);
  useEffect(() => {
    socket.on("receive-message", handleReceivedMessage);
    return () => {
      socket.off("receive-message", handleReceivedMessage);
    };
  }, []);
  const handleReceivedMessage = (newMessage) => {
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };
  const sendMessage = async (e) => {
    e.preventDefault();
    if (inputValue) {
      try {
        const response = await axios.post(`${apiurl}/message/send`, {
          senderId,
          receiverId,
          content: inputValue,
          isGroup,
          media: imageUrl,
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
        setInputValue("");
        setImagePop(false);
        setImageUrl("");
        setMessages([...messages, response.data.message]);
        socket.emit("send-message", response.data.message);
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };
  useEffect(() => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [messages]);
  const handleUpload = async (imageType, send_img) => {
    setImageLoading(true);
    setImagePop(false);
    setImageUrl(send_img);
    try {
      const updatedData = {
        [imageType]: send_img,
      };
      const response = await axios.put(
        `${apiurl}/user/update/${userData?._id}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.status === 200) {
        setImageLoading(false);
        setImagePop(false);
        toast.success("Image Uploaded Successfully");
      } else {
        toast.error("Failed to Image Uploaded");
      }
    } catch (error) {
      console.error("Image Uploaded error", error);
    }
  };
  const ImageHandler = async (e) => {
    setImageLoading(true);
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: awsKeyId,
        secretAccessKey: awsSecretKey,
      });
      const params = {
        Bucket: "savageseller",
        Key: `${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type,
      };
      let { Location } = await s3.upload(params).promise();
      setImageLoading(false);
      return Location;
    } catch (exception) {
      console.log(exception);
      return false;
    } finally {
    }
  };
  const setImage = async (e) => {
    const image = await ImageHandler(e);
    if (image) {
      await handleUpload("post_img", image);
    }
  };
  const isVideoExtension = (url) => {
    if (!url) return false;
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    const videoExtensionchange = videoExtensions.toString();
    const extension = getExtension(url);
    const myurl = videoExtensionchange.includes(extension);
    return myurl;
  };
  const getExtension = (url) => {
    return url.split(".").pop().toLowerCase();
  };
  const handlegetGroupMember = async () => {
    setGroupMemberPopup(true);
    try {
      const response = await axios.get(
        `${apiurl}/message/get-group-members/${receiverId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setGroupMemberData(response.data.members);
        setFilteredGroupMembers(response.data.members);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("get group member error", error);
    }
  };
  // useEffect(() => {
  //   socket.on("receive-message", (newMessage) => {
  //     handleReceivedMessage(newMessage);
  //     // console.log("newMessage", newMessage);
  //   });
  //   socket.on("disconnect", () => {
  //     console.log("Disconnected from the server");
  //   });
  // });
  return (
    <div
      className={`${
        isCheckPrivateGroupMember === undefined
          ? "flex flex-col items-center sm:justify-between h-full w-full"
          : isCheckPrivateGroupMember === true
          ? "flex flex-col items-center sm:justify-between h-full w-full"
          : "flex flex-col items-center sm:justify-between h-full w-full blur-[15px] filter"
      }`}
    >
      <div className="fixed sm:relative sm:z-0 z-[999] top-0  flex flex-row py-3 px-2 items-center border-b-2 bg-white w-full cursor-pointer">
        <button onClick={data?.data?.handleBackToChatList} className="ddd">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>

        {selectedChatImage && selectedChatImage.length > 0 ? (
          <div className="mx-1 border-[1px] border-gray-500 rounded-full p-1">
            <img
              src={selectedChatImage}
              className="object-cover sm:h-11 sm:w-14 w-20 h-11 rounded-full border-[1px] border-gray-500"
              alt="profile image"
            />
          </div>
        ) : (
          <div className="mr-3 border-[1px] border-gray-500 rounded-full p-1">
            <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
              {receiverName?.charAt(0).toUpperCase()}
            </p>
          </div>
        )}
        <div
          className="w-full"
          onClick={() =>
            isGroup
              ? (handlegetGroupMember(), setGroupmembershow(false))
              : setGroupmembershow(true)
          }
        >
          <h5 className="text-lg font-semibold sm:w-full w-40 overflow-hidden text-ellipsis whitespace-nowrap capitalize">
            {receiverName}
          </h5>
          <p className="text-gray-500 text-xs sm:w-full w-36 overflow-hidden text-ellipsis whitespace-nowrap capitalize">
            {`Hi ${receiverName}, Welcome `}
            <span className="text-gray-700 text-xs font-semibold">
              {groupMemberCount ? `(${groupMemberCount} Members)` : ""}
            </span>
          </p>
        </div>

        <div className="flex flex-col items-center justify-between w-32">
          <hr />
          <div>
            <audio ref={audioRef} autoPlay />
            <div className="flex items-center justify-center gap-2">
              {!isGroup ? (
                <>
                  <button
                    className="bg-green-500 text-white px-3 py-2 rounded-full 1"
                    // onClick={() => callUser(receiverId, userData?._id)}
                    onClick={() => {
                      AudioCalluser(receiverId);
                    }}
                  >
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 15 15"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      fill="#000000"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />

                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <defs> </defs>{" "}
                        <g
                          id="Page-1"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          {" "}
                          <g
                            id="Dribbble-Light-Preview"
                            transform="translate(-103.000000, -7321.000000)"
                            fill="#FFF"
                          >
                            {" "}
                            <g
                              id="icons"
                              transform="translate(56.000000, 160.000000)"
                            >
                              {" "}
                              <path
                                d="M61.7302966,7173.99596 C61.2672966,7175.40296 59.4532966,7176.10496 58.1572966,7175.98796 C56.3872966,7175.82796 54.4612966,7174.88896 52.9992966,7173.85496 C50.8502966,7172.33496 48.8372966,7169.98396 47.6642966,7167.48896 C46.8352966,7165.72596 46.6492966,7163.55796 47.8822966,7161.95096 C48.3382966,7161.35696 48.8312966,7161.03996 49.5722966,7161.00296 C50.6002966,7160.95296 50.7442966,7161.54096 51.0972966,7162.45696 C51.3602966,7163.14196 51.7112966,7163.84096 51.9072966,7164.55096 C52.2742966,7165.87596 50.9912966,7165.93096 50.8292966,7167.01396 C50.7282966,7167.69696 51.5562966,7168.61296 51.9302966,7169.09996 C52.6632966,7170.05396 53.5442966,7170.87696 54.5382966,7171.50296 C55.1072966,7171.86196 56.0262966,7172.50896 56.6782966,7172.15196 C57.6822966,7171.60196 57.5872966,7169.90896 58.9912966,7170.48196 C59.7182966,7170.77796 60.4222966,7171.20496 61.1162966,7171.57896 C62.1892966,7172.15596 62.1392966,7172.75396 61.7302966,7173.99596 C61.4242966,7174.92396 62.0362966,7173.06796 61.7302966,7173.99596"
                                id="call-[0f7511]"
                              >
                                {" "}
                              </path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </button>
                  <button
                    className="bg-green-500 text-white px-3 py-2 rounded-full 2"
                    // onClick={() => callUser(receiverId, userData?._id)}
                    onClick={() => {
                      Calluser(receiverId);
                    }}
                  >
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 1024 1024"
                      className="icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />

                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M170.666667 256h469.333333c46.933333 0 85.333333 38.4 85.333333 85.333333v341.333334c0 46.933333-38.4 85.333333-85.333333 85.333333H170.666667c-46.933333 0-85.333333-38.4-85.333334-85.333333V341.333333c0-46.933333 38.4-85.333333 85.333334-85.333333z"
                          fill="#fff"
                        />

                        <path
                          d="M938.666667 746.666667l-213.333334-128V405.333333l213.333334-128z"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="bg-green-500 text-white px-3 py-2 rounded-full 1"
                    // onClick={() => callUser(receiverId, userData?._id)}
                    onClick={() => {
                      groupCallInit(
                        {
                          members: groupMemberData,
                          room: receiverId,
                          name: receiverName,
                        },
                        false
                      );
                    }}
                  >
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 15 15"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      fill="#000000"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />

                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <defs> </defs>{" "}
                        <g
                          id="Page-1"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          {" "}
                          <g
                            id="Dribbble-Light-Preview"
                            transform="translate(-103.000000, -7321.000000)"
                            fill="#FFF"
                          >
                            {" "}
                            <g
                              id="icons"
                              transform="translate(56.000000, 160.000000)"
                            >
                              {" "}
                              <path
                                d="M61.7302966,7173.99596 C61.2672966,7175.40296 59.4532966,7176.10496 58.1572966,7175.98796 C56.3872966,7175.82796 54.4612966,7174.88896 52.9992966,7173.85496 C50.8502966,7172.33496 48.8372966,7169.98396 47.6642966,7167.48896 C46.8352966,7165.72596 46.6492966,7163.55796 47.8822966,7161.95096 C48.3382966,7161.35696 48.8312966,7161.03996 49.5722966,7161.00296 C50.6002966,7160.95296 50.7442966,7161.54096 51.0972966,7162.45696 C51.3602966,7163.14196 51.7112966,7163.84096 51.9072966,7164.55096 C52.2742966,7165.87596 50.9912966,7165.93096 50.8292966,7167.01396 C50.7282966,7167.69696 51.5562966,7168.61296 51.9302966,7169.09996 C52.6632966,7170.05396 53.5442966,7170.87696 54.5382966,7171.50296 C55.1072966,7171.86196 56.0262966,7172.50896 56.6782966,7172.15196 C57.6822966,7171.60196 57.5872966,7169.90896 58.9912966,7170.48196 C59.7182966,7170.77796 60.4222966,7171.20496 61.1162966,7171.57896 C62.1892966,7172.15596 62.1392966,7172.75396 61.7302966,7173.99596 C61.4242966,7174.92396 62.0362966,7173.06796 61.7302966,7173.99596"
                                id="call-[0f7511]"
                              >
                                {" "}
                              </path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </button>
                  <button
                    className="bg-green-500 text-white px-3 py-2 rounded-full 2"
                    // onClick={() => callUser(receiverId, userData?._id)}
                    onClick={() => {
                      groupCallInit(
                        {
                          members: groupMemberData,
                          room: receiverId,
                          name: receiverName,
                        },
                        true
                      );
                    }}
                  >
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 1024 1024"
                      className="icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />

                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M170.666667 256h469.333333c46.933333 0 85.333333 38.4 85.333333 85.333333v341.333334c0 46.933333-38.4 85.333333-85.333333 85.333333H170.666667c-46.933333 0-85.333333-38.4-85.333334-85.333333V341.333333c0-46.933333 38.4-85.333333 85.333334-85.333333z"
                          fill="#fff"
                        />

                        <path
                          d="M938.666667 746.666667l-213.333334-128V405.333333l213.333334-128z"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        ref={chatContainerRef}
        className="flex flex-col gap-2 w-full p-4 overflow-y-scroll overflow-x-hidden sm:mt-0 mt-[81px]"
        style={{ maxHeight: "650px" }}
      >
        <div className="chat-container h-[650px]">
        {messages.length>0?
          messages.map((message) => {
            const sanitizedContent = DOMPurify.sanitize(message.content);
            const isSender = message.sender === senderId;
            const messageClasses = `relative max-w-72 min-w-20 rounded-md ${
              isSender
                ? "self-end bg-gray-200 mr-3 text-black"
                : "self-start bg-blue-500 text-left ml-3 text-white"
            }`;
            const triangleClasses = `absolute ${
              isSender
                ? "right-0 bottom-[-10px] mx-auto h-0 w-0 border-r-31p border-b-35p border-t-35p border-solid border-r-transparent border-l-transparent border-b-[#000] rotate-90 border-gray-200"
                : "left-0 bottom-[-10px] mx-auto h-0 w-0 border-r-31p border-b-35p border-t-35p border-solid border-r-transparent border-l-transparent border-b-[#000] border-t-[17px] border-r-[17px] transform rotate-0 border-blue-500"
            } border-solid border-t-[17px] border-r-[17px] h-0 w-0 transform`;

            return (
              <div
                key={message?._id}
                className={`flex my-6 ${
                  isSender ? "justify-end" : "justify-end flex-row-reverse"
                }`}
              >
                <div className={messageClasses}>
                  <div className="rounded-xl mb-5">
                    {message.media && (
                      <div className="rounded-xl m-4">
                        {isVideoExtension(message.media) ? (
                          <video
                            className="w-auto h-auto rounded-lg relative m-auto"
                            controls
                            autoPlay
                            loop
                            muted
                          >
                            <source
                              src={message.media}
                              type="video/mp4"
                              alt="video"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            src={message.media}
                            alt="image"
                            className="w-auto h-auto rounded-lg relative m-auto"
                          />
                        )}
                        <div
                          className="w-3/4"
                          dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                        />
                      </div>
                    )}
                    {!message.media && (
                      <div
                        className="w-3/4 m-4"
                        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                      />
                    )}
                  </div>
                  <hr />
                  <p
                    className={`text-xs ${
                      isSender
                        ? "text-left text-[#74788D] ml-4 mb-2"
                        : "text-right text-[#B9B4F7] mr-2 mb-2"
                    }`}
                  >
                    <span className="align-middle">
                      {GetTimeforchat(message.createdAt)}
                    </span>
                  </p>
                  <div className={triangleClasses}></div>
                </div>
                <div className="bg-white flex items-center justify-center rounded-full m-1 h-10 w-10">
                  {isSender ? (
                    <div className="flex justify-center items-center border-[1px] bg-blue-400 border-gray-500 rounded-full sm:py-[7px] sm:px-[13px] py-1 px-[10px]">
                      
                        <p className="sm:text-xl text-lg">
                          {message.senderFirstName?.charAt(0).toUpperCase()||userData.firstname?.charAt(0).toUpperCase()}
                        </p>
                      
                    </div>
                  ) : (
                    <div className="flex justify-center items-center border-[1px] bg-green-400 border-gray-500 rounded-full sm:py-[7px] sm:px-[13px] py-1 px-[10px] ">
                      <Link to={`/profile/${message?.sender}`}>
                        <p className="sm:text-xl text-lg">
                          {message.receiverFirstName?.charAt(0).toUpperCase()}
                        </p>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            );
          }):<p className="flex justify-center font-bold text-[20px]">You have no previous chat</p>}
        </div>
      </div>
      {/* Input for sending messages */}
      <div className="sm:relative fixed bottom-0 flex items-center w-full border-t-[1px] bg-[#fff]">
        <div>
          {imageUrl && imageUrl.length > 0 && (
            <div
              onClick={() => setImagePop(false)}
              className="relative m-2 w-11/12 ml-6 mb-6"
            >
              {isVideoExtension(imageUrl) === true ? (
                <video
                  className="w-full h-[100px] rounded-lg relative"
                  controls
                  autoPlay
                  loop
                  muted
                >
                  <source
                    src="https://savageseller.s3.amazonaws.com/380f8d1f-4e9e-4a33-9dbc-f1a7b6937ec2.mp4"
                    type="video/mp4"
                    alt="video"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={imageUrl}
                  alt="image"
                  className="w-full h-[100px] rounded-lg relative"
                />
              )}
              <span
                onClick={() => setImageUrl("")}
                className="absolute top-2 right-2 text-lg z-20 text-black border border-white rounded-full px-2  cursor-pointer bg-white"
              >
                X
              </span>
            </div>
          )}
        </div>

        <form
          className="w-full flex sm:m-6 mx-3 my-4 mb-6 relative"
          onSubmit={(e) => sendMessage(e)}
        >
          <div className="button-container flex items-center absolute left-[5px] top-[12px]">
            <div className="emoji text-yellow-600 text-xl">
              <BsEmojiSmileFill onClick={handleEmojiPickerhideShow} />
              {showEmojiPicker && <Picker onEmojiClick={handleEmojiClick} />}
            </div>
          </div>
          <input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="flex-grow py-2 px-8 rounded-md border border-gray-400 w-11/12"
            placeholder="Type your message . . ."
          />
          <label
            htmlFor="fileInput"
            className={`${
              imageUrl && imageUrl.length > 0 ? "bg-green-500" : "bg-blue-500"
            } text-white cursor-pointer rounded mb-0 flex items-center px-2 py-[2px] ml-2`}
          >
            <MdAddPhotoAlternate className="text-4xl" />
            <input
              id="fileInput"
              type="file"
              className="hidden"
              name="send_img"
              onChange={(e) => {
                setImage(e);
              }}
            />
          </label>
          <button
            className={`${
              imageLoading === true ? "bg-green-500" : "bg-blue-500"
            } hover:bg-blue-700 text-white font-bold py-2 sm:px-4 px-3 rounded ml-2 sm:text-base text-sm`}
            type="button"
            onClick={sendMessage}
            disabled={imageLoading}
          >
            {imageLoading === true ? "Uploading . . ." : "Send"}
          </button>

          {groupMemberPopup === true && !groupmembershow ? (
            <>
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-30">
                <div className="bg-white sm:p-6 p-2 rounded-xl shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] text-center flex justify-start items-center flex-col sm:w-2/5 w-11/12">
                  <div className="flex justify-between items-center w-full">
                    <p className="text-lg">All Group Members</p>
                    <button
                      className=""
                      onClick={() => setGroupMemberPopup(false)}
                    >
                      X
                    </button>
                  </div>
                  <div className="w-full p-2">
                    <div className="my-1 p-1 flex bg-[#fff] border border-gray-400 w-full rounded-lg">
                      <input
                        type="text"
                        className="px-2 py-2 w-full focus:outline-none"
                        placeholder="Search Users . . ."
                        value={searchGroupMember}
                        onChange={(e) => {
                          const mydata = e.target.value.toLowerCase();
                          setSearchGroupMember(mydata);
                          setFilteredGroupMembers(
                            groupMemberData.filter((member) =>
                              member.name.toLowerCase().includes(mydata)
                            )
                          );
                        }}
                      />
                      <button className="px-4 focus:outline-none">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          className="text-[#000]"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                        </svg>
                      </button>
                    </div>

                    <div className="sm:mt-4 mt-2 w-full bg-gray-100 border border-gray-300 rounded-lg shadow-md overflow-y-scroll h-44">
                      {filteredGroupMembers.map((user, index) => (
                        <Link to={`/profile/${user._id}`}>
                          <label
                            className="flex justify-left px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer items-center"
                            onClick={() => user?._id}
                            key={index}
                          >
                            <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                              <img
                                className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 font-bold text-uppercase bg-slate-300"
                                src={user.hero_img}
                              />
                              <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-12 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                            </div>
                            <div className="flex justify-between w-3/4">
                              <div className="text-left">
                                <h5 className="font-semibold text-black ">
                                  {user?.name}
                                </h5>
                                <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                                  {user?.designation}
                                </p>
                              </div>
                              <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                                {GetTimeforchat(user?.createdAt)}
                              </div>
                            </div>
                          </label>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </form>
      </div>
    </div>
  );
};

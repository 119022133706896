import React from "react";
import { LiaStarSolid } from "react-icons/lia";

const Wishlist = () => {
  return (
    <div className="p-4 bg-gray-200">
      <div className="p-4 bg-white">
        <div className=" rounded-xl lg:flex justify-between items-center sm:flex-row">
          <div className="flex justify-between w-full lg:w-3/5 xl:w-[45%] flex-col sm:flex-row sm:w-11/12">
            <div className=" w-full xl:w-64 sm:w-64">
              <img src="/assets/community.jpg" alt="" className="w-full" />
            </div>
            <div className="mt-6 sm:mt-0">
              <p className="text-[#005AE6] font-bold text-lg">
                Sound City: Real to Reel
              </p>
              <p className="text-lg">by Sound City Real to Reel (audio CD)</p>
              <div className="flex items-center">
                <LiaStarSolid className="text-[#f1c65c]" />
                <LiaStarSolid className="text-[#f1c65c]" />
                <LiaStarSolid className="text-[#f1c65c]" />
                <LiaStarSolid className="text-[#f1c65c]" />
                <span className="text-[#005AE6] ml-2">(141)</span>
              </div>
              <p className="text-red-600 font-bold">$11.12</p>
              <p className="text-lg">In Stoks offers of by Community</p>
              <p className="text-sm font-bold text-[#005AE6]">
                70 Used & New <span className="text-black">from</span>{" "}
                <span className="text-red-600">$10.13</span>
              </p>
              <p className="text-[#005AE6] mt-3 font-bold text-lg">
                Add comments, quantity & priority
              </p>
            </div>
          </div>
          <div className="lg:w-3/12 lg:m-0 sm:w-11/12 sm:m-auto sm:mt-0 w-full mt-8">
            <h3 className="mb-2 font-bold">Added December 19, 2023</h3>
            <div className="text-center bg-[#005AE6] text-white px-8 py-2 rounded-md">
              <button className="text-lg font-semibold"> Add to Cart</button>
            </div>

            <div className="text-center bg-gray-200 px-8 py-2 rounded-md mt-3">
              <button className="text-lg font-semibold">Delete</button>
            </div>
          </div>
        </div>
        <hr className="border-t-2 border-gray-300 my-10 w-11/12 mx-auto h-2" />
      </div>
    </div>
  );
};

export default Wishlist;

// EditProfile.js
import React from "react";
import ProfileLeftSidebar from "../components/ProfileLeftSideBar";
import { removeCookie } from "../config/coockies";
import { useUser } from "../contexts/UserProvider";
import Layout from "../components/layout";
import { MdVerified } from "react-icons/md";

const EditProfile = () => {
  return (
    <Layout>
      <div className="md:container md:mx-auto w-[100%] lg:w-[70%] m-auto pb-10">
        <hr className="h-px bg-gray-200 border-0 my-4"></hr>

        <div className="block md:flex md:container">
          {/* Right Content Area */}
          <div className="w-full md:w-3/4 sm:p-8 sm:pt-0">
            <div className="w-full p-4 border border-solid border-light-gray-200">
              <h2 className="text-2xl font-bold mb-4">Edit Details</h2>

              {/* Mobile Number */}
              <div className="mb-4">
                <label
                  htmlFor="mobileNumber"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Mobile Number
                </label>
                <div className="flex items-center relative">
                  <input
                    type="text"
                    id="mobileNumber"
                    className="w-full pl-8 border rounded-md p-2 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                    value={"+91-9876543210"}
                    placeholder="Enter your mobile number"
                    disabled
                  />
                  <MdVerified className="absolute text-[#17af17] text-2xl left-1.5" />
                  <button className="w-1/2 px-4 py-2 border border-[#005AE6] rounded-md ml-4 bg-[#E0E9F7] text-[#005AE6]">
                    Edit
                  </button>
                </div>
              </div>

              {/* Full Name */}
              <div className="mb-4">
                <label
                  htmlFor="fullName"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="fullName"
                  className="w-full border rounded-md p-2 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                  placeholder="Enter your full name"
                />
              </div>

              {/* Email */}
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full border rounded-md p-2 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                  placeholder="Enter your email"
                />
              </div>

              {/* Gender */}
              <div className="mb-4 flex">
                <label className="w-1/2 block text-sm font-medium text-gray-700 mb-2">
                  Gender
                </label>
                <div className="flex items-center w-1/2">
                  <label className="flex items-center mr-4">
                    <input
                      type="radio"
                      className="form-radio text-blue-500"
                      name="gender"
                      value="male"
                    />
                    <span className="ml-2">Male</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-pink-500"
                      name="gender"
                      value="female"
                    />
                    <span className="ml-2">Female</span>
                  </label>
                </div>
              </div>

              {/* Birthday */}
              <div className="mb-4">
                <label
                  htmlFor="birthday"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Birthday (DD/MM/YYYY)
                </label>
                <input
                  type="date"
                  id="birthday"
                  className="w-full border rounded-md p-2 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                  placeholder="Enter your birthday"
                />
              </div>

              {/* Save Details Button */}
              <button className="w-full bg-[#005AE6] text-white px-4 py-2 rounded-md">
                Save Details
              </button>
            </div>
          </div>
          {/* Left Sidebar */}
          <ProfileLeftSidebar />
        </div>
      </div>
    </Layout>
  );
};

export default EditProfile;

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { FaCheck } from "react-icons/fa";
import { apiurl,marketplaceapiurl } from "../config/config";
import { useUser } from "../contexts/UserProvider";
import LoaderSpiner from "./loader";
import { toast } from "react-toastify";
import { getCookie } from "../config/coockies";
import { GetTimeAgo } from "./getTime";
import { FaComment, FaShare, FaThumbsUp } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { awsKeyId, awsSecretKey } from "../config/config";
import { v4 as uuidv4 } from "uuid";
import { MdAddPhotoAlternate } from "react-icons/md";

const aws = require("aws-sdk");

const ProfilePost = ({ activeTab,selectedTab }) => {
  const token = getCookie("savage_token");
  const { userData } = useUser();
  const [profilePosts, setProfilePosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState(false);
  const [replyRepostId, setReplyRePostID] = useState("");
  const [commentReply, setCommentReply] = useState(true);
  const [replyPost, setReplyPost] = useState([]);
  const [draftPost, setDraftPost] = useState(null);
  const [commentId, setCommentId] = useState(null);
  const [editPostComment, setEditPostComment] = useState();
  const [editPostCommentpop, setEditPostCommentpop] = useState(false);
  const [postCommentData, setPostCommentData] = useState();
  const [replyId, setReplyId] = useState(null);
  const [editPostCommentReplypop, setEditPostCommentReplypop] = useState(false);
  const [editPostCommentReply, setEditPostCommentReply] = useState();
  const [sharePostPop, setSharePostPop] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [chatList, setchatList] = useState([]);
  const [isChatId, setIsChatId] = useState([]);
  const [isGroupId, setIsGroupId] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [filteredGroupList, setFilteredGroupList] = useState([]);
  const [commentImg, setCommentImg] = useState("");
  const [editedCommentImg, setEditedCommentImg] = useState(null);
  const [editedReplyImg, setEditedReplyImg] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [community, setCommunity] = useState([]);

  const handleGetPostData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiurl}/post/get-cratedby-post/${userData._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log("get profile post response   >>>>>>>>>-------", response);
      if (response.data.success === true) {
        setLoading(false);
        let reversedPosts = response?.data?.Response.reverse();
        if (activeTab === "mypost") {
          const activepost = reversedPosts.filter((item) => !item.buyNowUrl);
          setProfilePosts(activepost);
        } else if (activeTab === "product_post") {
          const productPost = reversedPosts.filter(
            (item) => item.buyNowUrl?.length > 0 || item.buyNowUrl
          );
          setProfilePosts(productPost);
        }
      } else {
        console.error("Failed to get the profile post");
        setLoading(false);
      }
    } catch (error) {
      console.error("my profile post data", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPostData();
    handleDraftPost();
  }, []);

  let myUserID = userData?._id;
  let myUserName = userData?.name;

  const getImage = async (e) => {
    setIsImageLoading(true);
    const image = await ImageHandler1(e);
    if (image) {
        setCommentImg(image);
        setIsImageLoading(false);
    }
  };
  const getEditedCommentImg = async (e) => {
      setIsImageLoading(true);
      const image = await ImageHandler1(e);
      if (image) {
          setEditedCommentImg(image);
          setIsImageLoading(false);
      }
    };
  const getEditedReplyImg = async (e) => {
      setIsImageLoading(true);
      const image = await ImageHandler1(e);
      if (image) {
          setEditedReplyImg(image);
          setIsImageLoading(false);
      }
    };

  const ImageHandler1 = async (e) => {
  setIsImageLoading(true);
  try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
      accessKeyId: awsKeyId,
      secretAccessKey: awsSecretKey,
      });
      const params = {
      Bucket: "savageseller",
      Key: `${uuidv4()}.${myFileType}`,
      Body: file,
      ContentType: file?.type,
      };
      let { Location } = await s3.upload(params).promise();
      setIsImageLoading(false);
      return Location;
  } catch (exception) {
      console.log(exception);
      return false;
  } finally {
  }
  };

  const createLikeNotification = async (post) => {
      const data = {
          userId: {
              _id: userData?._id,
          },
          type:  "LIKE",
          message: `Liked the comment` ,
          community: {
              _id: post?.community?._id
          },
          post: {
          _id: post?._id,
              },
          author: {
              _id: post?.author,
          }
      }
      try {
          const response = await axios.post(
          `${apiurl}/notifications/createNotification`, data
          ,
          {
              headers: {
              "Content-Type": "application/json",
              Authorization: token,
              },
          }
          );
      } catch (error) {
          setLoading(false);
          console.error("Like Notification error", error);
      }
  };

  const deleteLikeNotification = async (post) => {
      const data = {
          userId: {
              _id: post?.author,
          },
          type:  "LIKE",
          community: {
              _id: post?.community._id,
          },
          post:{
              _id:post?._id,
          },
          author: {
              _id: post?.author,
          }
      }
      try {
          const response = await axios.delete(
          `${apiurl}/notifications/deleteLikeNotification`
          ,
          {
              data: data,
              headers: {
              "Content-Type": "application/json",
              Authorization: token,
              },
          }
          );
      } catch (error) {
          setLoading(false);
          console.error("Delete Like Notification error", error);
      }
  };

  const likePost = async (postId, communityHandle) => {
    try {
      // Ensure community data is fetched
      await fetchCommunities(communityHandle);
  
      // Proceed with like request
      let data = JSON.stringify({
        userid: `${userData?._id}`,
        username: `${userData?.name}`,
      });
  
      let config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `${apiurl}/post/post/${postId}/like`,
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-store",
          Authorization: token,
        },
        data: data,
      };
  
      const response = await axios.request(config);
  
      const updatedPosts = profilePosts.map((post) => {
        if (post._id === postId) {
          return { ...post, likes: response.data.post.likes };
        }
        return post;
      });
      setProfilePosts(updatedPosts);
  
      const responsePost = response?.data?.post;
      const userLiked = responsePost.likes.likedBy.some(
        (like) => like._id === userData?._id
      );
  
      if (userLiked) {
        createLikeNotification(responsePost);
      } else {
        deleteLikeNotification(responsePost);
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  const handleLike = async (e, post) => {
    e.preventDefault();
    await likePost(post._id, post.community?.community_handle);
  };
  

  const commentReplyHandle = (comment_id) => {
    setTags(true);
    setCommentReply(true);
    setReplyRePostID(comment_id);
  };

  const handleReplyPost = (postId) => {
    if (commentReply === true && replyRepostId !== "") {
      // console.log("Comment replyRepostId >>>>>>>>>>>>>>>>>>>", replyRepostId);

      if (replyPost.length <= 0 && commentImg === ""){
        toast.error("Please enter your reply");
        return;
      }

      let data = JSON.stringify({
        userid: myUserID,
        username: myUserName,
        text: replyPost[0]?.value,
        images:[
            {
                url: commentImg,
                description: replyPost[0]?.value,
                _id: userData?._id,
            }
        ]
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiurl}/post/post/${postId}/comment/${replyRepostId}/reply`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.status === 200) {
            commentReplyHandle(false);
            setTags(false);
            setReplyRePostID("");
            setReplyPost([]);
            handleGetPostData();
            setCommentImg("");
            toast.success("Reply posted successfully");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      if (replyPost.length <= 0 && commentImg === ""){
        toast.error("Please enter your comment");
        return;
      }
      let data = JSON.stringify({
        userid: userData?._id,
        username: userData?.name,
        text: replyPost[0]?.value,
        images:[
            {
                url: commentImg,
                description: replyPost[0]?.value,
                _id: userData?._id,
            }
        ]
      });


      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiurl}/post/post/${postId}/comment`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          if (response.status === 200) {
            // setPost((prevPost) => ({
            //     ...prevPost,
            //     comments: response?.data?.post.comments,
            // }));
            setReplyPost([]);
            handleGetPostData();
            setCommentImg("");
            toast.success("comment posted successfully");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getInputValue = (reply, index) => {
    if (reply.length === 0) {
      return "";
    }
    const item = reply.find((item) => item.id === index);
    return item ? item.value : "";
  };

  const isVideoExtension = (url) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    const videoExtensionsChange = videoExtensions.toString();
    const extension = getExtension(url);
    const videoData = videoExtensionsChange.includes(extension)
      ? "video"
      : imageExtensions.includes(extension)
      ? "image"
      : "unknown";
    return videoData;
  };
  const getExtension = (url) => {
    if (!url) return "";
    const splitUrl = url.split(".");
    if (
      splitUrl.length === 1 ||
      (splitUrl[0] === "" && splitUrl.length === 2)
    ) {
      return "";
    }
    return splitUrl.pop().toLowerCase();
  };
  // const toggleOptions = (postId) => {
  //   setOption((prevState) => ({
  //     ...prevState,
  //     [postId]: !prevState[postId],
  //   }));
  // };

  const [option, setOption] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedContent, setEditedContent] = useState("");
  const [editedImage, setEditedImage] = useState(null);
  const [postid, setPostid] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [activePost, setActivePost] = useState("active_post");
  const [sharePostId, setSharePostId] = useState();
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null); 

  const handleEditModalOpen = (post) => {
    setPostid(post._id);
    setEditingPost(post);
    setEditedTitle(post.title);
    setEditedContent(post.content);
    setEditedImage(post.images[0]?.url || "");
  };

  const handleEditModalClose = () => {
    setEditingPost(null);
    setEditedTitle("");
    setEditedContent("");
    setEditedImage("");
  };

  const ImageHandler = async (e) => {
    setImageLoading(true);
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: awsKeyId,
        secretAccessKey: awsSecretKey,
      });
      const params = {
        Bucket: "savageseller",
        Key: `${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type,
      };
      let { Location } = await s3.upload(params).promise();
      setImageLoading(false);
      setEditedImage(Location);
      return Location;
    } catch (exception) {
      console.log(exception);
      return false;
    } finally {
    }
  };

  const editPost = async (id) => {
    try {
      const updatedPost = {
        title: editedTitle,
        description: editedContent,
        images: [
          {
            url: editedImage,
            description: editedTitle,
          },
        ],
      };
      // Send request to update post data
      const response = await axios.put(
        `${apiurl}/post/update-post/${id}`,
        updatedPost,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // Update the posts state with the updated post
      handleGetPostData();
      handleEditModalClose();
    } catch (error) {
      console.error("Error editing post:", error);
    }
  };

  const handleDeletePostNotification = async (post) => {
    const data = {
        userId: {
            _id: post?.author,
        },
        type:  "POST_CREATE",
        community: {
            _id: post?.community._id,
        },
        post:{
            _id:post?._id,
        },
        author: {
            _id: community?.author?._id,
        }
    }
    try {
      const response = await axios.delete(
        `${apiurl}/notifications/deleteNotification`
        ,
        {
          data: data,
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
    } catch (error) {
      console.error("Delete Post Notification error", error);
    }
  };

  const deletePost = async (post) => {
    try {
      const response = await axios.delete(`${apiurl}/admin/deletepost/${post?._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      handleDeletePostNotification(post);
      handleGetPostData();
    } catch (error) {
      console.error("Error searching communities:", error);
    }
  };

  const fetchCommunities = async(communityHandle) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/community/handle/${communityHandle}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.community) {
          // console.log("response__",response.data)
          setCommunity(response?.data?.community)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const sharePost = async () => {};
  const saveAsDraft = async (id) => {
    try {
      const updatedPost = { status: 0 };
      // Send request to update post data
      const response = await axios.put(
        `${apiurl}/post/update-post/${id}`,
        updatedPost,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      handleDraftPost();
      handleGetPostData();
    } catch (error) {
      console.error("Error editing post:", error);
    }
  };

  const saveAsPost = async (id) => {
    setLoading(true);
    try {
      const updatedPost = { status: 1 };
      const response = await axios.put(
        `${apiurl}/post/update-post/${id}`,
        updatedPost,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setLoading(false);
        handleDraftPost();
        handleGetPostData();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error editing post", error);
    }
  };

  const handleDraftPost = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiurl}/post/get-draft-posts/${userData._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setLoading(false);
        let allDraftPost = response.data?.draftPosts || [];
        if (activeTab === "mypost") {
          const filteredDraftPosts = allDraftPost.filter(
            (item) => !item.buyNowUrl
          );
          setDraftPost(filteredDraftPosts);
        } else if (activeTab === "product_post") {
          const filteredDraftProduct = allDraftPost.filter(
            (item) => item.buyNowUrl && item.buyNowUrl.length > 0
          );
          setDraftPost(filteredDraftProduct);
        }
      } else {
        setDraftPost([]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Draft post error", error);
    }
  };

  const handleDeleteComment = async (post, PostCommentId) => {
    setLoading(true);
    try {
      const postId = post._id;
      const commentId = PostCommentId;

      const response = await axios.delete(
        `${apiurl}/post/delete-comment/${postId}/${commentId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          data: {
            userId: userData._id,
          },
        }
      );
      if (response.data.success === true) {
        handleGetPostData();
        setCommentId("");
        setLoading(false);
        toast.success(response.data.message);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("delete comment error", error);
    }
  };

  const handleEditComment = async () => {
    if(editPostComment === "" && editedCommentImg === ""){
      toast.error("Please enter your comment.");
      return;
    }
    setLoading(true);
    try {
      const postId = postCommentData._id;
      const commentId = postCommentData.comment._id;

      const response = await axios.put(
        `${apiurl}/post/edit-comment/${postId}/${commentId}`,
        {
          commentAuthorId: userData._id,
          text: editPostComment,
          images:[
              {
                  url: editedCommentImg,
                  description: editPostComment,
                  _id: userData?._id,
              }
          ]
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setEditPostCommentpop(false);
        setCommentId("");
        handleGetPostData();
        setLoading(false);
        toast.success(response.data.message);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("edit comment error", error);
    }
  };

  const handleEditCommentReply = async () => {
    try {
      // console.log("postCommentData all data", postCommentData);
      const { postId, commentId } = postCommentData;
      if(editPostCommentReply === "" && editedReplyImg === ""){
        toast.error("Please enter your reply");
        return;
      }

      const response = await axios.put(
        `${apiurl}/post/edit-comment-reply/${postCommentData.postId}/${postCommentData.commentId}/${replyId}`,
        {
          replyAuthorId: userData._id,
          text: editPostCommentReply,
          images:[
              {
                  url: editedReplyImg,
                  description: editPostCommentReply,
                  _id: userData?._id,
              }
          ]
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('edit reply response ----------------->', response)
      if (response.data.success === true) {
        setEditPostCommentReplypop(false);
        setReplyId("");
        handleGetPostData();
        setLoading(false);
        toast.success(response.data.message);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("edit reply error", error);
    }
  };

  const handleDeleteCommentReply = async (PostId, PostCommentId) => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${apiurl}/post/delete-comment-reply/${PostId}/${PostCommentId}/${replyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          data: {
            userId: userData._id,
          },
        }
      );
      // console.log('delete reply response ----------------->', response)
      if (response.data.success === true) {
        handleGetPostData();
        setReplyId("");
        setLoading(false);
        toast.success(response.data.message);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("delete reply error", error);
    }
  };

  useEffect(() => {
    const filteredChats = chatList
      .filter((user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .slice()
      .reverse();
    setFilteredChatList(filteredChats);
  }, [searchTerm, chatList]);

  useEffect(() => {
    const filteredGroups = groupList
      .filter((user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .slice()
      .reverse();
    setFilteredGroupList(filteredGroups);
  }, [searchTerm, groupList]);

  const handleChatChange = (e, userId) => {
    const checked = e.target.checked;
    if (checked) {
      setIsChatId((Ids) => [...Ids, userId]);
    } else {
      setIsChatId((Ids) => Ids.filter((id) => id !== userId));
    }
  };

  const handleGroupChange = (e, userId) => {
    const checked = e.target.checked;
    if (checked) {
      setIsGroupId((Ids) => [...Ids, userId]);
    } else {
      setIsGroupId((Ids) => Ids.filter((id) => id !== userId));
    }
  };

  const fetchChatsList = async () => {
    let senderId = userData?._id;
    const config = {
      url: `${apiurl}/user/all`,
      method: "GET",
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    try {
      const response = await axios.request(config);
      const data = await response.data;
      setchatList(data.users);
      // console.log('fetch chat list ----------->', data)
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };

  const fetchGroupList = () => {
    axios
      .get(`${apiurl}/message/getgroupsbyuserid?userId=${userData?._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        setGroupList(res.data.groups);
        // console.log('fetch group list ----->', res)
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleShare = (PostId) => {
    setSharePostPop(true);
    fetchChatsList();
    fetchGroupList();
    setSharePostId(PostId);
  };

  const handleSharePost = async () => {
    setLoading(true);
    try {
      const data = {
        postId: sharePostId,
        senderId: userData._id,
        receiverIds: isChatId,
        groupIds: isGroupId,
      };
      const response = await axios.post(
        `${apiurl}/post/post-share-in-chatbox`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setLoading(false);
        setSharePostPop(false);
        toast.success(response.data.message);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("share post error", error);
    }
  };
  const sendnotification = async (title,vendormail,customername,customeremail,customerphone) => {

    try {
        const data = {
            name: customername,
            content: title,
            customer_email: customeremail,
            customer_phone: customerphone,
            seller_email: vendormail
        }

        const response = await axios.post(
            `${marketplaceapiurl}/notification/createNotification`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token
                }
            }
        );
    // console.log(response)
    } catch (error) {
        console.error('Error editing post:', error);
    }
}

  const toggleOptions = (postId) => {
    setOpenDropdown(openDropdown === postId ? null : postId);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(null); 
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <div className={`bg-[#E5E7EB] px-2 pb-4 ${((activePost === "active_post" && profilePosts?.length < 1) || (activePost === "draft_post" && draftPost?.length < 1)) ? 'h-[300px] sm:h-auto' : ''}`}>
        <div className="flex justify-center sm:justify-end items-center m-auto sm:w-[60%] w-full mb-4">
          <button
            className={`bg-green-200 border font-semibold border-green-600 px-2 py-1 rounded-md hover:bg-green-600 transition-all duration-300 ${ activePost === "active_post" ? "bg-green-600 text-white" : "" }`}
            onClick={() => setActivePost("active_post")}
          >
            Active Post
          </button>
          <button
            className={`bg-red-200 border font-semibold border-red-600 px-2 py-1 ml-3 rounded-md hover:bg-red-500 transition-all duration-300 ${ activePost === "draft_post" ? "bg-red-500 text-white" : "" }`}
            onClick={() => setActivePost("draft_post")}
          >
            Draft Post
          </button>
        </div>

        {profilePosts ? (
          <>
            {(activePost === "active_post" ? profilePosts : draftPost)?.map(
              (item, index) => (
                <div
                  className="bg-white rounded-xl mb-5 sm:p-4 p-3 sm:px-10 border m-auto sm:w-3/5 w-full"
                  key={index}
                >
                  <div className={`flex items-center flex-row my-4 ${activePost === 'active_post' ? 'items-center' : 'items-center'}`}>
                    <Link
                      to={`/profile/${userData._id}`}
                      className="w-full flex"
                    >
                      <img
                        src={userData?.hero_img}
                        alt="savage-profile"
                        className="w-12 sm:w-14 h-12 sm:h-14  rounded-full bg-[#becbe0] p-1 cursor-pointer"
                      />
                      <div className="lg:w-full relative pl-2">
                        <h2 className="sm:text-lg font-bold flex items-center  cursor-pointer capitalize">
                          {item?.createdby?.username}
                          <FaCheck className="p-1 m-1 bg-[#0866FF] text-white rounded-[100%] w-3 h-3 lg:w-[15px] lg:h-[15px]" />
                        </h2>
                        <p className="font-[500] text-[14px] text-[#9e9e9e]">
                          {GetTimeAgo(item.createdAt)}
                        </p>
                      </div>
                    </Link>
                    {item.status === 1 ? (
                      <div className="relative">
                        <button
                          className="dropdown-toggle 2 text-grey font-bold px-4 rounded"
                          onClick={() => {toggleOptions(item._id);fetchCommunities(item?.community?.community_handle);}}
                        >
                          <BsThreeDotsVertical />
                        </button>
                        {openDropdown === item._id && (
                          <div ref={dropdownRef} className="dropdown-menu absolute  bg-white border rounded-md shadow-lg z-10 right-0 w-[150px]">
                            <button
                              onClick={() => {
                                handleEditModalOpen(item);
                                setEditingPost(!editingPost);
                                setOption(!option);
                              }}
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => deletePost(item)}
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              Delete
                            </button>
                            {/* <button onClick={() => sharePost(item._id)} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Share</button>*/}
                            <button
                              onClick={() => saveAsDraft(item._id)}
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              Save as Draft
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      <button
                        className="text-red-600 text-[11px] sm:text-sm font-semibold w-32 border border-red-400 px-1 py-1 rounded-md hover:text-green-700 hover:border-green-500 transition-all duration-300 "
                        onClick={() => saveAsPost(item._id)}
                      >
                        Set Active Post
                      </button>
                    )}
                  </div>

                  <h2 className="text-xl my-5  font-bold  flex items-start  cursor-pointer">
                    {item?.title}
                  </h2>
                  <p className="text-base leading-8 break-words">{item?.content}</p>
                  <div className="rounded-xl mb-5">
                    {item.images[0]?.url && (
                      <div className="rounded-xl ">
                        {isVideoExtension(item.images[0]?.url) === "video" ? (
                          <video
                            className="w-auto h-auto  rounded-lg relative m-auto"
                            controls
                            autoPlay
                            loop
                            muted
                          >
                            <source
                              src={item.images[0]?.url}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            src={item.images[0]?.url}
                            alt="post"
                            className="w-auto h-auto rounded-lg relative m-auto "
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {item?.buyNowUrl && (
                    <div className="flex items-center justify-center my-4"
                     onClick={()=>sendnotification(item?.title, userData?.email,userData?.userHandle,userData?.email,userData?.phone)}>
                      <Link
                        to={item?.buyNowUrl}
                        target="_blank"
                        className="bg-black text-white px-6 p-2 rounded font-medium text-lg hover:bg-red-700 transition-all duration-500"
                      >
                        {" "}
                        Buy Now{" "}
                      </Link>
                    </div>
                  )}
                  <hr />
                  <div className="flex justify-between items-center my-2">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={(event) => handleLike(event, item)}
                    >
                      {item.likes &&
                      item.likes.likedBy.some(
                        (user) => user._id === myUserID
                      ) ? (
                        <>
                          <FaThumbsUp className="text-[#1F51FF]" />
                          <span className="pl-2 text-[#1F51FF]">
                            Like ({item.likes.likedBy.length})
                          </span>
                        </>
                      ) : (
                        <>
                          <FaThumbsUp className="text-[#9e9e9e]" />
                          <span className="pl-2">
                            Like ({item.likes.likedBy.length})
                          </span>
                        </>
                      )}
                    </div>

                    <div className="flex items-center cursor-pointer">
                      <FaComment className="text-[#9e9e9e]" />
                      <span className="pl-2">
                        Comments ({item?.comments?.length})
                      </span>
                    </div>
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => handleShare(item._id)}
                    >
                      <FaShare className="text-[#9e9e9e]" />
                      <span className="pl-2">
                        Share 
                      </span>
                    </div>
                  </div>

                  {/* ---------------------- */}

                  <div className="flex flex-col justify-center items-start">
                    {item.comments &&
                      item.comments
                        .slice()
                        .reverse()
                        .map((comment, commentIndex) => {
                          return (
                            <div
                              className="rounded-lg border relative p-2 w-full bg-[#fafafa] mb-3"
                              key={commentIndex}
                            >
                              <div className="px-3 rounded-md">
                                <div className="flex items-center mb-4">
                                  <Link
                                    to={`/profile/${comment?.author?._id}`}
                                    className="flex"
                                  >
                                    <p className="w-10 h-10 flex items-center justify-center rounded-full mr-2 border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                                      {comment?.author?.username
                                        ?.charAt(0)
                                        .toUpperCase()}
                                    </p>
                                    <div>
                                      <h3 className="text-md font-semibold">
                                        {comment?.author?.username}
                                      </h3>
                                      <p className="text-gray-500">
                                        {GetTimeAgo(comment?.createdAt)}
                                      </p>
                                    </div>
                                  </Link>

                                  {(myUserID === comment?.author?._id ||
                                    item.author === userData._id) && (
                                    <div className="flex justify-end absolute right-4 text-lg cursor-pointer">
                                      <button
                                        onClick={() => {
                                          setCommentId(
                                            comment?._id === commentId
                                              ? null
                                              : comment._id
                                          );
                                        }}
                                      >
                                        <BsThreeDotsVertical />
                                      </button>
                                      {comment._id === commentId && (
                                        <div className="dropdown-menu absolute  bg-white border rounded-md shadow-lg z-10 right-4 w-[150px]">
                                          {myUserID ===
                                            comment?.author?._id && (
                                            <button
                                              className="block w-full text-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                              onClick={() => {
                                                setPostCommentData({
                                                  ...item,
                                                  comment,
                                                });
                                                setEditPostCommentpop(true);
                                                setEditPostComment( comment.text );
                                                setEditedCommentImg(comment?.images[0]?.url);
                                              }}
                                            >
                                              Edit
                                            </button>
                                          )}
                                          {item.author === userData._id && (
                                            <button
                                              className="block w-full text-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                              onClick={() => {
                                                handleDeleteComment(
                                                  item,
                                                  comment._id
                                                );
                                              }}
                                            >
                                              Delete
                                            </button>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <p>{comment?.text}</p>
                                {comment?.images[0]?.url && (
                                    <div className="flex items-center justify-start">
                                        <img src={comment?.images[0]?.url} alt="commented-image" className='w-auto h-24' />
                                    </div>
                                )}
                              </div>
                              <button
                                onClick={() => commentReplyHandle(comment?._id)}
                                className="text-[#005AE6] text-sm ml-3 hover:underline cursor-pointer"
                              >
                                Reply
                              </button>
                              {comment?.replies
                                .slice()
                                .reverse()
                                .map((reply, replyIndex) => (
                                  <div
                                    className="hover:bg-gray-300 bg-gray-200  p-3 mt-1 relative ml-8 w-auto rounded-lg transition-all duration-300 mb-2"
                                    key={replyIndex}
                                  >
                                    <div className="flex justify-between">
                                      <Link
                                        to={`/profile/${reply?.author?._id}`}
                                      >
                                        <div className="flex items-center mb-4">
                                          <p className="w-10 h-10 flex items-center justify-center rounded-full mr-2 border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                                            {reply?.author?.username
                                              ?.charAt(0)
                                              .toUpperCase()}
                                          </p>
                                          <div>
                                            <h3 className="text-md font-semibold">
                                              {reply?.author?.username}
                                            </h3>
                                            <p className="text-gray-500">
                                              {GetTimeAgo(reply?.createdAt)}
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                      {(myUserID === reply?.author?._id ||
                                        item.author === myUserID) && (
                                        <>
                                          <button
                                            onClick={() => {
                                              setReplyId(
                                                reply?._id === replyId
                                                  ? null
                                                  : reply._id
                                              );
                                            }}
                                            className="relative"
                                          >
                                            <BsThreeDotsVertical />
                                          </button>
                                          {reply._id === replyId && (
                                            <div className="dropdown-menu absolute  bg-white border rounded-md shadow-lg z-10 right-6 top-9 w-[150px]">
                                              {myUserID ===
                                                reply?.author?._id && (
                                                <button
                                                  className="block w-full text-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                  onClick={() => {
                                                    setPostCommentData({
                                                      postId: item._id,
                                                      commentId: comment._id,
                                                    });
                                                    setEditPostCommentReplypop(
                                                      true
                                                    );
                                                    setEditPostCommentReply(
                                                      reply?.text
                                                    );
                                                  }}
                                                >
                                                  Edit
                                                </button>
                                              )}
                                              <button
                                                className="block w-full text-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                onClick={() => {
                                                  handleDeleteCommentReply(
                                                    item._id,
                                                    comment._id
                                                  );
                                                }}
                                              >
                                                Delete
                                              </button>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <p>{reply?.text}</p>
                                    {reply?.images[0]?.url && (
                                        <div className="flex items-center justify-start">
                                            <img src={reply?.images[0]?.url} alt="replied-image" className='w-auto h-24' />
                                        </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          );
                        })}
                  </div>
                  {/* ----------- */}
                  <div className="mt-1 bg-white sm:p-4 p-2 rounded-md shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                    <div className="flex items-center flex-grow border border-gray-300 rounded-md px-2 py-2 focus:outline-none focus:border-blue-500">
                      <img
                        src={userData?.hero_img}
                        alt="User Profile"
                        className="w-10 h-10 rounded-full mr-2 border p-1"
                      />
                      {tags === true ? (
                        <div className="bg-gray-200 rounded-md px-2 py-1 flex items-center mr-1">
                          <span className="mr-1 text-blue-600">Reply</span>
                          <button
                            onClick={() => setTags(false)}
                            className="text-red-500 cursor-pointer"
                          >
                            X
                          </button>
                        </div>
                      ) : null}
                      <input
                        type="text"
                        value={getInputValue(replyPost, index)}
                        placeholder={
                          tags
                            ? "Add a comment reply . . ."
                            : "Add a comment . . ."
                        }
                        onChange={(e) => {
                          let x = [...replyPost];
                          let isItemPreset = x.some(
                            (item) => item.id === index
                          );
                          if (isItemPreset) {
                            let indexOfItem = x.findIndex(
                              (item) => item.id === index
                            );
                            x[indexOfItem].value = e.target.value;
                          } else {
                            x.push({
                              id: index,
                              value: e.target.value,
                            });
                          }
                          setReplyPost([...x]);
                        }}
                        className="flex-grow border border-gray-300 rounded-md p-2 h-10 focus:outline-none focus:border-blue-500 sm:text-sm text-xs sm:auto w-28"
                      />
                      <label
                          htmlFor="commmentImgUrl"
                          className={`${commentImg && commentImg.length > 0
                          ? "bg-green-500"
                          : "bg-blue-500"
                          } text-white cursor-pointer rounded mb-0 flex items-center px-1 sm:px-2 ml-2`}
                      >
                          {isImageLoading ? (
                              <svg
                              width="36px"
                              height="36px"
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              stroke="#ffffff"
                              viewBox="0 0 100 100"
                              preserveAspectRatio="xMidYMid"
                              >
                              <circle cx="75" cy="50" fill="#ffffff" r="6.39718"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.875s" ></animate> </circle> <circle cx="67.678" cy="67.678" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s" ></animate> </circle> <circle cx="50" cy="75" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.625s" ></animate> </circle> <circle cx="32.322" cy="67.678" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s" ></animate> </circle> <circle cx="25" cy="50" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.375s" ></animate> </circle> <circle cx="32.322" cy="32.322" fill="#ffffff" r="4.80282" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s" ></animate> </circle> <circle cx="50" cy="25" fill="#ffffff" r="6.40282"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.125s" ></animate> </circle> <circle cx="67.678" cy="32.322" fill="#ffffff" r="7.99718" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s" ></animate> </circle>
                              </svg>
                          ) : (
                              <MdAddPhotoAlternate className="text-4xl" />
                          )}
                          <input
                          id="commmentImgUrl"
                          type="file"
                          className="hidden"
                          name="post_img"
                          onChange={(e) => getImage(e)}
                          />
                      </label>
                      <button
                        className="ml-2 bg-blue-500 text-white sm:px-4 py-2 rounded-md px-2"
                        onClick={() => handleReplyPost(item._id)}
                        disabled={isImageLoading}
                      >
                        Post
                      </button>
                    </div>
                    {commentImg && (
                        <>
                            <div className="flex items-start justify-center">
                                <img src={commentImg} alt="comment-image" className='w-auto h-28 relative z-10' />
                                <span
                                onClick={() => setCommentImg("")}
                                className="relative -top-[2px] right-5 text-xs z-20 text-red-500 font-bold border rounded-full px-2 py-1 cursor-pointer bg-white"
                                >
                                X
                                </span>
                            </div>
                        </>
                    )}
                  </div>
                  {editingPost && (
                    <div className="fixed inset-0 overflow-y-auto z-[33] bg-[#000000e3]">
                      <div className="flex items-center justify-center min-h-screen">
                        <div className="relative bg-white rounded-lg max-w-md w-full p-6 m-3">
                          <span
                            className="absolute top-0 right-2 cursor-pointer text-2xl"
                            onClick={handleEditModalClose}
                          >
                            &times;
                          </span>
                          <h2 className="text-xl font-bold mb-4">Edit Post</h2>
                          <input
                            className="border border-gray-300 mb-4 p-2 rounded-md w-full"
                            type="text"
                            value={editedTitle}
                            onChange={(e) => setEditedTitle(e.target.value)}
                          />
                          <textarea
                            className="border border-gray-300 mb-4 p-2 rounded-md w-full"
                            value={editedContent}
                            onChange={(e) => setEditedContent(e.target.value)}
                          />
                          <input
                            className="border border-gray-300 mb-4 p-2 rounded-md w-full"
                            type="file"
                            onChange={(e) => ImageHandler(e)}
                          />
                          <span className="text-green-500">
                            {imageLoading === true ? "Uploading . . ." : ""}
                          </span>
                          {editedImage && (
                            <>
                              {isVideoExtension(editedImage) === "video" ? (
                                <video
                                  className="w-full h-40 rounded-lg"
                                  controls
                                >
                                  <source src={editedImage} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <img
                                  src={editedImage}
                                  className="w-full h-40 rounded-lg"
                                  alt="Edited Image"
                                />
                              )}
                            </>
                          )}
                          <button
                            className={`block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-5 mx-auto rounded ${
                              imageLoading
                                ? "opacity-50 !cursor-not-allowed"
                                : ""
                            }`}
                            onClick={() => !imageLoading && editPost(postid)}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {editPostCommentpop && (
                    <div className="fixed inset-0 overflow-y-auto z-[33] bg-[#000000e3]">
                      <div className="flex items-center justify-center min-h-screen">
                        <div className="relative bg-white rounded-lg max-w-md w-full p-6 m-3">
                          <span
                            className="absolute top-0 right-2 cursor-pointer text-2xl"
                            onClick={() => {setEditPostCommentpop(false); setEditedCommentImg("");setIsImageLoading(false)}}
                          >
                            &times;
                          </span>
                          <h2 className="text-xl font-bold mb-4">
                            Edit Post Comment
                          </h2>
                          <textarea
                            className="border border-gray-300 mb-4 p-2 rounded-md w-full"
                            value={editPostComment}
                            onChange={(e) => setEditPostComment(e.target.value)}
                          />
                          <input className="border border-gray-300 mb-4 p-2 rounded-md w-full" type="file" onChange={(e) => getEditedCommentImg(e)} />
                          <span className="text-green-500">{isImageLoading === true ? "Uploading . . ." : ""}</span>
                          
                          {editedCommentImg && (
                              <>
                                {isVideoExtension(editedCommentImg) === "video" ? (
                                <video className="w-full h-40 rounded-lg" controls>
                                    <source src={editedCommentImg} type='video/mp4' />
                                    Your browser does not support the video tag.
                                </video>
                                ) : (
                                  <div className="flex items-center justify-center relative">
                                      <img src={editedCommentImg} className="w-full h-40 rounded-lg" alt="EditedImage" />
                                      <span
                                      onClick={() => setEditedCommentImg("")}
                                      className="absolute -top-[5px] right-0 text-xs z-20 text-red-500 font-bold border border-[#333] rounded-full px-2 py-1 cursor-pointer bg-white"
                                      >
                                      X
                                      </span>
                                  </div>
                                )}
                              </>
                          )}
                          <button
                            className="block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-5 mx-auto rounded"
                            onClick={handleEditComment}
                            disabled={isImageLoading}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {editPostCommentReplypop && (
                    <div className="fixed inset-0 overflow-y-auto z-[33] bg-[#000000e3]">
                      <div className="flex items-center justify-center min-h-screen">
                        <div className="relative bg-white rounded-lg max-w-md w-full p-6 m-3">
                          <span
                            className="absolute top-0 right-2 cursor-pointer text-2xl"
                            onClick={() => {setEditPostCommentReplypop(false);setEditedReplyImg("");setIsImageLoading(false);}}
                          >
                            &times;
                          </span>
                          <h2 className="text-xl font-bold mb-4">Edit Reply</h2>
                          <textarea
                            className="border border-gray-300 mb-4 p-2 rounded-md w-full"
                            value={editPostCommentReply}
                            onChange={(e) =>
                              setEditPostCommentReply(e.target.value)
                            }
                          />
                          <input className="border border-gray-300 mb-4 p-2 rounded-md w-full" type="file" onChange={(e) => getEditedReplyImg(e)} />
                          <span className="text-green-500">{isImageLoading === true ? "Uploading . . ." : ""}</span>
                          {editedReplyImg && (
                            <>
                                {isVideoExtension(editedReplyImg) === "video" ? (
                                  <video className="w-full h-40 rounded-lg" controls>
                                      <source src={editedReplyImg} type='video/mp4' />
                                      Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <div className="flex items-center justify-center relative">
                                      <img src={editedReplyImg} className="w-full h-40 rounded-lg" alt="EditedImage" />
                                      <span
                                      onClick={() => setEditedReplyImg("")}
                                      className="absolute -top-[5px] right-0 text-xs z-20 text-red-500 font-bold border border-[#333] rounded-full px-2 py-1 cursor-pointer bg-white"
                                      >
                                      X
                                      </span>
                                  </div>
                                )}
                            </>
                          )}
                          <button
                            className="block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-5 mx-auto rounded"
                            onClick={handleEditCommentReply}
                            disabled={isImageLoading}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
          </>
        ) : (
          ""
        )}

        {activePost === "active_post" && profilePosts?.length < 1 ? (
          <div className="bg-white p-4 sm:p-8 rounded-lg shadow-md mb-4">
            <p className="sm:text-2xl text-lg font-semibold text-center">
              {selectedTab === 'mypost' ? 'No post is available' : 'Currently, no product posts available.'}
            </p>
          </div>
        ) : (
          "" 
        )}

        {activePost === "draft_post" && draftPost?.length < 1 ? (
          <div className="bg-white px-4 py-8 sm:p-8 rounded-lg shadow-md mb-4">
            <p className="sm:text-2xl text-lg font-semibold text-center">
              {selectedTab === 'mypost' ? 'No draft post is available.' : 'Currently, no draft posts available.'}
            </p>
          </div>
        ) : (
          ""
        )}

        {sharePostPop === true ? (
          <>
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-30">
              <div className="bg-white sm:p-6 p-2 rounded-xl shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] text-center flex justify-start items-center flex-col sm:w-2/5 w-11/12">
                <div className="flex justify-between items-center w-full">
                  <p className="text-lg">All the Members and Groups</p>
                  <button className="" onClick={() => setSharePostPop(false)}>
                    X
                  </button>
                </div>
                <div className="w-full p-2">
                  <div className="my-1 p-1 flex bg-[#fff] border border-gray-400 w-full rounded-lg">
                    <input
                      type="text"
                      className="px-2 py-2 w-full focus:outline-none"
                      placeholder="Search Users"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button className="px-4 focus:outline-none">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="text-[#000]"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                      </svg>
                    </button>
                  </div>

                  <div className="sm:mt-4 mt-2 w-full bg-gray-100 border border-gray-300 rounded-lg shadow-md overflow-y-scroll h-44">
                    {filteredChatList.map((user, index) => (
                      <label
                        className="flex justify-left w-full px-5 items-center py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer"
                        key={index}
                      >
                        <div className="flex items-center h-5 mr-2">
                          <input
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            type="checkbox"
                            value={user?._id}
                            onChange={(e) => handleChatChange(e, user?._id)}
                          />
                        </div>
                        <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1 bg-white">
                          <img
                            src={user.hero_img}
                            className="rounded-full w-12 h-12 border-[1px] border-gray-500 bg-gray-300"
                            alt=""
                          />
                          <span className="absolute w-2.5 h-2.5 bg-green-500 border-2 border-white rounded-full top-12 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                        </div>
                        <div className="flex justify-between w-3/4">
                          <div className="text-left">
                            <h5 className="mb-1 font-semibold text-black ">
                              {user?.name}
                            </h5>
                            <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px] block w-40 overflow-hidden whitespace-nowrap overflow-ellipsis">
                              {user?.lastMessage?.content}
                            </p>
                          </div>
                          <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                            {GetTimeAgo(user?.lastMessage?.createdAt)}
                          </div>
                        </div>
                      </label>
                    ))}
                    {filteredGroupList.map((user, index) => (
                      <label
                        className="flex justify-left px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer items-center"
                        onClick={() => user?._id}
                        key={index}
                      >
                        <div className="flex items-center h-5 mr-2">
                          <input
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            type="checkbox"
                            value={user?._id}
                            onChange={(e) => handleGroupChange(e, user?._id)}
                          />
                        </div>
                        <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                          <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                            {user?.name?.charAt(0).toUpperCase()}
                          </p>
                          <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-12 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                        </div>
                        <div className="flex justify-between w-3/4">
                          <div className="text-left">
                            <h5 className="mb-1 font-semibold text-black ">
                              {user?.name}
                            </h5>
                            <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                              {user?.members && Array.isArray(user.members)
                                ? `${user.members.length} members`
                                : ""}
                            </p>
                          </div>
                          <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                            {GetTimeAgo(user?.createdAt)}
                          </div>
                        </div>
                      </label>
                    ))}
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={handleSharePost}
                    className="border border-1 bg-blue-700 text-white px-5 py-2 rounded-lg"
                  >
                    Share
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default ProfilePost;

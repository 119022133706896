import React, { Fragment, useContext, useEffect, useRef } from "react";
import { GroupCallContext } from "../contexts/groupCallContext";
import { useUser } from "../contexts/UserProvider";
import incoming_call from "../components/incoming_call.mp3";
import { AiOutlineClose } from "react-icons/ai";
import { VscCallIncoming } from "react-icons/vsc";
import { SlCallEnd } from "react-icons/sl";
import { IoCallOutline } from "react-icons/io5";

export default function IncomingGroupCall() {
  const { userData } = useUser();
  const { AnswerGroupCall, joinGroupCall, isIncomingGroupCall, leaveGroupCall, groupCall } = useContext(GroupCallContext);
  const audioRef = useRef(null);

  useEffect(() => {
    if (isIncomingGroupCall) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isIncomingGroupCall]);

  const handleDecline = () => {
    leaveGroupCall();
    audioRef.current.pause();
  };

  const handleClose = () => {
    audioRef.current.pause();
  };

  return (
    <Fragment>
      {isIncomingGroupCall ? (
        <div className="absolute inset-0 z-30 w-full bg-black bg-opacity-30 flex justify-center items-center">
          <div className="bg-white h-48 w-[30%] rounded-md px-5 py-2">
            <div className="flex justify-between items-center">
              <audio ref={audioRef} loop src={incoming_call} />
              <h1 className="font-bold text-2xl">Incoming Group Call</h1>
              <AiOutlineClose
                fontSize={"25px"}
                className="text-gray-900 cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <div className="user_info py-5 flex items-center space-x-3">
              <p className="text-xl">{groupCall?.name || "Someone"} is calling you...</p>
              <VscCallIncoming className="text-3xl" />
            </div>
            <div className="answer_decline flex justify-between items-center pt-5">
              <button
                onClick={handleDecline}
                type="button"
                className="px-6 py-1.5 rounded-md outline-none bg-red-800 text-white flex items-center space-x-2"
              >
                <SlCallEnd className="text-xl" />
                <span>Decline</span>
              </button>
              <button
                onClick={() => {
                  AnswerGroupCall();
                  audioRef.current.pause();
                }}
                type="button"
                className="px-6 py-1.5 rounded-md outline-none bg-green-800 text-white flex items-center space-x-2"
              >
                <IoCallOutline className="text-xl" />
                <span>Answer</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <audio ref={audioRef} loop src={incoming_call} />
      )}
    </Fragment>
  );
}

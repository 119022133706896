// src/components/ProfileLeftSidebar.js

import React from "react";

const ProfileLeftSidebar = () => {
  return (
    <div className="w-full md:w-1/4 p-4 md:h-screen border-solid border-r border-light-gray-200 sm:order-first">
      {/* Sidebar content goes here */}
      <h2 className="text-lg font-semibold mb-4 border-solid border-b border-light-gray-20 pb-2">
        Overview
      </h2>
      <ul>
        <li className="mb-2">
          <a href="#my-profile">My Profile</a>
        </li>
        <li className="mb-2">
          <a href="#address">Address</a>
        </li>
        <li className="mb-2">
          <a href="#orders">Orders</a>
        </li>
        <li className="mb-2">
          <a href="#wishlists">Wishlists</a>
        </li>
      </ul>
    </div>
  );
};

export default ProfileLeftSidebar;

import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoutePage from "./routes";
import { Fragment } from "react";
import UserProvider from "./contexts/UserProvider";
import CallContextProvider from "./contexts/CallContext";
import AudioCallContext from "./contexts/audioCallContext";
import GroupCallContextProvider from "./contexts/groupCallContext";
import IncomingCall from "./components/incomingCall";
import IncomingAudioCall from "./components/incomingAudioCall";
import IncomingGroupCall from "./components/incomingGroupCall";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import {
  VideoCom,
  AudioCallComponent,
  GroupCallComponent,
} from "./components/VideoCom";
import NewGroupCall from "./contexts/NewGroupCall.js";
import IncomingCallNotify from "./components/IncomingCallNotify.js";
import IncomingVideoCam from "./components/IncomingVideoCam.js";

const initialOptions = {
  // "client-id":
  //   "AcZ05Zr-hgHhDwIqDhIZJb-Nkm0hJDb7HBF_naeWs66sVKrdleL5-wFPeX9r0NgyXCANk6-8iMjItDib",
  "client-id": "AWBof5w1sienqntjpVs3wzqq9Ircd5rrUhLMUOYeOUyIMaqE-nSRnsLOeXsEK7VVbwJObEMicmtp7cPC",
  currency: "USD",
  intent: "capture",
  "enable-funding": "venmo",
  debug: false,
};

function App() {
  return (
    <Fragment>
      <PayPalScriptProvider options={initialOptions}>
        <UserProvider>
          <CallContextProvider>
            <NewGroupCall>
              <GroupCallContextProvider>
                <AudioCallContext>
                  <RoutePage />
                  <ToastContainer />
                  <IncomingCall />
                  <IncomingAudioCall />
                  <IncomingGroupCall />
                  <VideoCom />
                  <AudioCallComponent />
                  <GroupCallComponent />
                  <IncomingCallNotify />
                  <IncomingVideoCam />
                </AudioCallContext>
              </GroupCallContextProvider>
            </NewGroupCall>
          </CallContextProvider>
        </UserProvider>
      </PayPalScriptProvider>
    </Fragment>
  );
}

export default App;

import React, { useState } from "react";
import { MdOutlineModeComment, MdOutlineWatchLater } from "react-icons/md";
import { Link } from "react-router-dom";

const DiscussionCard = () => {
  const data = [
    {
      title: "Savage Seller Community",
      slug: "savage-seller-community",
      community: "ens",
      date: "23 Jan 2024",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there.",
      imageUrl: "/assets/community.jpg",
      imageDescription: "post image",
      comment: [
        {
          title: "Gorgeous Looking",
          name: "Reao",
          userimg: "/assets/community.jpg",
        },
      ],
    },
    {
      title: "Savage Seller Community",
      slug: "savage-seller-community2",
      community: "ens",
      date: "23 Jan 2024",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there.",
      imageUrl: "/assets/community.jpg",
      imageDescription: "post image",
      comment: [
        {
          title: "Great and really nice",
          name: "Reao",
          userimg: "/assets/community.jpg",
        },
      ],
    },
    {
      title: "Savage Seller Community",
      slug: "savage-seller-community3",
      community: "ens",
      date: "23 Jan 2024",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there.",
      imageUrl: "/assets/community.jpg",
      imageDescription: "post image",
      comment: [
        {
          title: "Awesome Look",
          name: "Reao",
          userimg: "/assets/community.jpg",
        },
        { title: "Great", name: "Reao", userimg: "/assets/community.jpg" },
      ],
    },
  ];
  const [discussion, setDiscussion] = useState(data);
  return (
    <div>
      <div className="relative w-[100%] h-[200px] lg:h-[200px] full  m-auto bg-cover bg-no-repeat bg-center  bg-[#302b27] bg-[url('/public/assets/discussion.jpg')]">
        <h1 className="flex  items-center text-[40px] justify-center h-[200px] lg:h-[200px] font-bold text-[#fff]">
          Discussions
        </h1>
      </div>
      <div className="grid grid-cols-3 gap-2 w-[70%] m-auto my-5">
        {discussion.map((item, index) => {
          return (
            <Link
              to={`/community/${item.community}/${item.slug}/discussion-overview`}
              key={index}
            >
              <div className="p-4 flex flex-col border border-[#9e9e9e] hover:shadow-inner hover:border hover:border-[#000]">
                <img
                  src="/assets/savageseller-logo.jpg"
                  alt="savage-logo"
                  className="w-10 h-10  rounded-[100%] border-blue border-[5px] cursor-pointer"
                />
                <div className="lg:w-full relative pl-2">
                  <h2 className="text-[16px] lg:text-[20px] font-bold  cursor-pointer color-blue">
                    {item.title}
                  </h2>
                  <p className="font-[400] text-[16px] text-[#9e9e9e] line-clamp-3 ">
                    {item.content}
                  </p>
                </div>
                <div className="lg:w-full relative pl-2 mt-5 justify-between items-center flex">
                  <MdOutlineModeComment className="text-[30px] text-[#9e9e9e]" />
                  <p className="flex items-center  text-[#9e9e9e]">
                    <MdOutlineWatchLater className="text-[20px] text-[#9e9e9e]" />{" "}
                    1 hour ago
                  </p>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default DiscussionCard;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiurl } from "../config/config";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getCookie } from "../config/coockies";
import Layout from "../components/layout";
import { IoLocationOutline, IoArrowBackSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import LoaderSpiner from "../components/loader";
import { FaComment, FaShare, FaCheck, FaThumbsUp } from "react-icons/fa";
import { GetTimeAgo } from "../components/getTime";
import { useUser } from "../contexts/UserProvider";
import PostAddIcon from '@mui/icons-material/PostAdd';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { FaLayerGroup } from "react-icons/fa";
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleIcon from '@mui/icons-material/People';

const UserProfile = () => {
  const token = getCookie("savage_token");
  const { userid } = useParams();
  const { userData } = useUser();
  const [profileData, setProfileData] = useState({});
  const [isFollow, setIsFollow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [followerTab, setFollowerTab] = useState("allPost");
  const [getAllFollower, SetGetAllFollower] = useState([]);
  const [getAllFollowing, SetGetAllFollowing] = useState([]);
  const [getGetAllMutual,SetGetAllMutual]=useState([])
  const [isBlockedByme, setIsBlockedByme]=useState(false)
  const [isBlockedme, setIsBlockedme]=useState(false)
  const [productPosts, setProductPosts] = useState([]);
  const [userCommunity, setUserCommunity] = useState([]);
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleUserProfile = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiurl}/user/profile/${userid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log("user profile data >>>>>", response);
      if(response?.data?.user?._id === userData?._id){
        navigate('/profile');
      }
      if (response.data.success === true) {
        setLoading(false);
        setProfileData(response.data);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("User Profile error", error);
    }
  };

  useEffect(() => {
    handleUserProfile();
    handleCheckFollowing();
  }, [userid]);

  const isVideoExtension = (url) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    const videoExtensionsChange = videoExtensions.toString();
    const extension = getExtension(url);
    const videoData = videoExtensionsChange.includes(extension)
      ? "video"
      : imageExtensions.includes(extension)
      ? "image"
      : "unknown";
    return videoData;
  };
  const getExtension = (url) => {
    if (!url) return "";
    const splitUrl = url.split(".");
    if (
      splitUrl.length === 1 ||
      (splitUrl[0] === "" && splitUrl.length === 2)
    ) {
      return "";
    }
    return splitUrl.pop().toLowerCase();
  };

  const createFollowRequestNotification = async (userType) => {
    const data = {
        userId: {
            _id: userData?._id,
        },
        type: "FOLLOW_REQUEST",
        message: userType === 'public' ? "started following you" : "has sent you a follow request" ,
        author: {
            _id: userid,
        }
    }
    try {
      const response = await axios.post(
        `${apiurl}/notifications/createNotification`, data
        ,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
    } catch (error) {
      setLoading(false);
      console.error("Join Community Notification error", error);
    }
  };

  const handleSendFollowRequest = async () => {
    // console.log(">>>>89",profileData)
    setIsFollow(true);
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/follow/send-request`,
        { senderId: userData._id, receiverId: userid, userType:profileData?.user?.userType },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('send follow request response >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        if(response.data.friendRequest)
        {
          setIsRequest(true);
          setIsFollow(false);
        }
        else{
          setIsRequest(false);
          setIsFollow(true);
        }
        
        handleUserProfile();
        createFollowRequestNotification(profileData?.user?.userType)
        toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("send follow request", error);
    }
  };

  const handleCheckFollowing = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiurl}/follow/check-following-user/${userid}/${userData._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('follower check response >>>>>>>', response)
      if (response.data.status === "accepted") {
        setLoading(false);
        setIsFollow(true);
        setIsRequest(false);
      } else if (response.data.status === "pending") {
        setIsRequest(true);
        setLoading(false);
      } else {
        setIsRequest(false);
        setIsFollow(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("all following", error);
    }
  };

  const deleteFollowRequestNotification = async () => {
    const data = {
        userId: {
            _id: userData?._id,
        },
        type: "FOLLOW_REQUEST",
        author: {
            _id: userid,
        }
    }
    try {
      const response = await axios.delete(
        `${apiurl}/notifications/deleteFollowNotification`
        ,
        {
          data: data,
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
    } catch (error) {
      setLoading(false);
      console.error("Join Community Notification error", error);
    }
  };
  
  const handleRemoveFollower = async () => {
    setIsFollow(false);
    try {
      setLoading(true);
      const data = {
        userId: userid,
        friendId: userData._id,
        isRequest:isRequest
      };

      const response = await axios.delete(`${apiurl}/follow/unfriend`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      });
      if (response.data.success === true) {
        setLoading(false);
        handleCheckFollowing();
        handleUserProfile();
        deleteFollowRequestNotification();
        toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("remove-request", error);
    }
  };

  const handleGetPostData = async () => {
    try {
      const response = await axios.get(
        `${apiurl}/post/get-cratedby-post/${userid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        let reversedPosts = response?.data?.Response.reverse();
        if (followerTab === "allPost") {
          const activepost = reversedPosts.filter((item) => !item.buyNowUrl);
          setPosts(activepost);
        } else if (followerTab === "productPosts") {
          const productPost = reversedPosts.filter(
            (item) => item.buyNowUrl?.length > 0 || item.buyNowUrl
          );
          setProductPosts(productPost);
        }
      } else {
        console.error("Failed to get the post");
        setLoading(false);
      }
    } catch (error) {
      console.error("post data", error);
      setLoading(false);
    }
  };

  const handleGetUserCommunity = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/community/get`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const userCommunities = response.data.filter((community) =>
          community.members.some(
            (member) => member._id === userid
          )
        );
        setUserCommunity(userCommunities);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const handleGetFollowers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiurl}/follow/followers/${userid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log('myfollowers response ------- >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        SetGetAllFollower(response?.data?.follower);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("all follower", error);
    }
  };
  const handleGetMutual = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiurl}/follow/get-mutual-friend/${userData._id}/${userid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log('myfollowers response ------- >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        SetGetAllMutual(response?.data?.mutualFriends);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("all follower", error);
    }
  };

  const handleGetFollowing = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiurl}/follow/following/${userid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log('myfollowing response >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        SetGetAllFollowing(response?.data?.following);
        // toast.success(response.data.message)
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("all following", error);
    }
  };
  const handleblockRequest = async (receiverId) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/follow/black-list`,
        { userId: userData._id, friendId: receiverId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('send follow request response >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        setIsBlockedByme(true)
        toast.success(response.data.message);
       
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("send follow request", error);
    }
  };
  const handleunblockRequest = async (receiverId) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/follow/un-black-list`,
        { userId: userData._id, friendId: receiverId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('send follow request response >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        setIsBlockedByme(false)
        toast.success(response.data.message);
       
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("send follow request", error);
    }
  };
  const handleBlockstatus = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/follow/getblockstatus`,
        { userId: userData._id, friendId: userid },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('send follow request response >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        setIsBlockedByme(response.data.isBlockedByMe)
        setIsBlockedme(response.data.isBlockedMe)
       
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("send follow request", error);
    }
  };

  useEffect(() => {
    handleGetFollowers();
    handleGetFollowing();
    handleGetMutual();
    handleBlockstatus();
  }, [userid]);
  
  useEffect(() => {
    handleGetPostData();
    handleGetUserCommunity();
  }, [userid,followerTab]);

  const maxAboutLength = Math.max(...userCommunity.map(item => item.about.length));
  const dynamicHeight = maxAboutLength > 78 ? "sm:h-[100px]" : maxAboutLength > 38 ? "sm:h-24" : "";

  return (
    <div>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <Layout>
        <div className="bg-gray-200 p-4 mb-20">
        <div className="bg-white w-full sm:w-full mx-auto pb-5 rounded-xl">
          <div className="relative">
            <img
              src={profileData?.user?.cover_img}
              alt="Profile-bg"
              className="w-full h-28 sm:h-[250px] lg:h-[400px] rounded-xl sm:mb-4"
            />
            <div className="absolute sm:top-40 lg:top-80 top-16 sm:left-8 left-4">
              <div className="border-2 border-black p-1 sm:p-2 rounded-full bg-white">
                <img
                  src={profileData?.user?.hero_img}
                  alt="Profile-pic"
                  className="sm:w-32 sm:h-32 w-12 h-12 rounded-full"
                  // onClick={() => {
                  //   setShowImage(true);
                  //   setShowImageType("hero_img");
                  // }}
                />
              </div>
              {/* <button onClick={handleHeroIconClick}>
                <input
                  className="w-4 sm:w-7 h-4 sm:h-7 mr-2 sm:mr-4 absolute text-black bottom-6 sm:bottom-7 -right-1 sm:right-0 cursor-pointer border border-black p-1 rounded-full bg-white opacity-0"
                  type="file"
                  ref={heroFileInputRef}
                  id="bgImage"
                  onChange={(e) => handleFileChange("hero_img", e)}
                />
                <CiEdit className="w-7 h-7 mr-2 sm:mr-4 absolute text-black bottom-5 sm:bottom-7 -right-2 sm:right-0 cursor-pointer border border-black p-1 rounded-full bg-white" />
              </button> */}
            </div>
          </div>
          <div className="flex items-start flex-col sm:flex-row sm:items-end justify-between w-full">
            <div className="flex w-full sm:w-1/3 sm:pl-5 sm:pr-0 mt-4 mx-5 sm:mx-0">
              <div className="flex flex-col items-start sm:items-center sm:justify-center w-1/2 sm:w-full">
                <div className="sm:pl-2 sm:ml-28 mb-2">
                  <h3 className="font-bold sm:text-center capitalize sm:text-2xl text-lg sm:mb-1 mb-0">
                    {profileData.user?.userHandle?profileData.user?.name:profileData.user?.userHandle}
                    
                  </h3>
                  <p className="sm:text-center text-xs sm:text-base text-gray-600">{profileData.user?.bio}</p>
                  {profileData.user?.location && profileData.user?.userType ==="public"? (
                    <div>
                      <h3 className="flex text-xs sm:text-base sm:text-center sm:items-center sm:justify-center">
                        <IoLocationOutline />
                        {profileData.user?.location}<br />
                        {profileData.user?.dob}
                      </h3>
                    </div>
                  ) : null}
                  {profileData.user?.userType ==="public"? (
                    <div>
                      <h3 className="flex text-xs sm:text-base sm:text-center sm:items-center sm:justify-center text-gray-600">
                        DOB: {profileData.user?.dob}
                      </h3>
                    </div>
                  ):""}
                </div>
                {isBlockedme?"":
                <div className="sm:px-0 flex sm:ml-24" >
                  {isFollow ? (
                    
                      <button
                        className=" w-10 sm:w-[150px] flex-1 rounded-full bg-blue-600 dark:bg-blue-800 text-white antialiased font-semibold hover:bg-blue-800 dark:hover:bg-blue-900 py-1 transition-all sm:text-lg text-xs duration-500"
                        onClick={handleRemoveFollower}
                      >
                        Unfollow
                      </button>
                      
                    
                  
                  ) 
                  : (
                    <button
                      className="w-10 sm:w-full flex-1 rounded-full bg-blue-600 dark:bg-blue-800 text-white antialiased font-semibold hover:bg-blue-800 dark:hover:bg-blue-900 px-4 py-2 transition-all sm:text-lg text-xs duration-500"
                      onClick={isRequest?handleRemoveFollower:handleSendFollowRequest}
                    >
                      {isRequest ? "Requested" : "Follow"}
                    </button>
                  )}
                  {isBlockedByme?
                    <button
                    className=" w-10 sm:w-[150px] ml-2 flex-1 rounded-full bg-red-600 text-white antialiased font-semibold hover:bg-red-800 dark:hover:bg-red-900 py-1 transition-all sm:text-lg text-xs duration-500"
                    onClick={() =>
                      handleunblockRequest(profileData.user?._id)
                    }
                    >
                    UnBlock</button>:
                    isBlockedme?
                    <button
                    className="w-[150px] ml-2 flex-1 rounded-full bg-red-100 text-white antialiased font-semibold hover:bg-red-800 dark:hover:bg-red-900 py-1 transition-all sm:text-lg text-xs duration-500"
                    
                    >
                    Blocked</button>
                    :
                  <button
                  className="w-[150px] ml-2 flex-1 rounded-full bg-blue-600 dark:bg-blue-800 text-white antialiased font-semibold hover:bg-blue-800 dark:hover:bg-blue-900 py-1 transition-all sm:text-lg text-xs duration-500"
                  onClick={() =>
                    handleblockRequest(profileData.user?._id)
                  }
                >
                  Block</button>
  }
                </div>}
              </div>
              <div className="flex flex-col items-center justify-start w-1/2 sm:hidden mt-1">
                <Link
                className="flex items-center text-red-600 border border-red-600 px-2 py-1 rounded-lg sm:px-5 sm:py-2 hover:bg-red-600 hover:text-white transition-all duration-500 ease-in-out transform w-28"
                to="/communities"
              >
                <IoArrowBackSharp className="mr-2" /> Group Hub
                </Link>
                <button
                  className="flex items-center mt-2 text-green-600 border border-green-600 px-2 py-1 rounded-lg sm:px-5 sm:py-2 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform w-28"
                  onClick={handleGoBack}
                >
                  <IoArrowBackSharp className="mr-2" /> Back
                </button>
              </div>
            </div>
              <hr className="sm:mt-[30px] mt-3 mb-1 w-full sm:hidden" />
            <div className="flex sm:flex-col justify-between items-center w-full sm:w-2/3 sm:px-2 px-1 mt-2 sm:mt-0">
              <div className="sm:flex items-end justify-end hidden w-full mb-6">
                <Link
                className="flex items-center text-red-600 border border-red-600 px-2 py-1 rounded-lg sm:px-5 sm:py-2 hover:bg-red-600 hover:text-white transition-all duration-500 ease-in-out transform"
                to="/communities"
              >
                <IoArrowBackSharp className="mr-2" /> Group Hub
                </Link>
                <button
                  className="flex items-center justify-center ml-2 text-green-600 border border-green-600 px-2 py-1 rounded-lg sm:px-5 sm:py-2 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform"
                  onClick={handleGoBack}
                >
                  <IoArrowBackSharp className="mr-2" /> Back
                </button>
              </div>
              
              <div className="sm:flex justify-between items-center w-full grid grid-cols-2 sm:grid-cols-none gap-2 sm:gap-0">
                <div
                  className={`flex flex-col justify-center items-center cursor-pointer ${
                    followerTab === "allPost"
                      ? "text-blue-600"
                      : "text-[#000]"
                  }`}
                  onClick={() => setFollowerTab("allPost")}
                >
                  <button
                    className={` text-black w-36 md:w-40 mx-1 sm:w-1/2 flex justify-center items-center px-0 py-[5px] sm:p-[8px] border rounded-lg font-semibold ${ followerTab === "allPost" ? "bg-[#005AE6] border-[#005AE6] text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "border rounded-lg border-gray-500" }`}
                    onClick={() => setFollowerTab("allPost")}
                  >
                    
                    <PostAddIcon className="w-4 sm:w-6 h-4 sm:h-6 mr-2" fontSize="small" /> 
                      <span className="text-xs sm:text-sm mx-1 flex items-center justify-center">Posts</span>
                  </button>
                </div>
                <div
                  className={`flex flex-col justify-center items-center cursor-pointer ${
                    followerTab === "productPosts"
                      ? "text-blue-600"
                      : "text-[#000]"
                  }`}
                  onClick={() => setFollowerTab("productPosts")}
                >
                  <button
                    className={` text-black w-36 md:w-40 mx-1 sm:w-1/2 flex justify-center items-center sm:px-3 px-0 py-[5px] sm:p-[8px] border rounded-lg font-semibold ${ followerTab === "productPosts" ? "bg-[#005AE6] border-[#005AE6] text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "border rounded-lg border-gray-500" }`}
                    onClick={() => setFollowerTab("productPosts")}
                  >
                  <ProductionQuantityLimitsIcon className="w-[14px] sm:w-6 h-[14px] sm:h-6 mx-1 sm:mr-2" fontSize="small" />
                    <span className="text-xs sm:text-sm mr-1 sm:mx-1 flex items-center justify-center">Product Posts</span>
                  </button>
                </div>
                
                <div
                  className={`flex flex-col justify-center items-center cursor-pointer ${
                    followerTab === "myfollowers"
                      ? "text-blue-600"
                      : "text-[#000]"
                  }`}
                  onClick={() => setFollowerTab("myfollowers")}
                >
                  
                  <button
                    className={` text-black w-36 md:w-40 mx-1 sm:w-1/2 flex justify-center items-center sm:px-3 px-0 py-[5px] sm:p-[8px] border rounded-lg font-semibold ${ followerTab === "myfollowers" ? "bg-[#005AE6] border-[#005AE6] text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "border rounded-lg border-gray-500" }`}
                    onClick={() => setFollowerTab("allPost")}
                  >
                  <GroupsIcon className="w-4 sm:w-6 h-4 sm:h-6 mr-2" fontSize="small" />
                  <span className="text-xs sm:text-sm mx-1 flex items-center justify-center">Followers</span>
                  </button>
                </div>
                <div
                  className={`flex flex-col justify-center items-center cursor-pointer ${
                    followerTab === "myfollowing"
                      ? "text-blue-600"
                      : "text-[#000]"
                  }`}
                  onClick={() => setFollowerTab("myfollowing")}
                >
                  <button
                    className={` text-black w-36 md:w-40 mx-1 sm:w-1/2 flex justify-center items-center sm:px-3 px-0 py-[5px] sm:p-[8px] border rounded-lg font-semibold ${ followerTab === "myfollowing" ? "bg-[#005AE6] border-[#005AE6] text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "border rounded-lg border-gray-500" }`}
                    onClick={() => setFollowerTab("myfollowing")}
                  >
                    <PeopleIcon className="w-4 sm:w-6 h-4 sm:h-6 mr-2 ml-2" fontSize="small" /> 
                    <span className="text-xs sm:text-sm mx-1 flex items-center justify-center">Following</span>
                  </button>
                </div>
                <div
                  className={`flex flex-col justify-center items-center cursor-pointer ${
                    followerTab === "userCommunity"
                      ? "text-blue-600"
                      : "text-[#000]"
                  }`}
                  onClick={() => setFollowerTab("userCommunity")}
                >
                  
                  <button
                    className={` text-black w-36 md:w-40 mx-1 sm:w-1/2 flex justify-center items-center sm:px-3 px-0 py-[5px] sm:p-[8px] border rounded-lg font-semibold ${ followerTab === "userCommunity" ? "bg-[#005AE6] border-[#005AE6] text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "border rounded-lg border-gray-500" }`}
                    onClick={() => setFollowerTab("userCommunity")}
                  >
                  <ProductionQuantityLimitsIcon className="w-[14px] sm:w-6 h-[14px] sm:h-6 mx-1 sm:mr-2" fontSize="small" />
                    <span className="text-xs sm:text-sm mr-1 sm:mx-1 flex items-center justify-center">Communities</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr className="sm:mt-[30px] mb-1 hidden sm:block" />
        </div>
          
          {profileData?.user?.userType ==="public" || isFollow ?
          followerTab === "allPost"  && (
            isBlockedme? ("") :
            (<div className="flex flex-col pt-4">
              {posts && posts.length > 0 ? (
                posts
                  .slice()
                  .reverse()
                  .map((post, index) => (
                    <div
                      key={index}
                      className="sm:w-11/12 md:w-3/4 lg:w-1/2 my-2 sm:mx-auto p-2 bg-white rounded-xl shadow-lg overflow-hidden border "
                    >
                    <Link to={`/profile/${post?.createdby?._id}`}>
                      <div className="p-4 flex flex-row items-center">
                        <p className="w-10 h-10 flex items-center justify-center rounded-full mr-2 border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                          {post?.createdby?.username.charAt(0).toUpperCase()}
                        </p>
                        <div className="lg:w-full relative pl-2">
                          <div className="text-[16px] lg:text-[16px] font-bold  flex items-center  cursor-pointer">
                            <h2 className="text-lg font-semibold">
                              {post?.createdby?.username}
                            </h2>
                            <FaCheck className="p-1 m-1 bg-[#0866FF] text-white rounded-[100%] w-3 h-3 lg:w-[15px] lg:h-[15px]" />
                          </div>
                          <div>
                            <p className="text-gray-500 text-xs">
                              {GetTimeAgo(post.createdAt)}
                            </p>
                          </div>
                        </div>
                      </div>
                      </Link>
                      <Link to={`/community/${post?.post_handle}/posts/${post?._id}`}>
                      <div className="relative m-auto mx-3">
                        <h2 className="text-xl my-5  font-bold  flex items-center  cursor-pointer">
                          {post?.title}
                        </h2>
                        <p className="text-base leading-8 border-b-[1px] pb-5">
                          {post?.content}
                        </p>

                        {post && (
                          <div className="relative w-[100%]  full  m-auto ">
                            <div className="rounded-xl py-4">
                              {post.images && post.images[0]?.url && (
                                <div className="rounded-xl ">
                                  {isVideoExtension(post.images[0].url) ===
                                  "video" ? (
                                    <video
                                      className="w-auto h-auto  rounded-lg relative m-auto"
                                      controls
                                      autoPlay
                                      loop
                                      muted
                                    >
                                      <source
                                        src={post.images[0].url}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : (
                                    <img
                                      src={post.images[0].url}
                                      alt="post"
                                      className="w-auto h-auto rounded-lg relative m-auto "
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      </Link>
                      {post?.buyNowUrl && (
                        <div className="flex items-center justify-center my-4">
                          <Link
                            to={post?.buyNowUrl}
                            target="_blank"
                            className="bg-black text-white px-6 p-2 rounded font-medium text-lg hover:bg-red-700 transition-all duration-500"
                          >
                            {" "}
                            Buy Now{" "}
                          </Link>
                        </div>
                      )}
                      <Link to={`/community/${post?.post_handle}/posts/${post?._id}`}>
                      <div className="flex w-full justify-between h-[50px] p-5 border-b border-t border-[#9e9e9e]">
                        <p className="flex items-center cursor-pointer">
                          <>
                            <FaThumbsUp className="text-[#1F51FF]" />
                            <span className="pl-2 text-[#1F51FF]">
                              Like ({post?.likes?.likedBy?.length})
                            </span>
                          </>
                        </p>
                        <p className="flex items-center cursor-pointer">
                          <FaComment className="text-[#9e9e9e]" />
                          <span className="pl-2">
                            Comment ({post?.comments?.length})
                          </span>
                        </p>
                        <p className="flex items-center cursor-pointer">
                          <FaShare className="text-[#9e9e9e]" />
                          <span className="pl-2">Share</span>
                        </p>
                      </div>

                      <div className="flex flex-col p-2 justify-center items-start">
                        {post?.comments &&
                          post?.comments.map((comment, index) => {
                            return (
                              <div
                                className="mt-4 rounded-lg border relative p-2 w-full bg-[#fafafa]"
                                key={index}
                              >
                                <div className="px-3 rounded-md">
                                  <div className="flex items-center mb-4">
                                    <p className="w-10 h-10 flex items-center justify-center rounded-full mr-2 border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                                      {comment?.author?.username
                                        ?.charAt(0)
                                        .toUpperCase()}
                                    </p>
                                    <div>
                                      <h3 className="text-md font-semibold">
                                        {comment?.author?.username}
                                      </h3>
                                      <p className="text-gray-500 text-xs">
                                        {GetTimeAgo(comment.createdAt)}
                                      </p>
                                    </div>
                                  </div>
                                  <p>{comment?.text}</p>
                                </div>

                                <button className="text-[#005AE6] font-semibold mx-3 my-3 hover:underline cursor-pointer">
                                  Reply
                                </button>

                                {comment?.replies.map((reply, index) => {
                                  return (
                                    <div
                                      className="border-t hover:bg-gray-100 bg-gray-50  p-3 relative ml-8 w-auto"
                                      key={index}
                                    >
                                      <div className="flex items-center mb-4">
                                        <p className="w-10 h-10 flex items-center justify-center rounded-full mr-2 border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                                          {reply?.author?.username
                                            ?.charAt(0)
                                            .toUpperCase()}
                                        </p>
                                        <div>
                                          <h3 className="text-md font-semibold">
                                            {reply?.author?.username}
                                          </h3>
                                          <p className="text-gray-500 text-xs">
                                            {GetTimeAgo(reply.createdAt)}
                                          </p>
                                        </div>
                                      </div>
                                      <p> {reply?.text}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                      </div>
                      </Link>
                    </div>
                  ))
              ) : (
                <div className="sm:w-11/12 md:w-3/4 lg:w-1/2 my-2 sm:mx-auto p-2 bg-white rounded-xl shadow-lg overflow-hidden border mt-4">
                <div className="flex justify-center items-center my-10">
                    <p className="sm:text-3xl text-xl font-semibold">
                      No Posts available
                    </p>
                  </div>
                </div>
              )}
            </div>)
          ):""}
          
          {followerTab === "productPosts"  && (
            isBlockedme ? ("") :
            (<div className="flex flex-col pt-4">
              {productPosts && productPosts.length > 0 ? (
                productPosts.map((post, index) => (
                  <div key={index} className="sm:w-11/12 md:w-3/4 lg:w-1/2 my-2 sm:mx-auto p-2 bg-white rounded-xl shadow-lg overflow-hidden border">
                  <Link to={`/profile/${post?.createdby?._id}`}>
                    <div className="p-4 flex flex-row items-center">
                      <p className="w-10 h-10 flex items-center justify-center rounded-full mr-2 border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                        {post?.createdby?.username.charAt(0).toUpperCase()}
                      </p>
                      <div className="lg:w-full relative pl-2">
                        <div className="text-[16px] lg:text-[16px] font-bold  flex items-center  cursor-pointer">
                          <h2 className="text-lg font-semibold">
                            {post?.createdby?.username}
                          </h2>
                          <FaCheck className="p-1 m-1 bg-[#0866FF] text-white rounded-[100%] w-3 h-3 lg:w-[15px] lg:h-[15px]" />
                        </div>
                        <div>
                          <p className="text-gray-500 text-xs">
                            {GetTimeAgo(post.createdAt)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to={`/community/${post?.post_handle}/posts/${post?._id}`}>
                  <div className="relative m-auto mx-3">
                    <h2 className="text-xl my-5  font-bold  flex items-center  cursor-pointer">
                      {post?.title}
                    </h2>
                    <p className="text-base leading-8 border-b-[1px] pb-5">
                      {post?.content}
                    </p>

                    {post && (
                      <div className="relative w-[100%]  full  m-auto ">
                        <div className="rounded-xl py-4">
                          {post.images && post.images[0]?.url && (
                            <div className="rounded-xl ">
                              {isVideoExtension(post.images[0].url) ===
                              "video" ? (
                                <video
                                  className="w-auto h-auto  rounded-lg relative m-auto"
                                  controls
                                  autoPlay
                                  loop
                                  muted
                                >
                                  <source
                                    src={post.images[0].url}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video
                                  tag.
                                </video>
                              ) : (
                                <img
                                  src={post.images[0].url}
                                  alt="post"
                                  className="w-auto h-auto rounded-lg relative m-auto "
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  </Link>
                    {post?.buyNowUrl && (
                      <div className="flex items-center justify-center my-4">
                        <Link
                          to={post?.buyNowUrl}
                          target="_blank"
                          className="bg-black text-white px-6 p-2 rounded font-medium text-lg hover:bg-red-700 transition-all duration-500"
                        >
                          {" "}
                          Buy Now{" "}
                        </Link>
                      </div>
                    )}
                  <Link to={`/community/${post?.post_handle}/posts/${post?._id}`}>
                  <div className="flex w-full justify-between h-[50px] p-5 border-b border-t border-[#9e9e9e]">
                    <p className="flex items-center cursor-pointer">
                      <>
                        <FaThumbsUp className="text-[#1F51FF]" />
                        <span className="pl-2 text-[#1F51FF]">
                          Like ({post?.likes?.likedBy?.length})
                        </span>
                      </>
                    </p>
                    <p className="flex items-center cursor-pointer">
                      <FaComment className="text-[#9e9e9e]" />
                      <span className="pl-2">
                        Comment ({post?.comments?.length})
                      </span>
                    </p>
                    <p className="flex items-center cursor-pointer">
                      <FaShare className="text-[#9e9e9e]" />
                      <span className="pl-2">Share</span>
                    </p>
                  </div>

                  <div className="flex flex-col p-2 justify-center items-start">
                    {post?.comments &&
                      post?.comments.map((comment, index) => {
                        return (
                          <div
                            className="mt-4 rounded-lg border relative p-2 w-full bg-[#fafafa]"
                            key={index}
                          >
                            <div className="px-3 rounded-md">
                              <div className="flex items-center mb-4">
                                <p className="w-10 h-10 flex items-center justify-center rounded-full mr-2 border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                                  {comment?.author?.username
                                    ?.charAt(0)
                                    .toUpperCase()}
                                </p>
                                <div>
                                  <h3 className="text-md font-semibold">
                                    {comment?.author?.username}
                                  </h3>
                                  <p className="text-gray-500 text-xs">
                                    {GetTimeAgo(comment.createdAt)}
                                  </p>
                                </div>
                              </div>
                              <p>{comment?.text}</p>
                            </div>

                            <button className="text-[#005AE6] font-semibold mx-3 my-3 hover:underline cursor-pointer">
                              Reply
                            </button>

                            {comment?.replies.map((reply, index) => {
                              return (
                                <div
                                  className="border-t hover:bg-gray-100 bg-gray-50  p-3 relative ml-8 w-auto"
                                  key={index}
                                >
                                  <div className="flex items-center mb-4">
                                    <p className="w-10 h-10 flex items-center justify-center rounded-full mr-2 border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                                      {reply?.author?.username
                                        ?.charAt(0)
                                        .toUpperCase()}
                                    </p>
                                    <div>
                                      <h3 className="text-md font-semibold">
                                        {reply?.author?.username}
                                      </h3>
                                      <p className="text-gray-500 text-xs">
                                        {GetTimeAgo(reply.createdAt)}
                                      </p>
                                    </div>
                                  </div>
                                  <p> {reply?.text}</p>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                  </div>
                  </Link>
                </div>
                ))
              ) : (
                <div className="sm:w-11/12 md:w-3/4 lg:w-1/2 my-2 sm:mx-auto p-2 bg-white rounded-xl shadow-lg overflow-hidden border mt-4">
                <div className="flex justify-center items-center my-10">
                    <p className="sm:text-3xl text-xl font-semibold">
                    Currently, no product posts available.
                    </p>
                  </div>
                </div>
              )}
            </div>)
          )}

          <div className="pt-5">
            {followerTab === "userCommunity" && (
              <div className={`flex flex-wrap gap-4 bg-[#E5E7EB] px-4 pb-2 w-full ${userCommunity.length > 0 ? '' : 'sm:h-auto h-[250px]'}`}>
                {userCommunity.length > 0 ? (
                  userCommunity.map((item, index) => {
                    //const isUser = item.author?._id === userData._id;
                    const approvedMembers = item?.members.filter( (member) => member.status === "approved" );
                    const memberCount = approvedMembers.length;
                    return (
                      <div
                        key={index}
                        className="p-4 flex flex-col justify-between cursor-pointer bg-white rounded-xl shadow-md mb-2 sm:mb-5 w-full md:w-[31%] lg:w-[32%] xl:w-[24%]"
                      >
                        <Link to={`/community/${item.community_handle}`}>
                          <div className={`*:first-letter:flex justify-between mb-2 items-center bg-cover ${item?.cover_img ? 'border' : 'border-none'} border-[#eee] bg-center bg-no-repeat rounded-md h-[80px]`} style={{ backgroundImage: `url(${item?.cover_img ? item?.cover_img : '/assets/blue-bg.png'})` }}></div>
                          <div className="flex justify-between mb-2 items-center  ">
                            <img
                              src={item.hero_img}
                              alt="savage-logo"
                              className="w-16 h-16 rounded-full border-blue border-4 cursor-pointer mt-[-50px] ml-5"
                            />
                            {item.communityType === "public" ? (
                              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10">
                                Public
                              </span>
                            ) : item.communityType === "private" ? (
                              <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500/10">
                                Private
                              </span>
                            ) : item.communityType === "protected" ? (
                              <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-500/10">
                                Protected
                              </span>
                            ) : null}
                          </div>
                          <div className={`lg:w-full relative pl-2 border-b border-[#f0f0f0] pb-5 ${dynamicHeight}`}>
                          <h2 className="flex items-center text-[16px] lg:text-[18px] font-bold  cursor-pointer">
                          {item.title.length > 28 ? `${item.title.substring(0, 27)}...` : item.title}
                        </h2>
                            <p className="text-sm text-black line-clamp-3 ">
                              {item.about}
                            </p>
                          </div>
                        </Link>
                        <div className={`lg:w-full relative ${item?.discloseauthorname === true ? 'justify-between' : 'justify-end'} items-center flex`}>
                          <div className={`lg:w-full relative pl-2 ${item?.discloseauthorname === true ? 'justify-between mt-4' : 'justify-end mt-3'} items-center flex ${memberCount > 0 ? 'pt-2' : 'pt-5'} w-full`}>
                            <p className={`text-[rgb(0,0,0)] flex absolute py-2 left-2 ${item?.discloseauthorname === true ? '-top-3' : ''}`}>
                            </p>
                            {item?.discloseauthorname && (
                              <span className={`text-sm capitalize my-1 font-semibold mt-5 ${memberCount > 0 ? 'pt-3' : ''}`}>
                                {item?.author?.username}
                              </span>
                            )}
                            <div className="flex items-center">
                              {memberCount === 0 ? (
                                <span className="text-xs mt-5">Be the first to join</span>
                              ) : (
                                <div className="flex flex-col items-center justify-center">
                                  <div className="flex items-center ">
                                    {approvedMembers.slice(0, 3).map((member, idx) => (
                                      <img
                                        key={member._id}
                                        src={member.hero_img || (idx === 0 ? '/assets/member1.jpg' : idx === 1 ? '/assets/member2.jpg' : '/assets/member3.jpg')}
                                        alt={`${member.username}'s profile`}
                                        className={`w-8 h-8 rounded-full border-[#f0f0f0] border-[2px] ${idx > 0 ? 'ml-[-10px]' : ''}`}
                                      />
                                    ))}
                                  </div>
                                  <span className="mx-2 text-xs mt-1">
                                    {memberCount} {memberCount === 1 ? "Member" : "Members"}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="sm:w-11/12 md:w-3/4 lg:w-1/2 my-2 sm:mx-auto p-2 bg-white rounded-xl shadow-lg overflow-hidden border mt-2">
                    <div className="flex justify-center items-center my-10">
                      <p className="sm:text-3xl text-xl font-semibold">
                        No communities available
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          
          {followerTab === "myfollowers" ? (
          isBlockedme?"":
            <div className="sm:w-11/12 md:w-3/4 lg:w-1/2 my-2 sm:mx-auto p-2 bg-white rounded-xl shadow-lg overflow-hidden border mt-2">
              <div className="border-b w-full m-auto">
                {getAllFollower && getAllFollower.length > 0 ? (
                  <div className="mx-auto">
                    {getAllFollower.map((follower, index) => (
                      <Link to={`/profile/${follower.userId}`}>
                        <div className="sm:p-5 py-3 flex items-center justify-between cursor-pointer hover:bg-gray-200 transition-all duration-500 border-b">
                          <Link to={`/profile/${follower.userId}`}>
                            <div className="flex items-center w-full sm:w-20 sm:h-16 ml-3 sm:ml-0 py-3">
                              <img
                                className="rounded-full h-20 sm:w-20 w-[6.5rem]"
                                src={follower.hero_img}
                                alt="follower"
                              />
                            </div>
                          </Link>
                          <div className="flex  sm:flex-row sm:justify-between sm:items-center w-full flex-col items-start ml-6 ">
                            <Link to={`/profile/${follower.userId}`}>
                              <div className="ml-2 flex flex-col">
                                <div className="leading-snug sm:text-lg text-sm text-gray-900 font-semibold mb-1 capitalize">
                                  {follower.username}
                                </div>
                                <div className="leading-snug sm:text-sm text-xs text-gray-600">
                                  {follower.email}
                                </div>
                              </div>
                            </Link>
                            {/* <div className="flex justify-between items-center sm:mt-0 mt-2">
                                                            <button className="sm:py-2 py-[2px] px-5  sm:text-md text-xs sm:font-semibold font-normal bg-red-100 mr-2 text-red-500 border border-red-600 rounded-full  hover:bg-red-600 hover:text-white transition-all duration-300"
                                                                onClick={() => handleRemoveFollower(follower.userId)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div> */}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <div className="flex justify-center items-center my-10">
                    <p className="sm:text-3xl text-xl font-semibold">
                      No Follower available
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {followerTab === "myfollowing" ? (
          isBlockedme?"":
            <div className="sm:w-11/12 md:w-3/4 lg:w-1/2 my-2 sm:mx-auto p-2 bg-white rounded-xl shadow-lg overflow-hidden border mt-2">
              <div className="border-b w-full m-auto">
                {getAllFollowing && getAllFollowing.length > 0 ? (
                  <div className="mx-auto">
                    {getAllFollowing.map((following, index) => (
                      <Link to={`/profile/${following.userId}`}>
                        <div className="sm:p-5 py-3 flex items-center justify-between cursor-pointer hover:bg-gray-200 transition-all duration-500 border-b">
                          <Link to={`/profile/${following.userId}`}>
                            <div className="flex items-center w-full sm:w-20 sm:h-16 ml-3 sm:ml-0 py-3">
                              <img
                                className="rounded-full h-20 sm:w-20 w-[6.5rem]"
                                src={following.hero_img}
                                alt="follower"
                              />
                            </div>
                          </Link>
                          <div className="flex  sm:flex-row sm:justify-between sm:items-center w-full flex-col items-start ml-6 ">
                            <Link to={`/profile/${following.userId}`}>
                              <div className="ml-2 flex flex-col">
                                <div className="leading-snug sm:text-lg text-sm text-gray-900 font-semibold mb-1 capitalize">
                                  {following.username}
                                </div>
                                <div className="leading-snug sm:text-sm text-xs text-gray-600">
                                  {following.email}
                                </div>
                              </div>
                            </Link>
                            {/* <div className="flex justify-between items-center sm:mt-0 mt-2">
                                                            <button className="sm:py-2 py-[2px] px-5  sm:text-md text-xs sm:font-semibold font-normal bg-red-100 mr-2 text-red-500 border border-red-600 rounded-full  hover:bg-red-600 hover:text-white transition-all duration-300"
                                                                onClick={() => handleRemoveFollower(following.userId)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div> */}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <div className="flex justify-center items-center my-10">
                    <p className="sm:text-3xl text-xl font-semibold">
                      No Following available
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </Layout>
    </div>
  );
};

export default UserProfile;

import React, { Fragment, useContext, useRef, useEffect } from "react";
import { CallContext } from "../contexts/CallContext";
import { useUser } from "../contexts/UserProvider";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { MdOutlineVideocamOff } from "react-icons/md";
import { AudioCallContext } from "../contexts/audioCallContext";
import { GroupCallContext } from "../contexts/groupCallContext";
import { BsMicMute, BsMic } from "react-icons/bs";
import { SlCallEnd } from "react-icons/sl";

// export default function VideoCom() {
//   const {
//     isCalling,
//     DropCall,
//     MyVideoRef,
//     RemoteVideoRef,
//     UpdateAudio,
//     UpdateVideo,
//     MyMicStatus,
//     MyVideoStatus,
//     call,
//   } = useContext(CallContext);
//   const { userData } = useUser();
//   const id = call?.from;

//   return (
//     <Fragment>
//       {isCalling ? (
//         <div className="absolute inset-0 w-full h-screen">
//           <div className="video_box h-full">
//             <video
//               className="object-cover main_screen h-full w-full"
//               autoPlay
//               ref={RemoteVideoRef}
//             ></video>
//             <video
//               autoPlay
//               className="absolute top-5 left-5 h-40 w-40 local_screen"
//               ref={MyVideoRef}
//             ></video>
//             <div
//               className="video_controls_buttons absolute bottom-10 left-[40%]
//             flex items-center justify-between space-x-5 right-[40%]"
//             >
//               <button
//                 onClick={() => {
//                   UpdateAudio();
//                 }}
//                 className="mic_btn px-4 py-2  rounded-md focus:border focus:border-indigo-700
//                     outline-none bg-indigo-700 focus:text-white focus:bg-transparent
//                     "
//               >
//                 {MyMicStatus ? (
//                   <BsMic className="text-white text-2xl" />
//                 ) : (
//                   <BsMicMute className="text-white text-2xl" />
//                 )}
//               </button>
//               <button
//                 type="button"
//                 onClick={() => UpdateVideo()}
//                 className="video_btn px-4 py-2  rounded-md focus:border focus:border-sky-700
//                     outline-none bg-sky-700 focus:text-white focus:bg-transparent"
//               >
//                 {MyVideoStatus ? (
//                   <HiOutlineVideoCamera className="text-white text-2xl" />
//                 ) : (
//                   <MdOutlineVideocamOff className="text-white text-2xl" />
//                 )}
//               </button>
//               <button
//                 onClick={() => DropCall(id)}
//                 type="button"
//                 className="cancel_call_btn  px-4 py-2  rounded-md focus:border focus:border-red-700
//                     outline-none bg-red-700 focus:text-white focus:bg-transparent"
//               >
//                 <SlCallEnd className="text-white text-2xl" />
//               </button>
//             </div>
//           </div>
//         </div>
//       ) : null}
//     </Fragment>
//   );
// }

export const VideoCom = () => {
  const {
    isCalling,
    callUser,
    answerCall,
    Isincomingcall,
    DropCall,
    MyVideoRef,
    JoinConference,
    LeaveConference,
    RemoteVideoRef,
    UpdateAudio,
    UpdateVideo,
    MyMicStatus,
    MyVideoStatus,
    LeaveCall,
    call,
  } = useContext(CallContext);
  const { userData } = useUser();
  const id = call?.to;
  const from = call?.from;
  // const roomId = call?.roomId;
  // console.log("roomID", roomId);
  // console.log("id", id);
  // console.log("from", from);
  // // console.log("userData", userData);
  // console.log("call", call);
  // console.log("MyVideoRef", RemoteVideoRef);

  return (
    <Fragment>
      {isCalling ? (
        <div className="absolute inset-0 w-full h-screen">
          <div className="video_box h-full">
            <video
              className="object-cover main_screen h-full w-full"
              autoPlay
              ref={RemoteVideoRef}
            ></video>
            <video
              autoPlay
              className="absolute top-5 left-5 h-40 w-40 local_screen"
              ref={MyVideoRef}
            ></video>
            <div
              className="video_controls_buttons absolute bottom-10 left-[40%] 
                    flex items-center justify-between space-x-5 right-[40%]"
            >
              <button
                onClick={() => {
                  UpdateAudio();
                }}
                className="mic_btn px-4 py-2  rounded-md focus:border focus:border-indigo-700
                            outline-none bg-indigo-700 focus:text-white focus:bg-transparent
                            "
              >
                {MyMicStatus ? (
                  <BsMic className="text-white text-2xl" />
                ) : (
                  <BsMicMute className="text-white text-2xl" />
                )}
              </button>
              <button
                type="button"
                onClick={() => UpdateVideo()}
                className="video_btn px-4 py-2  rounded-md focus:border focus:border-sky-700
                            outline-none bg-sky-700 focus:text-white focus:bg-transparent"
              >
                {MyVideoStatus ? (
                  <HiOutlineVideoCamera className="text-white text-2xl" />
                ) : (
                  <MdOutlineVideocamOff className="text-white text-2xl" />
                )}
              </button>
              <button
                onClick={() => DropCall(id, from)}
                type="button"
                className="cancel_call_btn  px-4 py-2  rounded-md focus:border focus:border-red-700
                            outline-none bg-red-700 focus:text-white focus:bg-transparent"
              >
                <SlCallEnd className="text-white text-2xl" />
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export const AudioCallComponent = () => {
  const {
    isAudioCalling,
    callUser,
    answerCall,
    DropAudioCall,
    updateAudioCall,
    MyMicStatus,
    audiocall,
  } = useContext(AudioCallContext);
  
  const id = audiocall?.to;
  const from = audiocall?.from;

  return (
    <Fragment>
      {isAudioCalling && (
        <div className="absolute inset-0 w-full h-screen flex items-center justify-center bg-gray-800">
          <div className="audio_controls_buttons flex flex-col items-center space-y-5">
            <button
              onClick={updateAudioCall}
              className="mic_btn px-4 py-2 rounded-md bg-indigo-700 text-white focus:border focus:border-indigo-700 focus:text-white focus:bg-transparent outline-none"
            >
              {MyMicStatus ? (
                <BsMic className="text-2xl" />
              ) : (
                <BsMicMute className="text-2xl" />
              )}
            </button>
            <button
              onClick={() => DropAudioCall(id, from)}
              type="button"
              className="cancel_call_btn px-4 py-2 rounded-md bg-red-700 text-white focus:border focus:border-red-700 focus:text-white focus:bg-transparent outline-none"
            >
              <SlCallEnd className="text-2xl" />
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export const GroupCallComponent = () => {
  const {
    localStream,
    remoteStreams,
    isGroupCalling,
    myVideoRef,
    remoteVideoRefs,
    joinGroupCall,
    leaveGroupCall,
    callDrop,
    updateAudio,
    updateVideo,
    myMicStatus,
    myVideoStatus,
    groupCall,
  } = useContext(GroupCallContext);
  const data = groupCall;
  // console.log("groupCall>>>>>>", data);
  
  useEffect(() => {
    Object.keys(remoteStreams).forEach((userId) => {
      if (!remoteVideoRefs.current[userId]) {
        const videoElement = document.createElement("video");
        videoElement.srcObject = remoteStreams[userId];
        videoElement.autoplay = true;
        videoElement.playsInline = true;
        videoElement.className = "remote_video";
        remoteVideoRefs.current[userId] = videoElement;

        const remoteVideosContainer = document.querySelector(".remote_videos_container");
        if (remoteVideosContainer) {
          remoteVideosContainer.appendChild(videoElement);
        }
      }
    });

    return () => {
      Object.keys(remoteVideoRefs.current).forEach((userId) => {
        const videoElement = remoteVideoRefs.current[userId];
        if (videoElement && !remoteStreams[userId]) {
          videoElement.srcObject = null;
          videoElement.remove();
          delete remoteVideoRefs.current[userId];
        }
      });
    };
  }, [remoteStreams]);

  return (
    <Fragment>
      {isGroupCalling && (
        <div className="absolute inset-0 w-full h-screen">
          <div className="video_box h-full">
            <div className="remote_videos_container flex flex-wrap">
            </div>
            <video
              autoPlay
              muted
              className="absolute top-5 left-5 h-40 w-40 local_screen"
              ref={myVideoRef}
            ></video>
            <div className="video_controls_buttons absolute bottom-10 left-[40%] flex items-center justify-between space-x-5 right-[40%]">
              <button
                onClick={updateAudio}
                className="mic_btn px-4 py-2 rounded-md bg-indigo-700 text-white"
              >
                {myMicStatus ? (
                  <BsMic className="text-white text-2xl" />
                ) : (
                  <BsMicMute className="text-white text-2xl" />
                )}
              </button>
              <button
                type="button"
                onClick={updateVideo}
                className="video_btn px-4 py-2 rounded-md bg-sky-700 text-white"
              >
                {myVideoStatus ? (
                  <HiOutlineVideoCamera className="text-white text-2xl" />
                ) : (
                  <MdOutlineVideocamOff className="text-white text-2xl" />
                )}
              </button>
              <button
                onClick={() => callDrop(groupCall)}
                type="button"
                className="cancel_call_btn px-4 py-2 rounded-md bg-red-700 text-white"
              >
                <SlCallEnd className="text-white text-2xl" />
              </button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
import axios from "axios";
import React, { useEffect, useState } from "react";
import { HiPencilAlt as CiEdit } from "react-icons/hi";
import { useUser } from "../contexts/UserProvider";
import LoaderSpiner from "./loader";
import { getCookie } from "../config/coockies";
import { Link, useNavigate } from "react-router-dom";
import { CiBookmarkRemove } from "react-icons/ci";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { apiurl, marketplaceapiurl } from "../config/config";
import { MdVerified } from "react-icons/md";
const MySwal = withReactContent(Swal);

const ProfileCommunity = () => {
  const token = getCookie("savage_token");
  const { userData } = useUser();
  const [getCommunity, setGetCommunity] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleGetProfileCommunity = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiurl}/community/user-communities/${userData._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setGetCommunity(response.data?.communities || []);
        setLoading(false);
      } else {
        console.error("Failed to get the profile community");
        setLoading(false);
      }
    } catch (error) {
      console.error("my Community data", error);
      setLoading(false);
    }
  };

  const deleteCommunityPop = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteCommunity(id);
        MySwal.fire({
          title: "Deleted!",
          text: "Your Community has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const deleteCommunity = async (id) => {
    // console.log(searchCommunities);

    try {
      const response = await axios.delete(
        `${apiurl}/community/delete-community/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('Search communities response  >>>>>>>>>>>>>>>>>>-----:', response.data);
      handleGetProfileCommunity();
    } catch (error) {
      console.error("Error searching communities:", error);
    }
  };

  useEffect(() => {
    handleGetProfileCommunity();
  }, []);
  const[sellerType,setSellerType]=useState(false)
  const checkVendor = async () => {
    try {
      const { data } = await axios({
        url: `${marketplaceapiurl}/seller/checksellerexistance`,
        method: "POST",
        data: {
          email: userData.email,
        },
      });
      // console.log("role listing", data);

      if (data?.data?.sellerType=="organization") {
        setSellerType(true);
        
      } else {
        setSellerType(false);
      }
    } catch (error) {
      
    }
  };

  useEffect(() => {
    checkVendor()
  },[])

  const fetchCommunities = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/community/get`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const userCommunities = response.data.filter((community) =>
          community.members.some(
            (member) => member._id === userData._id
          )
        );
        setCommunities(userCommunities);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCommunities();
  }, []);

  const maxAboutLength = Math.max(...communities.map(item => item.about.length));
  const dynamicHeight = maxAboutLength > 78 ? "sm:h-[100px]" : maxAboutLength > 38 ? "sm:h-24" : "";

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <div className={`flex flex-wrap gap-4 bg-[#E5E7EB] px-4 pb-2 w-full ${communities.length > 0 ? '' : 'sm:h-auto h-[250px]'}`}>
        {communities.length > 0 ? (
          communities.map((item, index) => {
            const isUser = item.author?._id === userData._id;
            const approvedMembers = item?.members.filter( (member) => member.status === "approved" );
            const memberCount = approvedMembers.length;
            return (
              <div
                key={index}
                className="p-4 flex flex-col justify-between cursor-pointer bg-white rounded-xl shadow-md mb-2 sm:mb-5 w-full md:w-[31%] lg:w-[32%] xl:w-[24%]"
              >
                <Link to={`/community/${item.community_handle}`}>
                  <div className={`*:first-letter:flex justify-between mb-2 items-center bg-cover ${item?.cover_img ? 'border' : 'border-none'} border-[#eee] bg-center bg-no-repeat rounded-md h-[80px]`} style={{ backgroundImage: `url(${item?.cover_img ? item?.cover_img : '/assets/blue-bg.png'})` }}></div>
                  <div className="flex justify-between mb-2 items-center  ">
                    <img
                      src={item.hero_img}
                      alt="savage-logo"
                      className="w-16 h-16 rounded-full border-blue border-4 cursor-pointer mt-[-50px] ml-5"
                    />
                    {item.communityType === "public" ? (
                      <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10">
                        Public
                      </span>
                    ) : item.communityType === "private" ? (
                      <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500/10">
                        Private
                      </span>
                    ) : item.communityType === "protected" ? (
                      <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-500/10">
                        Protected
                      </span>
                    ) : null}
                  </div>
                  <div className={`lg:w-full relative pl-2 border-b border-[#f0f0f0] pb-5 ${dynamicHeight}`}>
                  <h2 className="flex items-center text-[16px] lg:text-[18px] font-bold  cursor-pointer">
                  {sellerType && userData.email==item?.author?.useremail?<MdVerified className="text-blue-600 mr-2"/>:""}
                  {item.title.length > 28 ? `${item.title.substring(0, 27)}...` : item.title}
                </h2>
                    <p className="text-sm text-black line-clamp-3 ">
                      {item.about}
                    </p>
                  </div>
                </Link>
                <div className={`lg:w-full relative ${item?.discloseauthorname === true ? 'justify-between' : 'justify-end'} items-center flex`}>
                  <div className={`lg:w-full relative pl-2 ${item?.discloseauthorname === true ? 'justify-between mt-4' : 'justify-end mt-3'} items-center flex ${memberCount > 0 ? 'pt-2' : 'pt-5'} w-full`}>
                    <p className={`text-[rgb(0,0,0)] flex absolute py-2 left-2 ${item?.discloseauthorname === true ? '-top-3' : ''}`}>
                    {isUser === true && (
                      <>
                        <CiEdit
                          onClick={() =>
                            navigate(
                              `/create-community?handle=${item.community_handle}&cid=${item._id}`
                            )
                          }
                          className="text-[#fff] bg-[#038703] border mr-1 border-gray-500 rounded-full p-1.5 h-8 w-8 hover:bg-blue-700 transition-all duration-300 hover:text-white cursor-pointer"
                        />
                        <CiBookmarkRemove
                          onClick={() => deleteCommunityPop(item._id)}
                          className="text-[#fff] bg-[#ff0000]  rounded-full p-1.5 h-8 w-8 hover:bg-blue-700 transition-all duration-300 hover:text-white cursor-pointer"
                        />
                      </>
                    )}
                    </p>
                    {item?.discloseauthorname && (
                      <span className={`text-sm capitalize my-1 font-semibold mt-5 ${memberCount > 0 ? 'pt-3' : ''}`}>
                        {item?.author?.username}
                      </span>
                    )}
                    <div className="flex items-center">
                      {memberCount === 0 ? (
                        <span className="text-xs mt-5">Be the first to join</span>
                      ) : (
                        <div className="flex flex-col items-center justify-center">
                          <div className="flex items-center ">
                            {approvedMembers.slice(0, 3).map((member, idx) => (
                              <img
                                key={member._id}
                                src={member.hero_img || (idx === 0 ? '/assets/member1.jpg' : idx === 1 ? '/assets/member2.jpg' : '/assets/member3.jpg')}
                                alt={`${member.username}'s profile`}
                                className={`w-8 h-8 rounded-full border-[#f0f0f0] border-[2px] ${idx > 0 ? 'ml-[-10px]' : ''}`}
                              />
                            ))}
                          </div>
                          <span className="mx-2 text-xs mt-1">
                            {memberCount} {memberCount === 1 ? "Member" : "Members"}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="bg-white p-4 sm:h-auto h-[100px] flex items-center justify-center sm:p-8 rounded-lg shadow-md mb-4 w-full">
            <p className="sm:text-2xl text-lg font-semibold text-center">
              No communities available
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileCommunity;

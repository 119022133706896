import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiurl,marketplaceapiurl } from "../config/config";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getCookie } from "../config/coockies";
import Layout from "../components/layout";
import { IoLocationOutline, IoArrowBackSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import LoaderSpiner from "../components/loader";
import { FaComment, FaShare, FaCheck, FaThumbsUp } from "react-icons/fa";
import { GetTimeAgo } from "../components/getTime";
import { useUser } from "../contexts/UserProvider";
import UpdateProfile from "../components/updateProfile";
import { FaBell, FaBellSlash } from 'react-icons/fa'; 
const UserProfilefeed = () => {
  const token = getCookie("savage_token");
  const { userid } = useParams();
  const { userData } = useUser();
  const [profileData, setProfileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const[buynowquery,setBuynowquery]=useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationContent, setNotificationContent] = useState(null);
  // const navigate = useNavigate();
  // const handleGoBack = () => {
  //   navigate(-1);
  // };

  const handleUserProfile = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiurl}/notifications/get-all-notifications/${userData._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log("user profile data >>>>>", response);
      if (response.data.success === true) {
        const sortedNotifications = response.data.notifications.sort((a, b) => {
          const dateA = a.createdAt || a.user.createdAt; 
          const dateB = b.createdAt || b.user.createdAt;
          return new Date(dateB) - new Date(dateA); 
        });
  
        setLoading(false);
        setProfileData(sortedNotifications);
        handleUserproductnotification();
        handleGetNotifications();
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("User Profile error", error);
    }
  };

  const handleUserproductnotification = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${marketplaceapiurl}/notification/getNotification`,{seller_email:userData.email}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log("user profile data >>>>>", response);
      if (response.data.status === 200) {
        setLoading(false);
        const newNotifications=response.data.data;
        // console.log(response.data.data)
        setProfileData((prevData) => [...prevData, ...newNotifications]);
      } else {
        setLoading(false);
        //toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("User Profile error", error);
    }
  };
  useEffect(() => {
    handleUserProfile();
  }, [userid]);

  const handleGetNotifications = async () => {
    try{
      const response = await axios.get(
        `${apiurl}/notifications/getNotifications/${userData?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if(response?.data){
        setNotificationData(response?.data?.notifications || []);
      }

    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }
  const handleNotificationReadByAuthor = async (id) => {
    // setLoading(true);
    try{
      const response = await axios.put(
        `${apiurl}/notifications/updateNotificationReadByAuthor/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if(response.data.success === true) {
        handleGetNotifications();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }
  const handleNotificationReadByUser = async (id) => {
    // setLoading(true);
    try{
      const response = await axios.put(
        `${apiurl}/notifications/updateNotificationReadByUser/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if(response.data.success === true) {
        handleGetNotifications();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

// console.log(profileData)
  const [isSilenced, setIsSilenced] = useState(true);

  // Function to toggle silence state
  const toggleSilence = async() => {
  
    
    try {
      const mydata = {
        userId: userData._id
      }
      // console.log('user submitting data', mydata)
      const response = await axios.post(`${apiurl}/notifications/toggle-notification`, mydata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log("user response", response);
      if (response.status === 200) {
        setIsSilenced(response.data.status);
        
          toast.info(response.data.message)
       
      } else {
        console.error("Failed to add product");
      }
    } catch (error) {
      console.error("user register", error);
    }
   
  };
  const getnotifictionstatus = async() => {
  
    
    try {

      // console.log('user submitting data', mydata)
      const response = await axios.get(`${apiurl}/notifications/get-notification-status/${userData._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log("user response", response);
      if (response.status === 200) {
        setIsSilenced(response.data.status);
       
      } else {
        console.error("Failed to add product");
      }
    } catch (error) {
      console.error("user register", error);
    }
   
  };
  useEffect(()=>{
    getnotifictionstatus()
  },[]);

  const handleNotificationClickByAuthor = (notification) => {
    const notificationElement = document.getElementById(`notification-${notification._id}`);
    if (notificationElement) {
        const content = notificationElement.innerHTML; 
        setNotificationContent(content); 
    }
    setOpenNotification(true);
    handleNotificationReadByAuthor(notification._id);
};
  const handleNotificationClickByUser = (notification) => {
    const notificationElement = document.getElementById(`notification-${notification._id}`);
    if (notificationElement) {
        const content = notificationElement.innerHTML; 
        setNotificationContent(content); 
    }
    setOpenNotification(true);
    handleNotificationReadByUser(notification._id);
};

  
  return (
    <div>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      
        <div className="dark:bg-gray-700 bg-gray-200 p-4">
          {/* <button
            className="flex items-center text-green-600 border border-green-600 px-2 py-1 rounded-lg sm:px-5 sm:py-2 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform"
            onClick={handleGoBack}
          >
            <IoArrowBackSharp className="mr-2" /> Back
          </button> */}
          <div className="sm:w-11/12 md:w-3/4 lg:w-1/2 my-2 mt-2 sm:mx-auto p-2 bg-white rounded-xl shadow-lg overflow-hidden border sm:mt-5">
          <div className="ml-4 flex justify-end">
          <label className="relative inline-flex items-end cursor-pointer">
            <input 
              type="checkbox" 
              className="sr-only peer" 
              checked={isSilenced}
              onChange={toggleSilence} 
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:bg-blue-600"></div>
            <div className="dot absolute top-0.5 left-0.5 bg-white w-5 h-5 rounded-full transition peer-checked:translate-x-full peer-checked:bg-blue-500"></div>
          </label>
          {/* Optional label to indicate silenced or not */}
          <span className="ml-2 text-sm text-gray-600">
            {isSilenced ? 'Silenced' : 'Active'}
          </span>
        </div>
        {isSilenced?
          profileData && (
              <div className="border-b pb-6 w-[95%] sm:w-11/12 m-auto">
              <div className="flex justify-between items-center mt-4">
                <h2 className="text-lg font-semibold">Notifications</h2>
                <h2 className="cursor-pointer text-blue-600" onClick={()=>setBuynowquery(!buynowquery)}>{buynowquery?"See Group Notifications":"See Product Notifications"}</h2>
              </div>

              <div className="flex flex-col justify-center mt-4 space-y-4">
                {!buynowquery?
                  Array.isArray(notificationData) && notificationData.map((notification, index) => (
                  notification.type?
                  <>
                    {(notification?.type === 'JOIN_COMMUNITY_REQUEST' && (userData?._id === notification?.author)) && (
                    <div
                      key={notification?._id}
                      id={`notification-${notification?._id}`}
                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer ${notification?.readbyauthor ? 'bg-white border' : 'bg-blue-50'}`}
                      onClick={() => handleNotificationClickByAuthor(notification)}
                    >
                      <img
                        className="w-10 h-10 rounded-full mr-4"
                        src={notification?.userdata?.hero_img ||'/profile.png'}
                        alt="Profile"
                      />
                      <div className="flex-1">
                            <p className="text-sm text-gray-700">
                              <Link to={`/profile/${notification?.userdata?._id}`} 
                                className="font-semibold text-blue-600 hover:underline capitalize"
                              >
                                {notification?.userdata?.name }
                              </Link>
                              {" "}has {notification?.message}
                              <Link to={`/community/${notification?.communitydata?.community_handle}`} 
                                className="ml-1 font-semibold text-blue-600 hover:underline"
                              >
                                {notification?.communitydata?.title}
                              </Link>
                            </p>
                        <p className="text-xs text-gray-500">
                          {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                        </p> 
                      </div>
                    </div>
                    )}
                    {(notification?.type === 'ACCEPT_JOIN_REQUEST' && (userData?._id === notification?.userdata?._id)) && (
                    <div
                      key={notification?._id}
                      id={`notification-${notification?._id}`}
                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer ${notification?.readbyuser ? 'bg-white border' : 'bg-blue-50'}`}
                      onClick={() => handleNotificationClickByUser(notification)}
                    >
                      <img
                        className="w-10 h-10 rounded-full mr-4"
                        src={notification?.authordata?.hero_img ||'/profile.png'}
                        alt="Profile"
                      />
                      <div className="flex-1">
                            <p className="text-sm text-gray-700">
                              <Link to={`/profile/${notification?.author}`} 
                                className="font-semibold text-blue-600 hover:underline capitalize"
                              >
                                {notification?.authordata?.name }
                              </Link>
                              {" "} has accepted your request to join the community
                              <Link to={`/community/${notification?.communitydata?.community_handle}`} 
                                className="ml-1 font-semibold text-blue-600 hover:underline"
                              >
                                {notification?.communitydata?.title}.
                              </Link>
                            </p>
                        <p className="text-xs text-gray-500">
                          {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                        </p> 
                      </div>
                    </div>
                    )}
                    {(notification?.type === 'JOIN_COMMUNITY' && (userData?._id === notification?.author)) && (
                    <div
                      key={notification?._id}
                      id={`notification-${notification?._id}`}
                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer ${notification?.readbyauthor ? 'bg-white border' : 'bg-blue-50'}`}
                      onClick={() => handleNotificationClickByAuthor(notification)}
                    >
                      <img
                        className="w-10 h-10 rounded-full mr-4"
                        src={notification?.userdata?.hero_img ||'/profile.png'}
                        alt="Profile"
                      />
                      <div className="flex-1">
                            <p className="text-sm text-gray-700">
                              <Link to={`/profile/${notification?.userdata?._id}`} 
                                className="font-semibold text-blue-600 hover:underline capitalize"
                              >
                                {notification?.userdata?.name }
                              </Link>
                              {" "} has joined the community
                              <Link to={`/community/${notification?.communitydata?.community_handle}`} 
                                className="ml-1 font-semibold text-blue-600 hover:underline"
                              >
                                {notification?.communitydata?.title}.
                              </Link>
                            </p>
                        <p className="text-xs text-gray-500">
                          {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                        </p> 
                      </div>
                    </div>
                    )}
                    {(notification?.type === 'FOLLOW_REQUEST' && (userData?._id === notification?.author)) && (
                    <div
                      key={notification?._id}
                      id={`notification-${notification?._id}`}
                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer ${notification?.readbyauthor ? 'bg-white border' : 'bg-blue-50'}`}
                      onClick={() => handleNotificationClickByAuthor(notification)}
                    >
                      <img
                        className="w-10 h-10 rounded-full mr-4"
                        src={notification?.userdata?.hero_img ||'/profile.png'}
                        alt="Profile"
                      />
                      <div className="flex-1">
                            <p className="text-sm text-gray-700">
                              <Link to={`/profile/${notification?.userdata?._id}`} 
                                className="font-semibold text-blue-600 hover:underline capitalize"
                              >
                                {notification?.userdata?.name }
                              </Link>
                              {" "} {notification?.message}.
                              <Link to={`/community/${notification?.communitydata?.community_handle}`} 
                                className="ml-1 font-semibold text-blue-600 hover:underline"
                              >
                                {notification?.communitydata?.title}
                              </Link>
                            </p>
                        <p className="text-xs text-gray-500">
                          {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                        </p> 
                      </div>
                    </div>
                    )}
                    {(notification?.type === 'ACCEPT_FOLLOW_REQUEST' && (userData?._id === notification?.userdata?._id)) && (
                    <div
                      key={notification?._id}
                      id={`notification-${notification?._id}`}
                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer ${notification?.readbyauthor ? 'bg-white border' : 'bg-blue-50'}`}
                      onClick={() => handleNotificationClickByAuthor(notification)}
                    >
                      <img
                        className="w-10 h-10 rounded-full mr-4"
                        src={notification?.authordata?.hero_img ||'/profile.png'}
                        alt="Profile"
                      />
                      <div className="flex-1">
                            <p className="text-sm text-gray-700">
                              <Link to={`/profile/${notification?.authordata?._id}`} 
                                className="font-semibold text-blue-600 hover:underline capitalize"
                              >
                                {notification?.authordata?.name }
                              </Link>
                              {" "} accepted your follow request.
                             
                            </p>
                        <p className="text-xs text-gray-500">
                          {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                        </p> 
                      </div>
                    </div>
                    )}
                    {(notification?.type === 'POST_CREATE' && (userData?._id === notification?.author)) && (
                      <div
                      key={notification?._id}
                      id={`notification-${notification?._id}`}
                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer ${notification?.readbyauthor ? 'bg-white border' : 'bg-blue-50'}`}
                      onClick={() => handleNotificationClickByAuthor(notification)}
                    >
                      <img
                        className="w-10 h-10 rounded-full mr-4"
                        src={notification?.userdata?.hero_img ||'/profile.png'}
                        alt="Profile"
                      />
                      <div className="flex-1">
                            <p className="text-sm text-gray-700">
                            {notification?.userdata?._id === userData?._id ? (<>
                              You have posted
                            </>) : (<>
                              <Link to={`/profile/${notification?.userdata?._id}`} 
                                className="font-semibold text-blue-600 hover:underline capitalize"
                              >
                                {notification?.userdata?.name }
                              </Link>
                              {" "}has posted
                            </>)}
                              
                              <Link to={`/community/${notification?.communitydata?.community_handle}/posts/${notification?.post?._id}`} 
                                className="ml-1 font-semibold text-blue-600 hover:underline"
                              >
                                {notification?.post?.title?.endsWith('.') ? notification?.post?.title.slice(0, -1) : notification?.post?.title}
                              </Link>
                              {" "} in the community
                              <Link to={`/community/${notification?.communitydata?.community_handle}`} 
                                className="ml-1 font-semibold text-blue-600 hover:underline"
                              >
                                {notification?.communitydata?.title}.
                              </Link>
                            </p>
                        <p className="text-xs text-gray-500">
                          {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                        </p> 
                      </div>
                    </div>
                    )}
                    {(notification?.type === 'LIKE' && (userData?._id === notification?.author)) && (
                      <div
                      key={notification?._id}
                      id={`notification-${notification?._id}`}
                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer ${notification?.readbyauthor ? 'bg-white border' : 'bg-blue-50'}`}
                      onClick={() => handleNotificationClickByAuthor(notification)}
                    >
                      <img
                        className="w-10 h-10 rounded-full mr-4"
                        src={notification?.userdata?.hero_img ||'/profile.png'}
                        alt="Profile"
                      />
                      <div className="flex-1">
                            <p className="text-sm text-gray-700">
                                {notification?.userdata?._id === userData?._id ? 'You liked the post' : (<>
                                  <Link to={`/profile/${notification?.userdata?._id}`} 
                                    className="font-semibold text-blue-600 hover:underline capitalize"
                                  >
                                  {notification?.userdata?.name} 
                                  </Link>
                                  {" "} liked your post
                                </>
                                ) }
                              <Link to={`/community/${notification?.communitydata?.community_handle}/posts/${notification?.post?._id}`} 
                                className="ml-1 font-semibold text-blue-600 hover:underline"
                              >
                                {notification?.post?.title?.endsWith('.') ? notification?.post?.title.slice(0, -1) : notification?.post?.title.trim()}.
                              </Link>
                            </p>
                        <p className="text-xs text-gray-500">
                          {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                        </p> 
                      </div>
                    </div>
                    )}
                    {(notification?.type === 'COMMENT' && (userData?._id === notification?.author)) && (
                      <div
                      key={notification?._id}
                      id={`notification-${notification?._id}`}
                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer ${notification?.readbyauthor ? 'bg-white border' : 'bg-blue-50'}`}
                      onClick={() => handleNotificationClickByAuthor(notification)}
                    >
                      <img
                        className="w-10 h-10 rounded-full mr-4"
                        src={notification?.userdata?.hero_img ||'/profile.png'}
                        alt="Profile"
                      />
                      <div className="flex-1">
                            <p className="text-sm text-gray-700">
                            {notification?.userdata?._id === userData?._id ? 'You have commented on post' : (<>
                              <Link to={`/profile/${notification?.userdata?._id}`} 
                                className="font-semibold text-blue-600 hover:underline capitalize"
                              >
                                {notification?.userdata?.name }
                              </Link>
                              {" "}has commented on your post
                            </>)}
                               
                              <Link to={`/community/${notification?.communitydata?.community_handle}/posts/${notification?.post?._id}`} 
                                className="ml-1 font-semibold text-blue-600 hover:underline"
                              >
                                {notification?.post?.title}
                              </Link>
                            </p>
                        <p className="text-xs text-gray-500">
                          {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                        </p> 
                      </div>
                    </div>
                    )}
                    {(notification?.type === 'REPLY_COMMENT' && (userData?._id === notification?.author)) && (
                      <div
                      key={notification?._id}
                      id={`notification-${notification?._id}`}
                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer ${notification?.readbyauthor ? 'bg-white border' : 'bg-blue-50'}`}
                      onClick={() => handleNotificationClickByAuthor(notification)}
                    >
                      <img
                        className="w-10 h-10 rounded-full mr-4"
                        src={notification?.userdata?.hero_img ||'/profile.png'}
                        alt="Profile"
                      />
                      <div className="flex-1">
                            <p className="text-sm text-gray-700">
                            {notification?.userdata?._id === userData?._id ? 'You have replied to a comment on the post ' : (<>
                              <Link to={`/profile/${notification?.userdata?._id}`} 
                                className="font-semibold text-blue-600 hover:underline capitalize"
                              >
                                {notification?.userdata?.name }
                              </Link>
                              {" "}has replied to your comment on the post
                            </>)}
                               
                              <Link to={`/community/${notification?.communitydata?.community_handle}/posts/${notification?.post?._id}`} 
                                className="ml-1 font-semibold text-blue-600 hover:underline"
                              >
                                {notification?.post?.title}
                              </Link>
                            </p>
                        <p className="text-xs text-gray-500">
                          {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                        </p> 
                      </div>
                    </div>
                    )}
                    {(notification?.type === 'INVITE_COMMUNITY' && (userData?._id === notification?.userdata?._id)) && (
                    <div
                      key={notification?._id}
                      id={`notification-${notification?._id}`}
                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer ${notification?.readbyuser ? 'bg-white border' : 'bg-blue-50'}`}
                      onClick={() => handleNotificationClickByUser(notification)}
                    >
                      <img
                        className="w-10 h-10 rounded-full mr-4"
                        src={notification?.authordata?.hero_img ||'/profile.png'}
                        alt="Profile"
                      />
                      <div className="flex-1">
                            <p className="text-sm text-gray-700">
                              <Link to={`/profile/${notification?.authordata?._id}`} 
                                className="font-semibold text-blue-600 hover:underline capitalize"
                              >
                                {notification?.authordata?.name }
                              </Link>
                              {" "} has invited you to join the community
                              <Link to={`/community/${notification?.communitydata?.community_handle}`} 
                                className="ml-1 font-semibold text-blue-600 hover:underline"
                              >
                                {notification?.communitydata?.title}.
                              </Link>
                            </p>
                        <p className="text-xs text-gray-500">
                          {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                        </p> 
                      </div>
                    </div>
                    )}
                  </>
                    :""
                )):""}
                {buynowquery?
                 profileData.map((notification, index) => (
                  notification.type?"":
                  <div
                  key={index}
                  className={` p-3 rounded-lg hover:bg-gray-100  ${notification.isRead ? '' : 'bg-blue-50'}`}
                >
                <p><span className="capitalize">{notification.name}</span> wants to buy {notification.content}. . Please connect with him/her using email: {notification.customer_email} or phone number: {notification.customer_phone}</p>
                 <p className="text-xs text-gray-500">{new Date(notification.createdAt).toLocaleString()}</p>
                </div>
                 )):""}
              </div>
            </div>
            ):""}
          </div>
         
        </div>
        {openNotification ? (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 h-full overflow-y-scroll">
            <div className="w-full sm:w-9/12  p-4 absolute top-0">
              <div className={`bg-white p-8 rounded shadow w-full m-auto my-4 update_Profile ease-in-out duration-700 transition-all ${openNotification ? 'slideBottom' : ''}`}>
                <div className="flex justify-between relative">
                  <h2 className="text-2xl font-semibold mb-4">
                    Notification
                  </h2>
                  <button
                    onClick={() => setOpenNotification(false)}
                    className="text-xl font-semibold border border-1 px-2 rounded-lg py-0 hover:bg-slate-200 absolute right-0 top-0"
                  >
                    X
                  </button>
                </div>
                <div className="w-full m-auto">
                  <div className="flex items-center justify-between">
                    <div className="flex" dangerouslySetInnerHTML={{ __html: notificationContent }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
    </div>
  );
};

export default UserProfilefeed;

import React, { useState } from "react";
import axios from "axios";
import { apiurl } from "../config/config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { setCookie } from "../config/coockies";
import { Link } from "react-router-dom";
import Layout from "../components/layout";
import { AiOutlineUser, AiOutlineLock } from "react-icons/ai";
import LoaderSpiner from "../components/loader";

const Login = () => {
  const location = useLocation();
  const { email } = location.state || {};
  const Navigate = useNavigate();
  const [handle, setHandle] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const initialerror = {
    email: false,
    password: false,
  };
  const [error, setError] = useState(initialerror);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const errorHandler = (e) => {
    if (e.target.name === "email") {
      const emailPattern = /\S+@\S+\.\S+/;
      if (!emailPattern.test(e.target.value)) {
        setError({ ...error, [e.target.name]: true });
      } else {
        setError({ ...error, [e.target.name]: "valid" });
      }
    } else {
      if (e.target.value) {
        setError({ ...error, [e.target.name]: "valid" });
      } else {
        setError({ ...error, [e.target.name]: true });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (formData.email !== "" && formData.password !== "") {
        // console.log({ email: formData.email, password: formData.password });
        const response = await axios({
          url: `${apiurl}/user/login`,
          method: "POST",
          data: { email: formData.email, password: formData.password },
          headers: {
            "Content-Type": "application/json",
          },
        });
        // console.log("login response >>>>>>", response);
        if (response.data.success === true) {
          // localStorage.setItem("token", response.data.token);
          const loginToken = response?.data?.token;
          //const expirationTime = 50 * 60;
          setCookie("savage_token", loginToken);
          setLoading(false);
          toast.success(response.data.message);
          localStorage.setItem("profilePromptShown", "false");
          Navigate("/communities");
        } else if (response.data.emailVerify === false) {
          Navigate("/reverifyemail");
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      } else {
        Object.keys(formData).forEach((fieldname) => {
          if (formData[fieldname].trim() === "") {
            setError((error) => ({
              ...error,
              [fieldname]: true,
            }));
          }
        });
        setLoading(false);
        console.error("Login failed");
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    <Layout>
      {loading === true ? <LoaderSpiner text="Loading..." /> : " "}{" "}
      <div className="flex flex-col  justify-center items-center login-screen min-h-[84vh] pb-7">
        
        <div className="relative  lg:w-[70%] w-[100%]  h-full max-w-[100%]  lg:flex block ">
          <div className="lg:w-[50%] w-full  lg:block hidden bg-[#005AE6] rounded-tl-[20px] rounded-bl-[20px]">
            <div className="h-full w-[70%] m-auto">
              <h1 className="flex items-center lg:text-[40px] text-[24px] justify-center lg:h-[180px] font-bold text-[#fff]">
                Start Your Savage Journey Today
              </h1>
              <img
                src="/assets/sign-bg.png"
                className="max-w-[85%] flex items-cent m-auto"
                alt="login_banner"
              />
            </div>
          </div>
          <div className="lg:w-[50%] w-11/12 bg-[#fff] rounded-md lg:ml-[-10px] bottomleftbg my-0 mx-auto">
            <div className="toprightbg pt-12  rounded-md ">
              <p className="text-center text-[20px] font-[500] mb-3">
                <img
                  src="/assets/savageseller-logo.jpg"
                  alt="logo"
                  className="w-[50%] h-auto rounded-full mx-auto mb-4"
                />
                Log in to your account
              </p>
              {error.email !== "valid" && error.email == true ? (
                <p className="text-[#FF0000] w-[80%] m-auto block py-2 px-0  text-[14px]">
                  Email is mandatory with @*
                </p>
              ) : null}
              <div className="relative w-[80%] m-auto">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="w-full p-2 pl-10 border border-[#f0f0f0] mb-3 bg-[#EFF5FF] rounded"
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => errorHandler(e)}
                />
                <AiOutlineUser className="absolute left-3 top-5 transform -translate-y-1/2 text-gray-400  text-[20px]" />
              </div>
              {error.password !== "valijd" && error.password == true ? (
                <p className="text-[#FF0000] w-[80%] m-auto block py-2 px-0  text-[14px]">
                  Password is mandatory *
                </p>
              ) : null}
              <form onSubmit={handleSubmit}>
                <div className="relative w-[80%] m-auto">
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="w-full p-2 pl-10 border border-[#f0f0f0] mb-3 bg-[#EFF5FF] rounded"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorHandler(e)}
                  />
                  <AiOutlineLock className="absolute left-3 top-5 transform -translate-y-1/2 text-gray-400 text-[20px]" />
                </div>
                <div className="flex w-[80%] m-auto  justify-between align-center">
                  <div className="flex align-center">
                    <input
                      type="checkbox"
                      name="terms"
                      className="w-auto  block p-2 border border-[#f0f0f0]"
                    />
                    <span className="ml-2 sm:text-base text-sm">
                      Keep me logged in
                    </span>
                  </div>
                  <div className="underline text-[#005AE6] cursor-pointer">
                    <button className="sm:text-base text-sm">
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </button>
                  </div>
                </div>
              </form>
              <button
                className="rounded-md bg-[#005AE6] text-[#fff] text-[20px] font-[500] p-2 m-auto block mt-3 w-[80%]"
                onClick={handleSubmit}
              >
                Log In
              </button>
              <p className="text-center  font-[500] mt-3 p-3 block">
                Don’t have an account yet?{" "}
                <Link to="/register">
                  <span className="underline text-[#005AE6] cursor-pointer">
                    Create account
                  </span>
                </Link>
                {email ? (
                  <p className="text-center block mt-2">
                    <Link to="/reverifyemail">
                      <span className="underline text-[#005AE6] cursor-pointer">
                        Resend E-mail Verification
                      </span>
                    </Link>
                  </p>
                ) : null}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;

import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { NewGroupCallContext } from "../contexts/NewGroupCall";
import { AiOutlineClose } from "react-icons/ai";
import { VscCallIncoming } from "react-icons/vsc";
import { SlCallEnd } from "react-icons/sl";
import { IoCallOutline } from "react-icons/io5";
import incoming_call from "./incoming_call.mp3";

export default function IncomingCallNotify() {
  const { isIncomingGroupCall, Calls, joinCall, DeclineGroupCall } =
    useContext(NewGroupCallContext);
  const audioRef = useRef();

  const [userInteracted, setUserInteracted] = useState(false);

  // Effect to handle playing the audio once the user interacts and a call is incoming
  useEffect(() => {
    if (isIncomingGroupCall) {
      audioRef.current.play().catch((error) => {
        console.error("Audio playback failed:", error);
      });
    } else if (audioRef.current) {
      audioRef.current.pause();
    }
    if (isIncomingGroupCall) {
      const timeoutId = setTimeout(() => {
        DeclineGroupCall();
      }, 60000);

      return () => clearTimeout(timeoutId);
    }
  }, [isIncomingGroupCall, userInteracted]);

  // Detect user interaction with the document
  useEffect(() => {
    const handleInteraction = () => {
      setUserInteracted(true); // Set to true once user interacts
      // Remove event listeners after the first interaction
      document.removeEventListener("click", handleInteraction);
      document.removeEventListener("keydown", handleInteraction);
    };

    // Listen for any user interaction on the page (click or key press)
    document.addEventListener("click", handleInteraction);
    document.addEventListener("keydown", handleInteraction);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("click", handleInteraction);
      document.removeEventListener("keydown", handleInteraction);
    };
  }, [isIncomingGroupCall]);

  return (
    <Fragment>
      {isIncomingGroupCall ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#00000061]">
          <div className="bg-white absolute h-48 md:w-[30%] w-[90%] rounded-md px-5 py-2 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
            <div className="flex justify-between items-center">
              <h1 className="font-bold text-2xl">Incoming {Calls?.calltype} call</h1>
              <AiOutlineClose
                fontSize={"25px"}
                className="text-gray-900 cursor-pointer"
                onClick={() => DeclineGroupCall()}
              />
            </div>
            <div className="user_info py-5 flex items-center space-x-3">
              <p className="text-xl">
                {Calls?.name || "Someone"} is calling you...
              </p>
              <VscCallIncoming className="text-3xl " />
            </div>
            <div className="answer_decline flex justify-between items-center pt-5">
              <button
                onClick={() => DeclineGroupCall()}
                type="button"
                className="px-6 py-1.5 rounded-md outline-none focus:border focus:border-red-700 text-white focus:text-black bg-red-800 focus:bg-transparent cursor-pointer flex items-center space-x-2"
              >
                <SlCallEnd className="text-xl" />
                <span>Decline</span>
              </button>
              <button
                onClick={() => joinCall()}
                type="button"
                className="px-6 py-1.5 rounded-md outline-none focus:border focus:border-green-700 text-white focus:text-black bg-green-800 focus:bg-transparent cursor-pointer flex items-center space-x-2"
              >
                <IoCallOutline className="text-xl" />
                <span>Answer</span>
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <audio ref={audioRef} loop src={incoming_call} />
    </Fragment>
  );
}

export const GetTimeforchat = (time) => {
    const currentTime = new Date();
    const commentTime = new Date(time);
    const timeDifference = Math.abs(currentTime.getTime() - commentTime.getTime());

    const minuteDifference = Math.floor(timeDifference / (1000 * 60));
    const isSameDay = currentTime.toDateString() === commentTime.toDateString();
    
    const formatTime = (date) => {
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    const formatDateTime = (date) => {
        return `${date.toLocaleDateString([], { day: '2-digit', month: 'short' })} at ${formatTime(date)}`;
    };

    if (minuteDifference < 60) {
        return `${formatTime(commentTime)}`;
    } else if (isSameDay) {
        return formatTime(commentTime);
    } else if (minuteDifference < 2880) {
        const yesterday = new Date(currentTime);
        yesterday.setDate(yesterday.getDate() - 1);
        if (commentTime.toDateString() === yesterday.toDateString()) {
            return `yesterday at ${formatTime(commentTime)}`;
        }
    } else {
        return formatDateTime(commentTime); // Return date and time if not today or yesterday
    }
};

// Usage examples:
// console.log(GetTimeAgo(new Date())); // "current time"
// console.log(GetTimeAgo(new Date(new Date().setDate(new Date().getDate() - 1)))); // "yesterday at time"
// console.log(GetTimeAgo(new Date(new Date().setFullYear(2023, 7, 23, 14, 30)))); // "23 Aug at 02:30 PM"

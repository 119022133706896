import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import global from "global";
import * as process from "process";
import { socket } from "../config/socket";
import { useUser } from "./UserProvider";
import adapter from "webrtc-adapter";

global.process = process;

export const NewGroupCallContext = createContext();

export default function NewGroupCall({ children }) {
  const { userData } = useUser();
  const [localStream, setLocalStream] = useState(null);
  const [isGroupCalling, setIsGroupCalling] = useState(false);
  const [isIncomingGroupCall, setisIncomingGroupCall] = useState(false);
  const [Calls, setCalls] = useState({});
  const MyGroupVideoRef = useRef();
  const [allRefs, setAllRefs] = useState({});
  const [LocalMicStatus, setLocalMicStatus] = useState(true);
  const [LocalVideoStatus, setLocalVideoStatus] = useState(true);
  const [pc, setPc] = useState([]);
  var screen = "";

  const userMediaAvailable = () => {
    return !!(
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia
    );
  };

  const openMediaDevices = async ({ video, audio }) => {
    if (userMediaAvailable() === true) {
      return await navigator.mediaDevices.getUserMedia({
        video: video,
        audio:
          audio === true
            ? {
                echoCancellation: true,
                noiseSuppression: true,
              }
            : false,
      });
    } else {
      throw new Error("User media not available");
      // return null;
    }
  };

  const groupCallInit = useCallback(
    async (data, video) => {
      setIsGroupCalling(true);
      try {
        const socketId = socket.io.engine.id;
        const stream = await openMediaDevices({ video: video, audio: true });
        setLocalStream(stream);
        MyGroupVideoRef.current.srcObject = stream;
        const allUsers = data.members.map((user) => {
          return {
            user: user._id,
            name: user.name,
          };
        });
        const notifyUsers = allUsers.filter(
          (member) => member.user !== userData?._id
        );
        socket.emit("subscribe", {
          room: data.room,
          allmembers: allUsers,
          notifyUsers: notifyUsers,
          socketId: socketId,
          name: data.name,
          calltype: video ? "video" : "audio",
        });
        setCalls({ name: data.name, room: data.room, calltype: video ? "video" : "audio" });
      } catch (error) {
        console.log("error calling user: ", error);
      }
    },
    [socket, setLocalStream, setIsGroupCalling, userData, setCalls]
  );

  const joinCall = useCallback(async () => {
    const room = Calls.room,
      socketId = socket.io.engine.id;
    setisIncomingGroupCall(false);
    socket.emit("joincall", {
      room: room,
      socketId: socketId,
      calltype: Calls.calltype,
    });
    setIsGroupCalling(true);
  }, [Calls, socket, setisIncomingGroupCall, setIsGroupCalling]);

  const DeclineGroupCall = useCallback(async () => {
    window.location.reload();
    setisIncomingGroupCall(false);
  }, [setisIncomingGroupCall]);

  useEffect(() => {
    socket.on("incoming_group_call", (data) => {
      setisIncomingGroupCall(true);
      setCalls({ name: data.from, room: data.room, calltype: data.calltype });
    });

    return () => {
      socket.off("incoming_group_call");
    };
  }, [socket]);

  useEffect(() => {
    socket.on("new user", (data) => {
      const socketId = socket.io.engine.id;
      socket.emit("newUserStart", {
        to: data.socketId,
        sender: socketId,
        calltype: data.calltype,
      });
      // pc.push(data.socketId);
      setPc((prevPc) => {
        const updatedPc = [...prevPc, data.socketId];
        return updatedPc; // Return the new updated array
      });
      init(true, data.socketId, socketId, data.calltype);
    });

    return () => {
      socket.off("new user");
    };
  }, [socket]);

  useEffect(() => {
    socket.on("newUserStart", (data) => {
      const socketId = socket.io.engine.id;
      pc.push(data.sender);
      init(false, data.sender, socketId, data.calltype);
    });

    return () => {
      socket.off("newUserStart");
    };
  }, [socket]);

  useEffect(() => {
    socket.on("ice candidates", async (data) => {
      if (data.candidate) {
        await pc[data.sender].addIceCandidate(
          new RTCIceCandidate(data.candidate)
        );
      }
    });
  }, [socket]);

  useEffect(() => {
    socket.on("sdp", async (data) => {
      const socketId = socket.io.engine.id;
      // console.log("sdp", data);
      if (data.description.type === "offer") {
        if (data.description) {
          await pc[data.sender].setRemoteDescription(
            new RTCSessionDescription(data.description)
          );
        }

        openMediaDevices({
          video: data.calltype === "video" ? true : false,
          audio: true,
        })
          .then(async (stream) => {
            MyGroupVideoRef.current.srcObject = stream;
            setLocalStream(stream);

            stream.getTracks().forEach((track) => {
              pc[data.sender].addTrack(track, stream);
            });

            let answer = await pc[data.sender].createAnswer();

            await pc[data.sender].setLocalDescription(answer);

            socket.emit("sdp", {
              description: pc[data.sender].localDescription,
              to: data.sender,
              sender: socketId,
            });
          })
          .catch((e) => {
            console.error(e);
          });
      } else if (data.description.type === "answer") {
        await pc[data.sender].setRemoteDescription(
          new RTCSessionDescription(data.description)
        );
      }
    });

    return () => {
      socket.off("sdp");
    };
  }, [socket]);

  const init = (createOffer, partnerName, socketId, video = "audio") => {
    pc[partnerName] = new RTCPeerConnection(getIceServer());

    if (screen && screen.getTracks().length) {
      screen.getTracks().forEach((track) => {
        pc[partnerName].addTrack(track, screen); //should trigger negotiationneeded event
      });
    } else if (localStream) {
      localStream.getTracks().forEach((track) => {
        pc[partnerName].addTrack(track, localStream); //should trigger negotiationneeded event
      });
    } else {
      openMediaDevices({ video: video === "video" ? true : false, audio: true })
        .then((stream) => {
          setLocalStream(stream);
          stream.getTracks().forEach((track) => {
            pc[partnerName].addTrack(track, stream); //should trigger negotiationneeded event
          });
          MyGroupVideoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error(`stream error: ${error}`);
        });
    }

    //create offer
    if (createOffer) {
      pc[partnerName].onnegotiationneeded = async () => {
        let offer = await pc[partnerName].createOffer();
        await pc[partnerName].setLocalDescription(offer);
        socket.emit("sdp", {
          description: pc[partnerName].localDescription,
          to: partnerName,
          sender: socketId,
          calltype: video,
        });
      };
    }

    //send ice candidate to partnerNames
    pc[partnerName].onicecandidate = ({ candidate }) => {
      socket.emit("ice candidates", {
        candidate: candidate,
        to: partnerName,
        sender: socketId,
      });
    };

    //add
    pc[partnerName].ontrack = (e) => {
      let str = e.streams[0];
      setAllRefs((prev) => {
        return { ...prev, [partnerName]: str };
      });
    };

    pc[partnerName].onconnectionstatechange = (d) => {
      switch (pc[partnerName].iceConnectionState) {
        case "disconnected":
        case "failed":
          closeVideo(partnerName);
          break;
        case "closed":
          closeVideo(partnerName);
          break;
      }
    };

    pc[partnerName].onsignalingstatechange = (d) => {
      switch (pc[partnerName].signalingState) {
        case "closed":
          // console.log("Signalling state is 'closed'");
          closeVideo(partnerName);
          break;
      }
    };
  };

  const closeVideo = (elemId) => {
    // console.log("elemId", elemId);
    setAllRefs((prev) => {
      const { [elemId]: _, ...newRefs } = prev;
      return newRefs;
    });
  };

  const getIceServer = () => {
    // return {
    //   iceServers: [
    //     {
    //       urls: ["stun:eu-turn4.xirsys.com"],
    //     },
    //     {
    //       username:
    //         "ml0jh0qMKZKd9P_9C0UIBY2G0nSQMCFBUXGlk6IXDJf8G2uiCymg9WwbEJTMwVeiAAAAAF2__hNSaW5vbGVl",
    //       credential: "4dd454a6-feee-11e9-b185-6adcafebbb45",
    //       urls: [
    //         "turn:eu-turn4.xirsys.com:80?transport=udp",
    //         "turn:eu-turn4.xirsys.com:3478?transport=tcp",
    //       ],
    //     },
    //   ],
    // };
    return {
      iceServers: [
        {
          urls: "stun:stun.relay.metered.ca:80",
        },
        {
          urls: "turn:global.relay.metered.ca:80",
          username: "6415b097b0d47cad8905867a",
          credential: "qTzvBnjxHDgevzan",
        },
        {
          urls: "turn:global.relay.metered.ca:80?transport=tcp",
          username: "6415b097b0d47cad8905867a",
          credential: "qTzvBnjxHDgevzan",
        },
        {
          urls: "turn:global.relay.metered.ca:443",
          username: "6415b097b0d47cad8905867a",
          credential: "qTzvBnjxHDgevzan",
        },
        {
          urls: "turns:global.relay.metered.ca:443?transport=tcp",
          username: "6415b097b0d47cad8905867a",
          credential: "qTzvBnjxHDgevzan",
        },
      ],
    };
  };

  const leaveCall = useCallback(async () => {
    window.location.reload();
  }, [socket, setIsGroupCalling, setisIncomingGroupCall, pc]);

  const broadcastNewTracks = (stream, type) => {
    // console.log("stream", stream);
    setLocalStream(stream);
    let track =
      type === "audio"
        ? stream.getAudioTracks()[0]
        : stream.getVideoTracks()[0];
    for (let p in pc) {
      let pName = pc[p];
      // console.log("pName", pName);
      if (typeof pc[pName] == "object") {
        replaceTrack(track, pc[pName]);
      }
    }
  };

  const toogleMic = useCallback(() => {
    if (localStream.getAudioTracks()[0].enabled) {
      localStream.getAudioTracks()[0].enabled = false;
      setLocalMicStatus(false);
    } else {
      localStream.getAudioTracks()[0].enabled = true;
      setLocalMicStatus(true);
    }
    broadcastNewTracks(localStream, "audio");
  }, [localStream, setLocalStream, setLocalMicStatus]);

  const toogleVideo = useCallback(() => {
    if (localStream.getVideoTracks()[0].enabled) {
      localStream.getVideoTracks()[0].enabled = false;
      setLocalVideoStatus(false);
    } else {
      localStream.getVideoTracks()[0].enabled = true;
      setLocalVideoStatus(true);
    }
    broadcastNewTracks(localStream, "video");
  }, [localStream, setLocalStream, setLocalVideoStatus]);

  function replaceTrack(stream, recipientPeer) {
    let sender = recipientPeer.getSenders
      ? recipientPeer
          .getSenders()
          .find((s) => s.track && s.track.kind === stream.kind)
      : false;
    if (sender) sender.replaceTrack(stream);
  }

  return (
    <NewGroupCallContext.Provider
      value={{
        localStream,
        isGroupCalling,
        MyGroupVideoRef,
        isIncomingGroupCall,
        Calls,
        pc,
        allRefs,
        LocalMicStatus,
        LocalVideoStatus,
        userMediaAvailable,
        openMediaDevices,
        groupCallInit,
        joinCall,
        DeclineGroupCall,
        leaveCall,
        toogleMic,
        toogleVideo,
      }}
    >
      {children}
    </NewGroupCallContext.Provider>
  );
}

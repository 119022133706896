import React from "react";

const AboutusCard = () => {
  return (
    <div>
      <div className="relative w-[100%] h-[200px] lg:h-[200px] full  m-auto bg-cover bg-no-repeat bg-center  bg-[#302b27] bg-[url('/public/assets/discussion.jpg')]">
        <h1 className="flex  items-center text-[40px] justify-center h-[200px] lg:h-[200px] font-bold text-[#fff]">
          About Us
        </h1>
      </div>
    </div>
  );
};

export default AboutusCard;

import axios from "axios";
import React, { useState } from "react";
import { apiurl,marketplaceapiurl } from "../config/config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderSpiner from "../components/loader";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [verifyotp, setVerifyotp] = useState(false);
  const [sendotp, setSendotp] = useState(true);
  const [changepassword, setChangepassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState(false);
  const [showNewpassword, setShowNewPassword] = useState(false);
  const [showConfirmpassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  const handleSubmitotp = async (e) => {
    e.preventDefault();
    setloading(true);
    if (email !== "") {
      try {
        const data = {
          email: email,
        };
        const response = await axios.post(
          `${apiurl}/user/forgot-password`,
          data
        );
        // console.log("response otp is >>>>>>>>>>>>>", response);
        if (response.data.success === true) {
          setloading(false);
          // setEmail('');
          toast.success("OTP sent on your mail", {
            autoClose: 3000,
          });
          setVerifyotp(true);
          setSendotp(false);
        } else if (response.data.success === false) {
          setloading(false);
          toast.error("User not found", {
            autoClose: 3000,
          });
        } else {
          setloading(false);
          toast.error('User not found"', {
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error("OTP response error", error);
        setloading(false);
        toast.error("Failed to send OTP", {
          autoClose: 3000,
        });
      }
    } else {
      toast.error("Please fill in the email");
      setloading(false);
    }
  };

  const handleVerifyotp = async (e) => {
    e.preventDefault();
    setloading(true);
    try {
      const data = {
        email: email,
        otp: parseInt(otp),
      };
      // console.log("verifying data is >>>>>>>", data);
      const response = await axios.post(`${apiurl}/user/verifyotp`, data);
      // console.log("verify otp is >>>>>>>>>>>>>>>>", response);
      if (response.data.success === true) {
        setloading(false);
        toast.success("Verified OTP", {
          autoClose: 3000,
        });
        setVerifyotp(false);
        setSendotp(false);
        setChangepassword(true);
      } else {
        toast.error("Failed to change the password", {
          autoClose: 3000,
        });
        setloading(false);
      }
    } catch (error) {
      console.error("Verify OTP response error", error);
      setloading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setloading(true);
    if (newpassword !== "" && confirmpassword !== "") {
      try {
        const data = {
          email: email,
          newPassword: newpassword,
        };
        // console.log("set password data >>>>>>", data);
        const response = await axios.post(
          `${apiurl}/user/reset-password`,
          data
        );
        // console.log("new set password is >>>>>>>>", response);

        if (response.data.success === true) {
          setloading(false);
          
          await axios.post(`${marketplaceapiurl}/user/reset-password`, {
            email: email,
          password: newpassword, // Hardcoded password for multivendor API, adjust if needed
          });
          toast.success("Password changed successfully", {
            autoClose: 3000,
          });
          navigate("/login");
        } else {
          toast.error("Failed to change the password", {
            autoClose: 3000,
          });
          setloading(false);
        }
      } catch (error) {
        toast.error("Failed to change the password", {
          autoClose: 3000,
        });
        setloading(false);
        console.error("set new password error", error);
      }
    } else {
      toast.error("fill the password", {
        autoClose: 3000,
      });
      setloading(false);
    }
  };

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading..." /> : null}
      <div className="flex justify-center items-center h-screen bg-[#E0E9F7] p-4">
        {sendotp === true ? (
          <form
            className="mt-4 space-y-4 lg:mt-5 md:space-y-5 w-full p-6 bg-white rounded-lg shadow-lg dark:border md:mt-0 sm:max-w-md sm:p-8"
            onSubmit={handleSubmitotp}
          >
            <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-[#005AE6] md:text-2xl text-center">
              Send OTP
            </h2>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Your email
              </label>
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                className="bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="w-full text-white hover:text-[#005AE6] border border-[#005AE6] hover:bg-white bg-[#005AE6] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Send OTP
            </button>
          </form>
        ) : null}

        {verifyotp ? (
          <form
            className="mt-4 space-y-4 lg:mt-5 md:space-y-5 w-full p-6 bg-white rounded-lg shadow-lg dark:border md:mt-0 sm:max-w-md sm:p-8"
            onSubmit={handleVerifyotp}
          >
            <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-[#005AE6] md:text-2xl text-center">
              Verify OTP
            </h2>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Enter 6 Digit OTP
              </label>
              <input
                type="text"
                name="otp"
                placeholder="OTP"
                className="bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                maxLength={6}
              />
            </div>
            <button
              type="submit"
              className="w-full text-white hover:text-[#005AE6] border border-[#005AE6] hover:bg-white bg-[#005AE6] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              OTP Verified
            </button>
          </form>
        ) : null}

        {changepassword ? (
          <form
            className="mt-4 space-y-4 lg:mt-5 md:space-y-5 w-full p-6 bg-white rounded-lg shadow-lg dark:border md:mt-0 sm:max-w-md sm:p-8"
            onSubmit={handleResetPassword}
          >
            <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-[#005AE6] md:text-2xl dark:text-[#005AE6] text-center">
              Reset with new password
            </h2>
            <div className="relative">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                New Password
              </label>
              <input
                type={showNewpassword ? "text" : "password"}
                name="password"
                id="password"
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required=""
                value={newpassword}
                onChange={(e) => setNewpassword(e.target.value)}
              />
              <div
                className="absolute top-10 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => setShowNewPassword(!showNewpassword)}
              >
                {showNewpassword ? <IoEye /> : <IoEyeOff />}
              </div>
            </div>
            <div className="relative">
              <label
                htmlFor="confirm-password"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Confirm password
              </label>
              <input
                type={showConfirmpassword ? "text" : "password"}
                name="password"
                id="password"
                placeholder="••••••••"
                className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  newpassword !== confirmpassword ? "border-red-500" : ""
                }`}
                required=""
                value={confirmpassword}
                onChange={(e) => setConfirmpassword(e.target.value)}
              />
              <div
                className="absolute top-10 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmpassword)}
              >
                {showConfirmpassword ? <IoEye /> : <IoEyeOff />}
              </div>
              {newpassword !== confirmpassword && (
                <p className="text-red-500 text-sm mt-1">
                  Passwords do not match.
                </p>
              )}
            </div>
            <button
              type="submit"
              className="w-full text-white hover:text-[#005AE6] border bg-[#005AE6] border-[#005AE6] hover:bg-white font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              disabled={newpassword !== confirmpassword ? true : false}
            >
              Change Password
            </button>
          </form>
        ) : null}
      </div>
    </>
  );
};

export default ForgotPassword;

import React from "react";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }) => {
  return (
    <div className="layoutContainer flex flex-col h-screen relative">
      <Header />
      <div className="relative">
        <main className="">{children}</main>
      </div>
      <Footer className="mt-auto" />
    </div>
  );
};

export default Layout;

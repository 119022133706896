import React, { useState } from "react";
import { FaCalendar } from "react-icons/fa";

const Discussionoverview = () => {
  const postData = [
    {
      title: "Discussion Edition",
      topic: "How to use the padding in the external css file",
      date: "23 Jan 2024",
      content:
        "In CSS, the padding property is used to set the padding space around the content inside an element. It can be applied to various HTML elements such as divs, paragraphs, headers, etc. The padding property can take different values to specify the padding for each side (top, right, bottom, left) individually or for all sides at once.",
    },
    {
      title: "Discussion Edition",
      topic: "How to use the padding in the external css file",
      date: "23 Jan 2024",
      content:
        "In CSS, the padding property is used to set the padding space around the content inside an element. It can be applied to various HTML elements such as divs, paragraphs, headers, etc. The padding property can take different values to specify the padding for each side (top, right, bottom, left) individually or for all sides at once.",
    },
    {
      title: "Discussion Edition",
      topic: "How to use the padding in the external css file",
      date: "23 Jan 2024",
      content:
        "In CSS, the padding property is used to set the padding space around the content inside an element. It can be applied to various HTML elements such as divs, paragraphs, headers, etc. The padding property can take different values to specify the padding for each side (top, right, bottom, left) individually or for all sides at once.",
    },
  ];
  const [posts, setPosts] = useState(postData);
  return (
    <div>
      <div className="relative w-[100%] h-[200px] lg:h-[200px] full  m-auto bg-cover bg-no-repeat bg-center  bg-[#302b27] bg-[url('/public/assets/discussion.jpg')]">
        <h1 className="flex  items-center text-[40px] justify-center h-[200px] lg:h-[200px] font-bold text-[#fff]">
          Discussion Edition
        </h1>
      </div>
      <div className="w-full lg:w-full flex flex-col p-3 sm:flex">
        {posts.map((item, index) => (
          <div
            className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col mt-2 p-5"
            key={index}
          >
            <div className="p-4 flex flex-row">
              <img
                src="/assets/savageseller-logo.jpg"
                alt="savage-logo"
                className="w-10 h-10  rounded-[100%] border-blue border-[5px] cursor-pointer"
              />
              <div className="lg:w-full relative pl-2">
                <h2 className="text-[16px] lg:text-[16px] font-[500] flex items-center  cursor-pointer">
                  {item.title}
                </h2>
                <h2 className="text-[20px] lg:text-[20px] font-bold flex items-center  cursor-pointer">
                  {item.topic}
                </h2>
                <p className="font-[500] text-[14px] text-[#9e9e9e] ">
                  {item.content}
                </p>
                <p className="font-[500] text-[14px] flex items-center ">
                  <FaCalendar />
                  {item.date}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Discussionoverview;

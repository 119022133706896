export const getCroppedImg = (imageSrc, pixelCrop, rotation = 0,format ='image/jpeg') => {
    const image = new Image();
    image.src = imageSrc;
    
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    const maxSize = Math.max(image.width, image.height);
    const safeArea = maxSize * 2;
  
    canvas.width = safeArea;
    canvas.height = safeArea;
  
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.translate(-safeArea / 2, -safeArea / 2);
  
    ctx.drawImage(image, safeArea / 2 - image.width / 2, safeArea / 2 - image.height / 2);
  
    const data = ctx.getImageData(0, 0, safeArea, safeArea);
  
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
  
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width / 2 - pixelCrop.x,
      0 - safeArea / 2 + image.height / 2 - pixelCrop.y
    );
  
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(file);
      }, format);
    });
  };
  
import React from "react";
import { FaHeart } from "react-icons/fa";
import { MdOutlineRequestPage } from "react-icons/md";
function Notification({ setIsNotification }) {
  return (
    <div className="fixed top-24 right-0 w-96 bg-white rounded-lg shadow-lg h-[70vw] z-40 p-3 border">
      <div className="flex justify-between items-center border-b pb-3">
        <p
          tabIndex="0"
          className="focus:outline-none text-xl font-semibold leading-6 text-gray-800 pr-4"
        >
          Notifications
        </p>

        <button
          className="border-none hover:text-black focus:outline-none focus:ring "
          onClick={() => setIsNotification(false)}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#4B5563"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#4B5563"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      <div className="bg-gray-100 pt-4 overflow-y-auto h-screen p-3">
        <div className="w-full p-3 bg-white rounded flex border">
          <div
            tabIndex="0"
            aria-label="heart icon"
            role="img"
            className="focus:outline-none w-8 h-8 border rounded-full border-gray-200 flex items-center justify-center"
          >
            <FaHeart className="text-[#f00000]"/>
          </div>
          <div className="pl-3">
            <p tabIndex="0" className="focus:outline-none text-sm leading-none">
              <span className="text-indigo-700">James Doe</span> favourited an{" "}
              <span className="text-indigo-700">item</span>
            </p>
            <p
              tabIndex="0"
              className="focus:outline-none text-xs leading-3 pt-1 text-gray-500"
            >
              2 hours ago
            </p>
          </div>
        </div>
        <div className="w-full p-3 mt-4 bg-white rounded shadow flex flex-shrink-0">
          <div
            tabIndex="0"
            aria-label="group icon"
            role="img"
            className="focus:outline-none w-8 h-8 border rounded-full border-gray-200 flex flex-shrink-0 items-center justify-center"
          >
            <MdOutlineRequestPage />
          </div>
          <div className="pl-3 w-full">
            <div className="flex items-center justify-between w-full">
              <p
                tabIndex="0"
                className="focus:outline-none text-sm leading-none"
              >
                <span className="text-indigo-700">Sash</span> added you to the
                group: <span className="text-indigo-700">UX Designers</span>
              </p>
              <div
                tabIndex="0"
                aria-label="close icon"
                role="button"
                className="focus:outline-none cursor-pointer"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 3.5L3.5 10.5"
                    stroke="#4B5563"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.5 3.5L10.5 10.5"
                    stroke="#4B5563"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <p
              tabIndex="0"
              className="focus:outline-none text-xs leading-3 pt-1 text-gray-500"
            >
              2 hours ago
            </p>
          </div>
        </div>
        <div className="w-full p-3 mt-4 bg-white rounded flex">
          <div
            tabIndex="0"
            aria-label="post icon"
            role="img"
            className="focus:outline-none w-8 h-8 border rounded-full border-gray-200 flex items-center justify-center"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.30325 12.6667L1.33325 15V2.66667C1.33325 2.48986 1.40349 2.32029 1.52851 2.19526C1.65354 2.07024 1.82311 2 1.99992 2H13.9999C14.1767 2 14.3463 2.07024 14.4713 2.19526C14.5963 2.32029 14.6666 2.48986 14.6666 2.66667V12C14.6666 12.1768 14.5963 12.3464 14.4713 12.4714C14.3463 12.5964 14.1767 12.6667 13.9999 12.6667H4.30325ZM5.33325 6.66667V8H10.6666V6.66667H5.33325Z"
                fill="#4338CA"
              />
            </svg>
          </div>
          <div className="pl-3">
            <p tabIndex="0" className="focus:outline-none text-sm leading-none">
              <span className="text-indigo-700">Sarah</span> posted in the
              thread: <span className="text-indigo-700">Update gone wrong</span>
            </p>
            <p
              tabIndex="0"
              className="focus:outline-none text-xs leading-3 pt-1 text-gray-500"
            >
              2 hours ago
            </p>
          </div>
        </div>
       
       
      
      </div>
    </div>
  );
}

export default Notification;

export const GetTimeAgo = (time) => {
  const currentTime = new Date();
  const commentTime = new Date(time);
  const timeDifference = Math.abs(
    currentTime.getTime() - commentTime.getTime()
  );

  const minuteDifference = Math.floor(timeDifference / (1000 * 60));

  if (minuteDifference < 60) {
    return `${minuteDifference} min ago`;
  } else if (minuteDifference < 1440) {
    const hours = Math.floor(minuteDifference / 60);
    return `${hours} ${hours > 1 ? "hours" : "hour"} ago`;
  } else if (minuteDifference < 525600) {
    const days = Math.floor(minuteDifference / 1440);
    return `${days} ${days > 1 ? "days" : "day"} ago`;
  } else {
    const years = Math.floor(minuteDifference / 525600);
    return `${years} ${years > 1 ? "years" : "year"} ago`;
  }
};

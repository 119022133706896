import React from "react";
import Header from "../components/header";
import GroupCard from "../components/groupCard";

const Group = () => {
  return (
    <div>
      <Header />
      <GroupCard />
    </div>
  );
};

export default Group;

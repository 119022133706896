import React from "react";
import { Link, useNavigate } from "react-router-dom";

const TopBanner = (props) => {
  const navigate = useNavigate();
  // console.log(props);
  return props.name === "homepage" ? (
    <div className="p-5">
      <div className="relative w-[100%] bg-cover bg-no-repeat bg-center  bg-[#005AE6]  sm:h-[250px] h-44 rounded-md homepageleft ">
        <div className="homepageright w-full  flex flex-col items-center justify-center  h-full ">
          <h1 className="lg:text-[48px] text-[20px] font-[700] text-[#fff]">
          GROUP HUB HOME PAGE
          </h1>
          <p className="lg:text-[18px] text-[14px] text-[#fff]">
            Connect, Discuss, Create and Browse Communities
          </p>
          <div className="flex mt-5 ">
            <div
              className="bg-transparent text-white py-2 px-3 rounded-md transition duration-300  font-semibold text-sm lg:text-lg border border-[#fff] cursor-pointer"
              onClick={() => navigate("/create-community")}
            >
              Create a Community
            </div>
            <Link
              to="/communities"
              className="bg-[#fff] text-[#000] py-2 px-5 rounded-md ml-2 transition duration-300 font-semibold text-sm lg:text-lg"
            >
            Join Communities
            </Link>
          </div>
        </div>
      </div>
      <section className="bg-gray-100 py-8 text-center mt-5">
        <div className="container mx-auto">
          <h2 className="text-2xl font-semibold mb-4">Community Visibility</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 px-5">
            <div className="bg-[#e1e1e1] p-4 rounded-md shadow-md">
              <h3 className="text-xl font-semibold mb-2 capitalize">
                Public Community
              </h3>
              <p className="text-gray-600">
                Engage freely! Post, comment, like, and share without joining or
                approval. Open to everyone.
              </p>
            </div>
            <div className="bg-[#e1e1e1] p-4 rounded-md shadow-md ">
              <h3 className="text-xl font-semibold mb-2 capitalize">
                Private Community
              </h3>
              <p className="text-gray-600">
                Join first, then get admin approval to participate. Post,
                comment, like, and share after approval. Exclusive interactions.
              </p>
            </div>
            <div className="bg-[#e1e1e1] p-4 rounded-md shadow-md">
              <h3 className="text-xl font-semibold mb-2 capitalize">
                Protected Community
              </h3>
              <p className="text-gray-600">
                Receive a password via email to join. Enter the password and get
                admin approval to participate. Post, comment, like, and share
                after approval. Highly secure and exclusive.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  ) : (
    <div className="relative w-[100%] bg-cover bg-no-repeat bg-center  bg-[#005AE6]  sm:h-[250px] h-44 rounded-md homepageleft ">
      <h1 className="homepageright w-full  flex flex-col items-center lg:text-[48px] text-[20px] text-[#fff] justify-center  h-full font-bold">
        {props.text}
      </h1>
    </div>
  );
};

export default TopBanner;

import React, { createContext, useState, useRef, useEffect } from "react";
import { socket } from "../config/socket";
import Peer from "simple-peer";
import axios from "axios";
import { apiurl } from "../config/config";
import { useUser } from "./UserProvider";

export const GroupCallContext = createContext();

export default function GroupCallContextProvider({ children }) {
  const { userData } = useUser();
  const [localStream, setLocalStream] = useState(null);
  const [remoteStreams, setRemoteStreams] = useState({});
  const [isGroupCalling, setIsGroupCalling] = useState(false);
  const [peerConnections, setPeerConnections] = useState({});
  const [isIncomingGroupCall, setIsIncomingGroupCall] = useState(false);
  const [groupcallAccepted, setgroupcallAccepted] = useState(false);
  const [groupCall, setGroupCall] = useState(null);
  const remoteVideoRefs = useRef({});
  const myVideoRef = useRef();
  const [myVideoStatus, setMyVideoStatus] = useState(true);
  const [myMicStatus, setMyMicStatus] = useState(true);
  const [roomId, setRoomId] = useState("");
  const [peer, setpeer] = useState(null);
  const [RemoteVideoStatus, setRemoteVideoStatus] = useState();
  const [RemoteMicStatus, setRemoteMicStatus] = useState();

  const openMediaDevices = async (constraints) => {
    // console.log("Opening media devices with constraints: ", constraints);
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      // console.log("Local stream obtained: ", stream);
      return stream;
    } catch (err) {
      console.error("Error accessing media devices.", err);
      throw err;
    }
  };

  const handleGroupCallOffer = ({ offer, from }) => {
    // console.log("Received group call offer from: ", from, " with offer: ", offer);
    if (peerConnections[from]) {
        console.warn(`Peer connection already exists for user ${from}`);
        return;
    }

    const peer = new Peer({
        initiator: false,
        trickle: false,
        stream: localStream,
    });

    peer.on("signal", (signal) => {
        // console.log("Emitting group call answer for user: ", from, " with signal: ", signal);
        socket.emit("group-call-answer", { roomId, answer: signal, from: userData._id, to: from });
    });

    peer.on("stream", (stream) => {
        // console.log(`Received remote stream from user: ${from}`);
        if (!remoteVideoRefs.current[from]) {
            remoteVideoRefs.current[from] = document.createElement("video");
            remoteVideoRefs.current[from].autoplay = true;
            remoteVideoRefs.current[from].playsInline = true;
            document.body.appendChild(remoteVideoRefs.current[from]); // Append to DOM
        }
        remoteVideoRefs.current[from].srcObject = stream;
        setRemoteStreams((prevStreams) => ({ ...prevStreams, [from]: stream }));
    });

    peer.on('error', err => console.error(`Peer error for ${from}`, err));

    peer.on('signal', data => {
        // console.log(`Signal state for peer ${from}: `, peer._pc.signalingState);
        if (peer._pc.signalingState === 'have-remote-offer' || peer._pc.signalingState === 'have-local-offer') {
            socket.emit('group-call-answer', { roomId, answer: data, from: userData._id, to: from });
        }
    });

    // console.log(`Handling peer connection signaling state: ${peer._pc.signalingState}`);
    peer.signal(offer);

    setPeerConnections((prevPeers) => ({ ...prevPeers, [from]: peer }));
  };

  const joinGroupCall = async (getIds) => {
    // console.log("Attempting to join group call with user IDs: ", getIds);
    setIsGroupCalling(true);
    try {
        const stream = await openMediaDevices({ video: true, audio: true });
        setLocalStream(stream);
        if (myVideoRef.current) {
            myVideoRef.current.srcObject = stream;
        }

        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream: stream,
        });

        const notify = getIds.filter((id) => id !== userData?._id);
        // console.log("Filtered notify list (excluding self): ", notify);

        const response = await axios.get(`${apiurl}/generate-room-id`);
        const newRoomId = response.data.roomId;
        // console.log("Generated new roomId: ", newRoomId);
        
        setRoomId(newRoomId);

        socket.emit("join", newRoomId);
        // console.log("Socket emit join: ", newRoomId);

        peer.on("signal", (data) => {
            // console.log("Sending peer signal for group call: ", data);
            if (peer._pc.signalingState === 'stable' || peer._pc.signalingState === 'have-local-offer') {
                socket.emit("join-group-call", {
                    signalData: data,
                    roomId: newRoomId,
                    userId: userData._id,
                    notify: notify,
                    name: userData?.name,
                    type: "both"
                });
            } else {
                console.warn(`Invalid signaling state: ${peer._pc.signalingState}`);
            }
        });

        peer.on("stream", (currentStream) => {
            // console.log("Received current stream for local user: ", currentStream);
            const peerId = userData?._id;
            // console.log("peerId", peerId);
            
            if (!remoteVideoRefs.current[peerId]) {
                remoteVideoRefs.current[peerId] = document.createElement("video");
                remoteVideoRefs.current[peerId].autoplay = true;
                remoteVideoRefs.current[peerId].playsInline = true;
                document.body.appendChild(remoteVideoRefs.current[peerId]);
            }
            remoteVideoRefs.current[peerId].srcObject = currentStream;
            setRemoteStreams((prevStreams) => ({ ...prevStreams, [peerId]: currentStream }));
        });

        socket.on("group-call-answered", (data) => {
            // console.log("Group call answered: ", data);
            setgroupcallAccepted(true);
            setGroupCall({ to: data.from, from: data.to });
            peer.signal(data.signal);
        });

        setpeer(peer);
    } catch (error) {
        console.error("Failed to get media stream:", error);
        setIsGroupCalling(false);
    }
  };

  const leaveGroupCall = () => {
    // console.log("Leaving group call.");
    socket.emit("leave-group-call", { roomId, userId: userData._id });
    setIsGroupCalling(false);
    setIsIncomingGroupCall(false);
    setGroupCall(null);
    // console.log("Destroying peer connections.");
    Object.values(peerConnections).forEach((peer) => peer.destroy());
    setPeerConnections({});
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
    }
    setLocalStream(null);
  };

  const handleUserJoined = ({ userId }) => {
    // console.log(`User ${userId} joined the group call.`);
    if (peerConnections[userId]) {
      console.warn(`Peer connection already exists for user ${userId}`);
      return;
    }

    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream: localStream,
    });
  
    peer.on("signal", (signal) => {
      // console.log("Sending group call offer to user: ", userId, " with signal: ", signal);
      socket.emit("group-call-offer", { roomId, offer: signal, from: userData._id, to: userId });
    });
  
    peer.on("stream", (stream) => {
      // console.log(`Received remote stream from user: ${userId}`);
      if (!remoteVideoRefs.current[userId]) {
        remoteVideoRefs.current[userId] = document.createElement("video");
        remoteVideoRefs.current[userId].autoplay = true;
        remoteVideoRefs.current[userId].playsInline = true;
        document.body.appendChild(remoteVideoRefs.current[userId]);
      }
      remoteVideoRefs.current[userId].srcObject = stream;
      setRemoteStreams((prevStreams) => ({ ...prevStreams, [userId]: stream }));
    });
  
    peer.on('error', err => console.error(`Peer error for ${userId}`, err));
  
    setPeerConnections((prevPeers) => ({ ...prevPeers, [userId]: peer }));
  };

  const handleUserLeft = ({ userId }) => {
    // console.log(`User ${userId} left the group call`);
    if (peerConnections[userId]) {
      peerConnections[userId].destroy();
      setPeerConnections((prevPeers) => {
        const { [userId]: _, ...rest } = prevPeers;
        return rest;
      });
      setRemoteStreams((prevStreams) => {
        const { [userId]: _, ...rest } = prevStreams;
        return rest;
      });
    }
  };

  const handleGroupCallAnswer = () => {
    // console.log("Handling group call answer: ", groupCall);
  };

  const handleGroupCallIceCandidate = ({ candidate, from }) => {
    // console.log("Received ICE candidate from: ", from, " candidate: ", candidate);
    const peer = peerConnections[from];
    if (peer) {
      peer.signal(candidate);
    }
  };

  const AnswerGroupCall = async () => {
    // console.log("Answering group call for: ", groupCall);
    setIsGroupCalling(true);
    try {
      const stream = await openMediaDevices({ video: true, audio: true });
      setLocalStream(stream);
      myVideoRef.current.srcObject = stream;
      const peer = new Peer({
        initiator: false,
        trickle: false,
        stream: stream,
      });

      peer.on("signal", (data) => {
        // console.log("Emitting group call answer with signal: ", data);
        socket.emit("group-call-answer", {
          signal: data,
          to: groupCall.from,
          type: "both",
          from: userData?._id,
        });
      });

      peer.on("stream", (currentStream) => {
        // console.log("Received remote stream after answering call: ", currentStream);
      });

      peer.signal(groupCall.signal);

      setPeerConnections((prevPeers) => ({ ...prevPeers, [groupCall.from]: peer }));
      setgroupcallAccepted(true);
      setIsIncomingGroupCall(false);
    } catch (error) {
      console.error("Failed to get media stream:", error);
    }
  };

  const callDrop = () => {
    // console.log("Dropping group call and stopping all tracks.");
    if (peerConnections) {
      Object.values(peerConnections).forEach((peer) => {
        const streams = peer.streams || [];
        streams.forEach((stream) => {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        });
        peer.destroy();
      });
      setIsGroupCalling(false);
      setIsIncomingGroupCall(false);
      socket.emit("leave-group-call", { roomId, userId: userData._id });
      setPeerConnections({});
      setRemoteStreams({});
      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
      }
      setLocalStream(null);
      window.location.reload();
    }
  };

  useEffect(() => {
    socket.on("user-joined", handleUserJoined);
    socket.on("group-call-offer", handleGroupCallOffer);
    return () => {
      socket.off("user-joined", handleUserJoined);
      socket.off("group-call-offer", handleGroupCallOffer);
    };
  }, [localStream, peerConnections, socket]);

  useEffect(() => {
    socket.on("user-joined", (data) => {
      // console.log("User joined: ", data);
      data.notify.forEach((user) => {
        // console.log("Emitting group-call-offer for user: ", user);
        socket.emit("group-call-offer", {
          from: data.roomId,
          offer: user,
          signal: data.signal,
          name: data.name,
          notify: data.notify,
        });
      });
    });

    socket.on("updateRemoteMedia", ({ type, currentMediaStatus }) => {
      // console.log(`Remote media status update: type = ${type}, status = ${currentMediaStatus}`);
      if (currentMediaStatus !== null || currentMediaStatus) {
        switch (type) {
          case "video":
            setRemoteVideoStatus(currentMediaStatus);
            break;
          case "mic":
            setRemoteMicStatus(currentMediaStatus);
            break;
          default:
            setRemoteMicStatus(currentMediaStatus[0]);
            setRemoteVideoStatus(currentMediaStatus[1]);
            break;
        }
      }
    });
  }, [socket, peer]);

  useEffect(() => {
    socket.on("group-call-offered", (data) => {
      // console.log("Received group-call-offered event: ", data);
      setGroupCall(data);
      setIsIncomingGroupCall(true);
      setIsGroupCalling(true);
      const allUsers = { [Object.keys(data.notify).length]: data.from, ...data.notify };
      // console.log("All users in the call: ", allUsers);
    });
  }, [socket, peer]);

  return (
    <GroupCallContext.Provider
      value={{
        localStream,
        remoteStreams,
        isGroupCalling,
        myVideoRef,
        remoteVideoRefs,
        isIncomingGroupCall,
        joinGroupCall,
        leaveGroupCall,
        AnswerGroupCall,
        groupCall,
        myMicStatus,
        myVideoStatus,
        updateAudio: () => setMyMicStatus((prev) => !prev),
        updateVideo: () => setMyVideoStatus((prev) => !prev),
        callDrop,
        handleGroupCallAnswer,
        groupcallAccepted,
        RemoteVideoStatus,
        RemoteMicStatus,
      }}
    >
      {children}
    </GroupCallContext.Provider>
  );
}

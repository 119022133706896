import React from "react";

export const config1 = () => {
  return <div>config 1</div>;
};

export const config2 = () => {
  return <div>config 2</div>;
};

// export const apiurl = "http://54.176.124.238"
// export const apiurl = "https://savageseller-community-engine.onrender.com"
// export const apiurl = "http://localhost:7171";
export const apiurl = "https://community-api.savageseller.com";
export const marketplaceapiurl =
  "https://marketplace-api.savageseller.com"; /*-------prod------*/
//export const apiurl = "http://localhost:7171"
export const awsKeyId = process.env.REACT_APP_AWSKEYID;
export const awsSecretKey = process.env.REACT_APP_AWSSECRETKEY;

import { Link, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { apiurl ,marketplaceapiurl} from "../config/config";
import axios from "axios";
import Layout from "../components/layout";
import { RxCross1 } from "react-icons/rx";
import { toast } from "react-toastify";
import { IoArrowBackSharp } from "react-icons/io5";
import LoaderSpiner from "../components/loader";
import { getCookie } from "../config/coockies";
import { useUser } from "../contexts/UserProvider";
import GetPosts from "../components/getPosts";
import CreatePost from "../components/createPost";
import { CiEdit, CiBookmarkRemove } from "react-icons/ci";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MdVerified } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
const MySwal = withReactContent(Swal);

const Community = () => {
  const navigate = useNavigate();
  const token = getCookie("savage_token");
  const { userData } = useUser();
  const [inviteCommunity, setInviteCommunity] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginpopup, setLoginPopup] = useState(false);
  const [protectedCommunity, setProtectedCommunity] = useState(false);
  const [posts, setPosts] = useState([]);
  const [Community, setCommunity] = useState([]);
  const [isPriCommMember, setPriCommMember] = useState(false);
  const { communityHandle } = useParams();
  const [communitiesId, setCommunitiesID] = useState();
  const [communitiesAuthId, setCommunitiesAuthId] = useState();
  const [Protectedpassword, setProtectedPassword] = useState();
  const [allCommunities, setAllCommunities] = useState([]);
  const [searchPost, SetSearchPost] = useState("");
  const [searchPostData, setSearchPostData] = useState([]);
  const [getMember, setGetMember] = useState([]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const [vendorStatus, setVendorStatus] = useState({});
  const checkVendor = async (email) => {
    try {
      const { data } = await axios.post(
        `${marketplaceapiurl}/seller/checksellerexistance`,
        { email }
      );
      if(data?.data?.sellerType === "organization")
      {
        setVendorStatus(true)
      }
      else{
        setVendorStatus(false)
      }
    } catch (error) {
      console.error("Error checking vendor status:", error);
      return false;
    }
  };
  const fetchCommunities = () => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/community/handle/${communityHandle}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response.data.community) {
          // console.log("response__",response.data)
          setCommunity(response.data.community);
          setCommunitiesID(response.data?.community._id);
          setCommunitiesAuthId(response.data?.community?.author?._id);
          checkVendor(response.data?.community?.author?.useremail)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchCommunities();
  }, [communityHandle]);

  const handleProtected = async () => {
    try {
      setLoading(true);
      const data = {
        password: Protectedpassword,
      };
      const response = await axios.post(
        `${apiurl}/community/protected-handle/${communityHandle}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setLoading(false);
        setProtectedCommunity(false);
        toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("protected community error", error);
    }
  };

  const handleInviteCommunity = async () => {
    if (inviteEmail !== "") {
      setLoading(true);
      try {
        const data = {
          email: inviteEmail,
          username: Community.author.username,
          communityId: Community._id,
        };
        // console.log("user invite submit data >>>>", data);
        const response = await axios.post(
          `${apiurl}/community/send-invite`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        // console.log("invite community response >>>>>", response);
        if (response.data.success === true) {
          setLoading(false);
          setInviteCommunity(false);
          toast.success("invite community mail sent successfully");
          setInviteEmail("");
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        console.error("invite our community error");
        const errorMessage =
          error.response?.data?.message ||
          "Failed to invite community. Please try again.";
        toast.error(errorMessage);
        setLoading(false);
      }
    } else {
      toast.error("Please provide an email address.");
      setLoading(false);
    }
  };

  const GetAllCommunities = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/community/get`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setAllCommunities(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    GetAllCommunities();
  }, []);

  const [likes, setLikes] = useState(0);
  const [getPostData, setGetPostData] = useState([]);
  const [communityAuthId, setCommunityAuthId] = useState();
  const [comments, setComments] = useState(0);
  const [shares, setShares] = useState(0);

  const handleLike = () => {
    setLikes((prevLikes) => prevLikes + 1);
  };

  const handleComment = () => {
    setComments((prevComments) => prevComments + 1);
  };

  const handleShare = () => {
    setShares((prevShares) => prevShares + 1);
  };

  const isUser =
    Community && Community.author && Community.author._id === userData._id;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoginPopup(true);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const isProtected = Community.communityType === "protected";
    setProtectedCommunity(isProtected);
    setLoginPopup(false);
  }, [Community.communityType]);

  const handleJoinCommunity = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiurl}/community/send-join-request`,
        {
          userId: userData._id,
          communityId: Community._id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response?.data?.success === true) {
        setLoading(false);
        fetchCommunities();
        toast.success(response?.data?.message);
      } else {
        setLoading(false);
        toast.error(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Join Community error", error);
    }
  };
  const handleJoinCommunityNotification = async () => {
    setLoading(true);
    const data = {
        userId: {
            _id: userData?._id,
        },
        type:  (Community?.communityType === "private" || Community?.communityType === "protected") ? "JOIN_COMMUNITY_REQUEST" : "JOIN_COMMUNITY",
        message: (Community?.communityType === "private" || Community?.communityType === "protected") ? `requested to join the community` : "joined the community!" ,
        community: {
            _id: Community._id
        },
        author: {
            _id: Community?.author?._id
        }
    }
    try {
      const response = await axios.post(
        `${apiurl}/notifications/createNotification`, data
        ,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response?.data?.success === true) {
        setLoading(false);
        fetchCommunities();
        // toast.success(response?.data?.message);
      } else {
        setLoading(false);
        // toast.error(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Join Community Notification error", error);
    }
  };
  const handleRemoveCommunityNotification = async () => {
    setLoading(true);
    const data = {
        userId: {
            _id: userData?._id,
        },
        type:  "LEAVE_COMMUNITY",
        community: {
            _id: Community._id
        },
        author: {
            _id: Community?.author?._id
        }
    }
    try {
      const response = await axios.delete(
        `${apiurl}/notifications/deleteNotification`
        ,
        {
          data: data,
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response?.data?.success === true) {
        setLoading(false);
        fetchCommunities();
        // toast.success(response?.data?.message);
      } else {
        setLoading(false);
        // toast.error(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Join Community Notification error", error);
    }
  };

  const isMember = Community.members?.some(
    (member) => member._id === userData._id && member.status === "approved"
  );

  useEffect(() => {
    const isRequestJoinMember = Community.members?.some(
      (member) => member._id === userData._id && member.status === "pending"
    );
    setPriCommMember(isRequestJoinMember);
  }, [Community, userData]);

  const handleRemoveCommunity = async () => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${apiurl}/community/remove-community/${Community._id}/member/${userData._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        fetchCommunities();
        setLoading(false);
        // setIsMember(false);
        toast.success(response?.data?.message);
      } else {
        setLoading(false);
        toast.error(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("remove-community", error);
    }
  };

  const fetchposts = () => {
    setLoading(true);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/post/${communityHandle}/posts`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setCommunityAuthId(response.data?.creator);

        if (Array.isArray(response.data?.posts)) {
          const reversedPosts = response.data?.posts.reverse();
          setGetPostData(reversedPosts);
          setSearchPostData(reversedPosts);
        } else {
          console.error("Response data is not an array");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("error", error);
      });
  };


  const handlePostSearch = () => {
    const query = searchPost.toLowerCase();
    const filteredPosts = searchPostData.filter(
      (item) =>
        item.title.toLowerCase().includes(query) ||
        item.createdby.username.toLowerCase().includes(query)
    );
    setGetPostData(filteredPosts);
  };

  useEffect(() => {
    handlePostSearch();
  }, [searchPost]);

  const deleteCommunityPop = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteCommunity(id);
        MySwal.fire({
          title: "Deleted!",
          text: "Your Community has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const deleteCommunity = async (id) => {
    try {
      const response = await axios.delete(
        `${apiurl}/community/delete-community/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      navigate("/communities");
      fetchCommunities();
    } catch (error) {
      console.error("Error searching communities:", error);
    }
  };

  useEffect(() => {
    fetchposts();
  }, [Community]);

  const handleGetMemberList = async () => {
    try {
      const response = await axios.get(
        `${apiurl}/user/getAllCommunityRequest/${communitiesId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setGetMember(response.data.data || []);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    handleGetMemberList();
  }, [communitiesId]);

  const maxAboutLength = Math.max(...allCommunities.map(item => item.about.length));
  const dynamicHeight = maxAboutLength > 78 ? "sm:h-28" : maxAboutLength > 38 ? "sm:h-24" : "";  

  return (
    <Layout>
      {loading === true ? <LoaderSpiner text="Loading..." /> : null}
      {protectedCommunity && !isUser && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-40">
          <div className="relative bg-white sm:px-8 px-5 py-12 rounded-lg shadow-lg sm:w-1/2 w-11/12">
          <button onClick={()=>setProtectedCommunity(false)} className="absolute top-5 right-5"><IoMdCloseCircle  className="h-8 w-8"/></button>  
          <h2 className="sm:text-4xl text-2xl font-semibold my-4 text-center text-[#005AE6]">
              Protected Community
            </h2>
            <p className="text-gray-700 mb-4 text-center sm:text-lg text-sm">
              This community is protected. Please log in or sign up to access
              it.
            </p>
            <div className="sm:w-1/2 w-11/12 m-auto pb-5">
              <h2 className="mb-3 text-xl font-semibold leading-tight text-[#005AE6] text-center">
                Community Password
              </h2>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Enter Password
                </label>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={Protectedpassword}
                  onChange={(e) => setProtectedPassword(e.target.value)}
                  className="bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-4"
                />
              </div>
              <button
                type="submit"
                className="w-full text-white hover:text-[#005AE6] border border-[#005AE6] hover:bg-white bg-[#005AE6] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={handleProtected}
              >
                Verify Password
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="bg-[#eeeeee]">
        {/* ----------IMAGE-SECTION----------------- */}
        <div
          className="py-3 relative z-20 sm:z-0 h-24 border-[#eee] border sm:border-x-0 bg-cover bg-no-repeat bg-center w-full sm:h-[400px] rounded-xl mb-4 mt-4 sm:mt-0"
          style={{
            backgroundImage: Community.cover_img
              ? `url(${Community.cover_img})`
              : "url(/assets/communityBanner.png)",
          }}
        ></div>

        <div className="flex-col flex sm:flex-row justify-between w-full m-auto px-5 pl-0 sm:pl-0 lg:pl-10 xl:pl-14">
          {/* --------------LEFT-SECTION----------- */}
          <div className="w-full sm:w-[30%] lg:pl-0 pl-5 sm:pl-6 mt-[-2rem] sm:mt-[-3rem] lg:mt-[-4rem]">
            <div className="bg-white rounded-xl py-2 pb-8 px-4 mb-5  relative z-30">
              <div className="flex justify-end sm:mt-4 sm:mr-4 absolute top-14 right-5 sm:top-4 sm:right-4">
                {Community.communityType &&
                Community.communityType === "public" ? (
                  <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10">
                    Public
                  </span>
                ) : Community.communityType === "private" ? (
                  <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500/10">
                    Private
                  </span>
                ) : Community.communityType === "protected" ? (
                  <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-500/10">
                    Protected
                  </span>
                ) : null}
              </div>
              <button
                className="absolute sm:top-5 sm:left-4 top-16 flex items-center ml-2 text-green-600 border border-green-600 px-2 py-1 rounded-lg sm:px-5 sm:py-2 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform"
                onClick={handleGoBack}
              >
                <IoArrowBackSharp className="mr-2" /> Back
              </button>
              <img
                src={
                   Community.hero_img
                    
                }
                className="sm:mx-auto sm:mt-12 sm:w-40 sm:h-40 w-24 h-24 relative z-20 bottom-12 sm:bottom-0 rounded-full border-[5px] border-[#e3e3e3]"
                alt="community"
              />
              <div className="flex items-center justify-center 6 sm:mt-3 mt-0">
                <h2 className="font-bold text-xl">{Community.title}</h2>
                
                {vendorStatus?<MdVerified  className="text-blue-600 mr-2 ms-1 text-3xl"/>:""}
              </div>
              <div className="flex items-center my-1 justify-center ">
                <div className="flex items-center">
                  {Community?.members?.length > 0 && 
                    Community.members
                      .filter((member) => member.status === "approved") 
                      .slice(0, 3) 
                      .map((member, idx) => (
                      <img
                        key={member._id}
                        src={member.hero_img || (idx === 0 ? '/assets/member1.jpg' : idx === 1 ? '/assets/member2.jpg' : '/assets/member3.jpg')}
                        alt={`${member.username}'s profile`}
                        className={`w-8 h-8 rounded-full border-[#f0f0f0] border-[2px] ${idx > 0 ? 'ml-[-10px]' : ''}`}
                      />
                  ))}
                </div>
                {Community?.members?.filter((member) => member.status === "approved").length > 0 && (
                  <p className="cursor-pointer ml-2">
                    {Community.members.filter((member) => member.status === "approved").length}
                    <Link
                      to={`/community/${communityHandle}/users/${communitiesId}/${communitiesAuthId}`}
                      className="ml-3 text-blue-700"
                      test={Community.members.filter((member) => member.status === "approved").length}
                    >
                      {Community.members.filter((member) => member.status === "approved").length > 1 ? 'View All' : 'View'}
                    </Link>
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-2 sm:mt-5">
                {isUser ? (
                  <div className="flex flex-col">
                    <div className="flex flex-col items-center justify-center">
                      <div className="flex justify-center mb-2">
                        <CiEdit
                          onClick={() =>
                            navigate(
                              `/create-community?handle=${communityHandle}&cid=${Community._id}`
                            )
                          }
                          className="text-[#fff] bg-[#038703] border mr-1 border-gray-500 rounded-full p-2 h-10 w-10 hover:bg-blue-700 transition-all duration-300 hover:text-white cursor-pointer"
                        />
                        <CiBookmarkRemove
                          onClick={() => deleteCommunityPop(Community._id)}
                          className="text-[#fff] bg-[#ff0000]  rounded-full p-2 h-10 w-10 hover:bg-blue-700 transition-all duration-300 hover:text-white cursor-pointer"
                        />
                      </div>
                      <p className="text-center sm:text-lg text-sm  text-green-600 font-semibold border-2 border-green-600 mb-3 py-1 rounded-lg w-full">
                        You are the Admin of this Community
                      </p>
                    </div>
                    <div className="flex items-center justify-center w-full">
                      <Link
                      className="bg-[#005AE6] relative py-2 sm:text-lg text-sm rounded-lg text-white text-center mr-2 w-full"
                      to={`/community/${communityHandle}/requesttojoin/${communitiesId}/${communitiesAuthId}`}
                      >
                        All Request {getMember.length > 0 && `(${getMember.length})`}
                        {/* {getMember.length > 0 && (
                          <span class="absolute top-[-8px] right-[-8px] bg-red-600 text-white text-xs font-bold rounded-full h-6 w-6 flex items-center justify-center">{getMember.length}</span>
                        )} */}
                      </Link>
                      {isUser && (
                        <button
                          className="bg-[#005AE6] py-2 sm:text-lg text-sm rounded-lg text-white ml-2 w-full"
                          onClick={() => setInviteCommunity(!inviteCommunity)}
                        >
                          Invite
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {isMember === true ? (
                      <button
                        className="bg-red-200 border mb-2 py-1 rounded-lg border-red-800 text-lg text-red-800 font-semibold"
                        onClick={()=> {handleRemoveCommunity(); handleRemoveCommunityNotification();}}
                      >
                        Leave
                      </button>
                    ) : isPriCommMember === true ? (
                      <button className="bg-[#005AE61A] text-[#005AE6] border mb-2 text-[14px] py-1 rounded-lg border-[#005AE6]">
                        Requested
                      </button>
                    ) : (
                      <button
                        className="bg-[#005AE61A] text-[#005AE6] border mb-2 text-[14px] py-1 rounded-lg border-[#005AE6]"
                        onClick={()=> {handleJoinCommunity(); handleJoinCommunityNotification();}}
                      >
                        Join
                      </button>
                    )}
                    {isMember && (
                      <p className="text-center sm:text-lg text-sm text-green-600 font-semibold border-2 border-green-600 mb-3 py-1 rounded-lg">
                        You are the Member of this Community
                      </p>
                    )}
                  </>
                )}
                
              </div>
            </div>
            <div className="bg-white rounded-xl py-2 pb-8 px-6 mb-5 hidden sm:block">
              <h2 className="font-semibold text-xl my-2">Info</h2>
              <p className="leading-[30px]">{Community.about}</p>
            </div>
            {/* <div className="bg-white rounded-xl py-2 pb-8 px-6 mb-5 hidden sm:block">
              <h2 className="font-semibold text-xl my-2">Photos</h2>
              <div className="grid gap-2 grid-cols-3">
                <div>
                  <img src="/assets/communityPhotos_1.png" />
                </div>
                <div>
                  <img src="/assets/communityPhotos_2.png" />
                </div>
                <div>
                  <img src="/assets/communityPhotos_5.png" />
                </div>
                <div>
                  <img src="/assets/communityPhotos_4.png" />
                </div>
                <div>
                  <img src="/assets/communityPhotos_5.png" />
                </div>
                <div>
                  <img src="/assets/communityPhotos_6.png" />
                </div>
              </div>
            </div> */}
            <div className="bg-white rounded-xl py-2 pb-8 px-6 mb-5 hidden sm:block">
              <div className="flex items-center justify-between">
                <h2 className="font-semibold text-xl my-2">
                  Other Communities
                </h2>
                <Link to="/communities" className="text-[#005AE6]">
                  View All
                </Link>
              </div>
              <div className="flex overflow-x-auto custom-scroll">
                {allCommunities.map((item, index) => {
                  const isUser = item.author?._id === userData._id;
                  const approvedMembers = item?.members.filter( (member) => member.status === "approved" );
                  const memberCount = approvedMembers.length;
                  return (
                    <Link
                      to={`/community/${item.community_handle}`}
                      className="min-w-[280px] m-2"
                      key={index}
                    >
                      <div className="p-4 flex flex-col cursor-pointer bg-[#fff] rounded-md shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] lg:mb-0 mb-5">
                        <div className={`*:first-letter:flex justify-between mb-2 items-center bg-cover ${item?.cover_img ? 'border' : 'border-none'} border-[#eee] bg-center bg-no-repeat rounded-md h-[80px]`} style={{ backgroundImage: `url(${item?.cover_img ? item?.cover_img : '/assets/blue-bg.png'})` }}></div>
                        <div className="flex justify-between mb-2 items-center">
                          <img
                            src={item.hero_img}
                            alt="savage-logo"
                            className="w-[60px] h-[60px]  rounded-[100%] border-blue border-[5px] cursor-pointer mt-[-50px] ml-5"
                          />
                          {item.communityType === "public" ? (
                            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10">
                              Public
                            </span>
                          ) : item.communityType === "private" ? (
                            <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500/10">
                              Private
                            </span>
                          ) : item.communityType === "protected" ? (
                            <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-500/10">
                              Protected
                            </span>
                          ) : null}
                        </div>
                        <div className={`lg:w-full relative pl-2 border-b border-[#f0f0f0] pb-5 ${dynamicHeight}`}>
                          <h2 className="text-[16px] lg:text-[20px] font-bold  cursor-pointer">
                            {item.title}
                          </h2>
                          <p className="font-[400] text-[14px] text-[#000] line-clamp-3 ">
                            {item.about}
                          </p>
                        </div>
                        <div className={`lg:w-full relative ${item?.discloseauthorname === true ? 'justify-between' : 'justify-end'} items-center flex`}>
                          <div className={`lg:w-full relative pl-2 ${item?.discloseauthorname === true ? 'justify-between mt-4' : 'justify-end mt-5'} items-center flex ${memberCount > 0 ? 'pt-2' : 'pt-5'}`}>
                            <p className={`text-[rgb(0,0,0)] flex absolute py-2 left-2 ${item?.discloseauthorname === true ? '-top-3' : ''}`}>
                              {/* {isUser === true && (
                                <p>
                                  <CiEdit
                                    onClick={() =>
                                      navigate(
                                        `/create-community?handle=${item.community_handle}&cid=${item._id}`
                                      )
                                    }
                                    className="text-[#fff] bg-[#038703] border mr-1 border-gray-500 rounded-full p-1.5 h-8 w-8 hover:bg-blue-700 transition-all duration-300 hover:text-white cursor-pointer"
                                  />
                                  <CiBookmarkRemove
                                    onClick={() => deleteCommunityPop(item._id)}
                                    className="text-[#fff] bg-[#ff0000]  rounded-full p-1.5 h-8 w-8 hover:bg-blue-700 transition-all duration-300 hover:text-white cursor-pointer"
                                  />
                                </>
                              )} */}
                            </p>
                            {item?.discloseauthorname && (
                              <span className={`text-sm capitalize my-1 font-semibold mt-5 ${memberCount > 0 ? 'pt-3' : ''}`}>
                                {item?.author?.username}
                              </span>
                            )}

                            <div className="flex items-center">
                              {memberCount === 0 ? (
                                <span className="text-xs mt-5">Be the first to join</span>
                              ) : (
                                <div className="flex flex-col items-center justify-center">
                                  <div className="flex items-center ">
                                    {approvedMembers.slice(0, 3).map((member, idx) => (
                                      <img
                                        key={member._id}
                                        src={member.hero_img || (idx === 0 ? '/assets/member1.jpg' : idx === 1 ? '/assets/member2.jpg' : '/assets/member3.jpg')}
                                        alt={`${member.username}'s profile`}
                                        className={`w-8 h-8 rounded-full border-[#f0f0f0] border-[2px] ${idx > 0 ? 'ml-[-10px]' : ''}`}
                                      />
                                    ))}
                                  </div>
                                  <span className="mx-2 text-xs mt-1">
                                    {memberCount} {memberCount === 1 ? "Member" : "Members"}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
          {/* --------------right section----------------- */}
          {isUser || isMember || Community.communityType === "public" ? (
            <div className="w-full sm:w-[68%] sm:pl-0 pl-5">
              <CreatePost
                fetchposts={fetchposts}
                SetSearchPost={SetSearchPost}
                searchPost={searchPost}
                postCount={getPostData.length}
              />
              {(isUser || isMember || Community.communityType === "public") && (
                <GetPosts
                  isUser={isUser}
                  isMember={isMember}
                  Community={Community}
                  fetchposts={fetchposts}
                  posts={getPostData}
                  setPosts={setGetPostData}
                  communityAuthId={communityAuthId}
                />
              )}
            </div>
          ) : (
            <div className="my-4 w-full sm:w-[68%] pl-5 sm:pl-0">
              <div className="bg-white rounded-xl shadow-md h-64 flex justify-center items-center p-5">
                <p className="sm:text-2xl text-lg font-semibold mb-4 text-center">
                  {Community.communityType === "private"
                    ? "This is a private community. Please join first."
                    : "This is a protected community. Please join first."}
                </p>
              </div>
            </div>
          )}
        </div>
        {!isUser && !isMember && Community.communityType === "private" && (
          <div
            className={`z-40 flex items-center justify-center bg-[#E5EEFC] border-t border-blue-400 fixed bottom-0 w-full transition-all duration-1000 shadow-[0px_10px_1px_rgba(221,_221,_221,_1),_0_10px_20px_rgba(204,_204,_204,_1)] ${
              loginpopup
                ? "opacity-100 transform translate-y-0"
                : "opacity-0 transform translate-y-full transition-transform duration-300"
            }`}
          >
            <div className="p-4">
              <p className="sm:mt-3 sm:mb-4 mb-0 mt-0 text-sm sm:text-xl text-center font-semibold ">
                Join our private community by sending a request, once approved,
                invite friends for a secure, exclusive experience
              </p>
            </div>
          </div>
        )}
      </div>
      {inviteCommunity ? (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-30">
          <div className="w-full sm:w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 p-4">
            <div className="mt-4 space-y-2 lg:mt-5 md:space-y-5 p-6 bg-white rounded-lg shadow-2xl md:mt-0 sm:max-w-md sm:p-8 m-auto">
              <div className="flex justify-end text-xl">
                <button onClick={() => setInviteCommunity(false)}>
                  <RxCross1 />
                </button>
              </div>
              <h2 className="mt-0 text-xl font-bold leading-tight tracking-tight text-[#005AE6] md:text-2xl text-center">
                Invite our Community
              </h2>
              <label
                htmlFor="email"
                className="block mb-2 text-left font-medium text-gray-900"
              >
                E-mail :
              </label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                required
                value={inviteEmail}
                onChange={(e) => setInviteEmail(e.target.value)}
                className="border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 bg-[#EFF5FF] m-0"
              />
              <button
                type="button"
                className="w-full text-white hover:text-[#005AE6] border border-[#005AE6] hover:bg-white bg-[#005AE6] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={handleInviteCommunity}
              >
                Invite
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </Layout>
  );
};

export default Community;

import React, { useRef, useState, useEffect } from "react";
import { IoIosArrowForward, IoIosShareAlt } from "react-icons/io";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./header";
import { getCookie } from "../config/coockies";
import { Link, useParams } from "react-router-dom";
import { apiurl, marketplaceapiurl } from "../config/config";
import axios from "axios";
import LoaderSpiner from "./loader";
import { toast } from "react-toastify";

const Products = () => {
  const [liketoggle, setLiketoggle] = useState(false);
  const [vendorid, setVendorid] = useState(0);
  const [allproduct, setAllproduct] = useState([]);
  const { communityHandle } = useParams();
  const token = getCookie("savage_token");
  const marketplaceRef = useRef(null);
  const rentalsRef = useRef(null);
  const buyRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [getRentalProducts, setRentalGetProducts] = useState([]);
  const [likedProducts, setLikedProducts] = useState([]);
  const [Protectedpassword, setProtectedPassword] = useState();

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 200,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   autoplay: false,
  //   arrows: false,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  const fetchCommunities = () => {
    setLoading(true);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/community/handle/${communityHandle}`,
      data: { password: Protectedpassword },
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    axios
      .request(config)
      .then((response) => {
        // console.log(response.data.community.author.useremail);
        setLoading(false);
        checkVendor(response.data.community.author.useremail);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const checkVendor = async (email) => {
    try {
      const { data } = await axios({
        url: `${marketplaceapiurl}/seller/checksellerandgetid`,
        method: "POST",
        data: {
          email: email,
        },
      });
      // console.log("role listing", data);
      if (data.status === 200) {
        setVendorid(data.sellerId);
        vendorProduct(data.sellerId);
        handleProductRental(data.sellerId);
      }
    } catch (error) {}
  };

  const vendorProduct = async (sellerId) => {
    setLoading(true);
    try {
      const { data } = await axios({
        url: `${marketplaceapiurl}/product/communityvendorProduct`,
        method: "POST",
        data: {
          seller_id: sellerId,
        },
      });
      // console.log("role listing", data);
      if (data.status === 200) {
        setLoading(false);
        setAllproduct(data.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("product ", error);
    }
  };
  useEffect(() => {
    fetchCommunities();
    handleProductRental();
  }, [communityHandle]);

  const handleProductRental = async (sellerId) => {
    setLoading(true);
    try {
      const { data } = await axios({
        url: `${marketplaceapiurl}/product/communityvendorrentalProduct`,
        method: "POST",
        data: {
          seller_id: sellerId,
        },
      });
      // console.log("role listing", data);
      if (data.status === 200) {
        setLoading(false);
        setRentalGetProducts(data.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("rental product ", error);
    }
  };

  const handleLikeToggle = (productId) => {
    setLikedProducts((prevLikedProducts) => {
      if (prevLikedProducts.includes(productId)) {
        return prevLikedProducts.filter((id) => id !== productId);
      } else {
        return [...prevLikedProducts, productId];
      }
    });
  };

  const handleShare = (item) => {
    const fullURL = `https://savageseller.com/products/${item.name.replace(
      /\s+/g,
      "-"
    )}`;
    navigator.clipboard
      .writeText(fullURL)
      .then(() => {
        toast.success("URL copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy URL:", error);
      });
  };

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <Header />
      <div className="bg-[#f9f9f9]">
        <div className="p-5">
          <div className="w-full rounded-lg">
            <img
              src="/assets/communityBanner.png"
              alt=""
              className="w-full h-1/2"
            />
          </div>
          <div className="flex justify-between w-11/12 gap-3 py-5 sm:gap-5 sm:w-10 ">
            <div className="bg-[#FFD5F6] px-4 py-2 rounded-lg font-semibold text-lg border border-[#f3bbe8] sm:px-6">
              <button onClick={() => scrollToRef(marketplaceRef)}> All</button>
            </div>
            <div className="bg-[#D9DAFF] px-4 py-2 rounded-lg font-semibold text-lg border border-[#cbcceb] sm:px-6">
              <button onClick={() => scrollToRef(rentalsRef)}>Rentals</button>
            </div>
            <div className="bg-[#E8FFDD] px-4 py-2 rounded-lg font-semibold text-lg border border-[#d7d9d6] sm:px-6">
              <button onClick={() => scrollToRef(buyRef)}>Buy</button>
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <h3
                className="text-xl font-semibold sm:text-3xl"
                ref={marketplaceRef}
              >
                Marketplace
              </h3>
              <Link to="https://savageseller.com/products">
                <h4 className="text-[#005AE6] font-semibold underline flex items-center text-sm sm:text-xl">
                  See All <IoIosArrowForward />
                </h4>
              </Link>
            </div>

            <div className="flex flex-wrap my-6 w-full justify-start">
              {allproduct.length > 0 ? (
                allproduct.map((item) => (
                  <div
                    key={item._id}
                    className="xl:w-[23%] lg:w-[23%] sm:w-[31%] w-[45%] relative bg-cover rounded-lg mx-2 mb-6 border-1 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]"
                  >
                    <img
                      src={item.image[0]}
                      alt="University"
                      className="w-full"
                    />
                    <Link
                      to={`https://savageseller.com/products/${item.name.replace(
                        /\s+/g,
                        "-"
                      )}`}
                    >
                      <div className="w-full p-3 mw-auto flex justify-between absolute bottom-0 sm:bottom-0 items-center  bg-[#FFD5F6]">
                        <h3 className="text-xs font-normal sm:font-semibold text-black sm:text-[16px] capitalize">
                          {item.name}
                        </h3>
                        <button className="bg-white text-[#005AE6] px-3 rounded-3xl font-semibold text-xs h-5 sm:text-lg sm:px-5 sm:h-9">
                          ${item.selling_price}
                        </button>
                      </div>
                    </Link>
                    {/* <div className="absolute top-3 right-4 sm:top-7 sm:right-7">
                      <button
                        className="bg-white rounded-full p-2 sm:p-3"
                        onClick={() => handleLikeToggle(item._id)}
                      >
                        {likedProducts.includes(item._id) ? (
                          <GoHeartFill className="text-[#FD0606] text-base sm:text-2xl" />
                        ) : (
                          <GoHeart className="text-[#FD0606] text-base sm:text-2xl" />
                        )}
                      </button>
                    </div> */}
                    <div className="absolute top-3 right-4 sm:top-7 sm:right-7">
                      <button
                        className="bg-white rounded-full p-1 sm:p-3"
                        onClick={() => handleShare(item)}
                      >
                        <IoIosShareAlt className="text-blue-500 text-base sm:text-3xl" />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <h1 className="text-[20px]">No products Found</h1>
              )}
            </div>
          </div>

          <div>
            <div className="flex justify-between">
              <h3
                className="text-xl font-semibold sm:text-3xl"
                ref={rentalsRef}
              >
                Rentals
              </h3>
              <Link to="https://savageseller.com/collections/rental">
                <h4 className="text-[#005AE6] font-semibold underline flex items-center text-sm sm:text-xl">
                  See All <IoIosArrowForward />
                </h4>
              </Link>
            </div>
            <div className="flex flex-wrap my-6 w-full justify-start">
              {getRentalProducts.length > 0 ? (
                getRentalProducts.map((item) => (
                  <div
                    key={item._id}
                    className="xl:w-[23%] lg:w-[23%] sm:w-[31%] w-[45%] relative rounded-lg mx-2 mb-6 border-1 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]"
                  >
                    <img
                      src={item.image[0]}
                      alt="University"
                      className="w-full"
                    />
                    <Link
                      to={`https://savageseller.com/products/${item.name.replace(
                        /\s+/g,
                        "-"
                      )}`}
                    >
                      <div className="w-full p-3 mw-auto flex justify-between absolute bottom-0 sm:bottom-0 items-center  bg-[#D9DAFF]">
                        <h3 className="text-xs font-normal sm:font-semibold text-black sm:text-[16px] capitalize">
                          {item.name}
                        </h3>
                        <button className="bg-white text-[#005AE6] px-3 rounded-3xl font-semibold text-xs h-5 sm:text-lg sm:px-5 sm:h-9 mr-2 sm:mr-3">
                          ${item.selling_price}
                        </button>
                      </div>
                    </Link>
                    {/* <div className="absolute top-3 right-4 sm:top-7 sm:right-7">
                      <button
                        className="bg-white rounded-full p-2 sm:p-3"
                        onClick={() => handleLikeToggle(item._id)}
                      >
                        {likedProducts.includes(item._id) ? (
                          <GoHeartFill className="text-[#FD0606] text-base sm:text-2xl" />
                        ) : (
                          <GoHeart className="text-[#FD0606] text-base sm:text-2xl" />
                        )}
                      </button>
                    </div> */}
                    <div className="absolute top-3 right-4 sm:top-7 sm:right-7">
                      <button
                        className="bg-white rounded-full p-1 sm:p-3"
                        onClick={() => handleShare(item)}
                      >
                        <IoIosShareAlt className="text-blue-500 text-base sm:text-3xl" />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <h1 className="text-[20px]">No products Found</h1>
              )}
            </div>
          </div>

          <div>
            <div className="flex justify-between">
              <h3 className="text-xl font-semibold sm:text-3xl" ref={buyRef}>
                Buy
              </h3>
              <Link to="https://savageseller.com/products">
                <h4 className="text-[#005AE6] font-semibold underline flex items-center text-sm sm:text-xl">
                  See All <IoIosArrowForward />
                </h4>
              </Link>
            </div>
            <div className="flex flex-wrap my-6 w-full justify-start">
              {allproduct.length > 0 ? (
                allproduct.map((item) => (
                  <div
                    key={item._id}
                    className="xl:w-[23%] lg:w-[23%] sm:w-[31%] w-[45%] relative rounded-lg mx-2 mb-6 border-1 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]"
                  >
                    <img
                      src={item.image[0]}
                      alt="University"
                      className="w-full"
                    />
                    <Link
                      to={`https://savageseller.com/products/${item.name.replace(
                        /\s+/g,
                        "-"
                      )}`}
                    >
                      <div className="w-full p-3 mw-auto flex justify-between absolute bottom-0 sm:bottom-0 items-center  bg-[#E8FFDD]">
                        <h3 className="text-xs font-normal sm:font-semibold text-black sm:text-[16px] capitalize">
                          {item.name}
                        </h3>
                        <button className="bg-white text-[#005AE6] px-3 rounded-3xl font-semibold text-xs h-5 sm:text-lg sm:px-5 sm:h-9 mr-2 sm:mr-3">
                          ${item.selling_price}
                        </button>
                      </div>
                    </Link>
                    {/* <div className="absolute top-3 right-4 sm:top-7 sm:right-7">
                      <button
                        className="bg-white rounded-full p-2 sm:p-3"
                        onClick={() => handleLikeToggle(item._id)}
                      >
                        {likedProducts.includes(item._id) ? (
                          <GoHeartFill className="text-[#FD0606] text-base sm:text-2xl" />
                        ) : (
                          <GoHeart className="text-[#FD0606] text-base sm:text-2xl" />
                        )}
                      </button>
                    </div> */}
                    <div className="absolute top-3 right-4 sm:top-7 sm:right-7">
                      <button
                        className="bg-white rounded-full p-1 sm:p-3"
                        onClick={() => handleShare(item)}
                      >
                        <IoIosShareAlt className="text-blue-500 text-base sm:text-3xl" />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <h1 className="text-[20px]">No products Found</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;

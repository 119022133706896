import React, { useState, useEffect } from "react";
import { apiurl } from "../config/config";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import CreatePost from "../components/createPost";
import Layout from "../components/layout";
import { getCookie } from "../config/coockies";
import GetPosts from "../components/getPosts";
import { IoArrowBackSharp } from "react-icons/io5";

const Posts = () => {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const token = getCookie("savage_token");
  const [communityAuthId, setCommunityAuthId] = useState(null);
  const { communityHandle } = useParams();
  let navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const fetchAllPost = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/post/${communityHandle}/posts`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log('all post data is ---------------->', response.data)
        setLoading(false);
        setCommunityAuthId(response.data?.creator);
        const reversedPosts = response.data?.posts.reverse();
        setPosts(reversedPosts);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAllPost();
  }, []);

  const handleCreatePost = (newPost) => {
    setPosts([newPost, ...posts]);
  };

  return (
    <Layout>
      <>
        <div className="relative w-[100%] h-[200px] lg:h-[200px] full  m-auto bg-cover bg-no-repeat bg-center  bg-[#302b27] bg-[url('/public/assets/discussion.jpg')]">
          <h1 className="flex  items-center text-[40px] justify-center h-[200px] lg:h-[200px] font-bold text-[#fff]">
            Posts
          </h1>
        </div>
        <button
          className="flex items-center ml-2 text-green-600 border border-green-600 px-2 py-1 rounded-lg sm:px-5 sm:py-2 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform mt-2 "
          onClick={handleGoBack}
        >
          <IoArrowBackSharp className="mr-2" /> Back
        </button>
        <div className="w-full m-auto sm:w-1/2 sm:px-0 px-5 sm:-mt-10 mt-2">
          <CreatePost
            fetchposts={fetchAllPost}
            onCreatePost={handleCreatePost}
          />
          <GetPosts
            fetchposts={fetchAllPost}
            posts={posts}
            communityAuthId={communityAuthId}
          />
        </div>
      </>
    </Layout>
  );
};

export default Posts;

import React, { useState } from "react";
import Layout from "../components/layout";

const Setting = () => {
  const [settingdata, setSettingdata] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettingdata({ ...settingdata, [name]: value });
  };

  return (
    <Layout>
      <form className="max-w-7xl mx-auto mt-6 p-5 bg-#F9FAFB rounded-md">
        <div className="flex justify-between items-start flex-col lg:flex-row">
          <div className="w-full lg:w-2/6 mb-4 lg:mr-4">
            <h2 className="font-semibold pb-2">Basic Details</h2>
            <h3 className="text-gray-800">
              This information will be displayed publicly, so be careful what
              you share.
            </h3>
          </div>
          <div className="bg-white shadow-lg rounded-2xl w-full lg:w-3/5 p-10 border-2 border-darkgray">
            <div className="mb-4">
              <label className="block text-lg mb-1">User Name </label>
              <input
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="username"
              />
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-1">First Name</label>
              <input
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="firstname"
              />
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-1">Last Name </label>
              <input
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="lastname"
              />
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-1">E-Mail </label>
              <input
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="email"
              />
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-1">Phone no.</label>
              <input
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="phone"
              />
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-1">Address</label>
              <input
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="address"
              />
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-1">Status</label>
              <input
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="status"
              />
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-1">Location</label>
              <input
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="location"
              />
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-1">Date of Birth</label>
              <input
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="dob"
              />
            </div>
          </div>
        </div>

        <hr className="h-[0.12rem] bg-gray-300  m-9" />

        <div className="flex justify-between items-start flex-col lg:flex-row">
          <div className="w-full lg:w-2/6 mb-4 lg:mr-4">
            <h2 className="font-semibold pb-2">Community Details</h2>
            <h3 className="text-gray-800">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </h3>
          </div>
          <div className="bg-white shadow-lg rounded-2xl w-full lg:w-3/5 p-10 border-2 border-darkgray">
            <div className="mb-4">
              <label className="block text-lg mb-1">User handle</label>
              <input
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="userhandle"
              />
            </div>

            <div className="mb-4">
              <label className="block text-lg mb-1">Join</label>
              <input
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="Join"
              />
            </div>

            <div className="mb-4">
              <label className="block text-lg mb-1">Product</label>
              <input
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="Join"
              />
            </div>

            <div className="mb-4">
              <label className="block text-lg  mb-1">
                Members<span className="text-red-500">*</span>
              </label>
              <select
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="members"
              >
                <option>-- select --</option>
                <option>member 1 </option>
                <option>member 2 </option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-lg  mb-1">
                Wishlists<span className="text-red-500">*</span>
              </label>
              <select
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="wishlists"
              >
                <option>-- select --</option>
                <option>List 1</option>
                <option>List 2</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-lg  mb-1">
                Permissions<span className="text-red-500">*</span>
              </label>
              <select
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                required
              >
                <option>-- select --</option>
                <option>admin:false </option>
              </select>
            </div>
          </div>
        </div>

        <hr className="h-[0.12rem] bg-gray-300  m-9" />

        <div className="flex justify-between items-start flex-col lg:flex-row">
          <div className="w-full lg:w-2/6 mb-4 lg:mr-4">
            <h2 className="font-semibold pb-2">Password Details</h2>
            <h3 className="text-gray-800">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </h3>
          </div>
          <div className="bg-white shadow-lg rounded-2xl w-full lg:w-3/5 p-10 border-2 border-darkgray">
            <div className="mb-4">
              <label className="block text-lg mb-1">Password</label>
              <input
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="password"
              />
            </div>

            <div className="mb-4">
              <label className="block text-lg mb-1">Confirm Password</label>
              <input
                className="border-2 border-#D1D5DB rounded-md p-2 w-full"
                onChange={handleChange}
                name="password"
              />
            </div>
          </div>
        </div>

        <div className="flex mt-4">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4 ml-auto hover:bg-blue-600 transition-colors"
          >
            Submit
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default Setting;

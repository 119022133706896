// coockie.js

export const getCookie = (name) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};
export const setCookie = (name, value) => {
  const expireDate = new Date();
  // Set expiration date to 100 years in the future
  expireDate.setTime(expireDate.getTime() + (36500 * 24 * 60 * 60 * 1000)); // 100 years
  document.cookie = `${name}=${value};expires=${expireDate.toUTCString()};path=/`;
};


export const removeCookie = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
};

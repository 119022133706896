export const GetDate = (time) => {
    const currentTime = new Date();
    const commentTime = new Date(time);
    const timeDifference = Math.abs(currentTime.getTime() - commentTime.getTime());

    const isSameDay = currentTime.toDateString() === commentTime.toDateString();

    const formatTime = (date) => {
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    const formatDate = (date) => {
        return date.toLocaleDateString([], { day: '2-digit', month: 'short' });
    };

    if (isSameDay) {
        return formatTime(commentTime); // Return time if the date is today
    }

    // If not today or yesterday, return the date in "dd MMM" format
    return formatDate(commentTime);
};

// Usage examples:
// console.log(GetDate(new Date())); // "current time"
// console.log(GetDate(new Date(new Date().setDate(new Date().getDate() - 1)))); // "yesterday at time"
// console.log(GetDate(new Date(new Date().setFullYear(2023, 7, 23)))); // "23 Aug"

import React from "react";
import Discussionoverview from "../components/dicussionoverview";
import Header from "../components/header";

const DiscussionOverview = () => {
  return (
    <>
      <Header />
      <Discussionoverview />
    </>
  );
};

export default DiscussionOverview;

import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/layout";

const NotFound = () => {
  return (
    <Layout>
      <div className="h-screen">
        <div className="flex items-center justify-center h-full bg-gray-100">
          <div className="text-center">
            <h1 className="text-6xl font-bold text-gray-800">404</h1>
            <p className="text-2xl font-semibold text-gray-600">
              Page Not Found
            </p>
            <p className="mt-4 text-lg text-gray-500">
              Sorry, the page you are looking for might be in another castle.
            </p>
            <Link
              to="/"
              className="inline-block px-6 py-3 mt-4 text-lg font-semibold text-white bg-blue-500 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              HOME
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
